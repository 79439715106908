/*
admin  pages
*/
import CityRateCards from "./views/CityRateCard/CityRateCards";

//Drivers
import AllDrivers from "./views/Drivers/AllDrivers";
import AddDrivers from "./views/Drivers/AddDrivers";
import Driveranalytics from "./views/Drivers/DriversAnalytics";
import Testfile from "./test";
import PoliceVerification from "./views/Drivers/PoliceVerification";
//Rides
import OngoinRides from "./views/Rides/OngoinRides";
import CompletedRides from "./views/Rides/CompletedRides";
import PendingRides from "./views/Rides/PendingRides";
import CanceledRides from "./views/Rides/CancelledRides";
//Riders
import AllRiders from "./views/Riders/AllRiders";
//CR Management
import CustomerSupport from "./views/CRmanagement/CustomerSupport";
//HR Management
import SalesReferral from "./views/HRManagement/SalesReferral";

import BlackListed from "./views/Riders/BlackListed";
import BlockedNonPayment from "./views/Riders/BlockedNonPayment";
//Vehicae=les
import AddnewVehicle from "./views/Vehicles/AddnewVehicle";
import AllVehicles from "./views/Vehicles/AllVehicles";
import BlackListedVehicales from "./views/Vehicles/BlackListedVehicles";
import DocumentExperied from "./views/Vehicles/DocumentExperied";
//vendor
import Allvendors from "./views/Vendor/Allvendors";
import BlacklistedVendor from "./views/Vendor/BlacklistedVendor";
import Suspendedvendor from "./views/Vendor/Suspendedvendor";
//useraccess control
import Createuserrole from "./views/UserRoles/Createuserrole";
import Userrolehierarchy from "./views/UserRoles/userrolehierarchy";

import WarRoom from "./views/WarRoom/WarRoom";
//test
import Mapsss from "./views/mapsss";
//assets
import MobilePhones from "./views/Assets/MobilePhones";
import SimCard from "./views/Assets/SimCard";
import MobileSimLink from "./views/Assets/MobileSimLink";
import MobileVehicleLink from "./views/Assets/MobileVehicleLink";

//earnings report
import Earningreports from "./views/EarningReports/Earningreports";
import BankView from "./views/EarningReports/BankView";

//localizatuon
import AddCity from "./views/localization/AddCity";
import AddCountry from "./views/localization/AddCountry";
import ModifyCity from "./views/localization/ModifyCity";
import modifyCountry from "./views/localization/modifyCountry";
//promocode
import PromoCodeindex from "./views/promocode/PromoCode";
import PushNotification from "./views/PushNotification/PushNotifications";

//customer support
import customersupport from "./views/customersupport";
import adduser from "./views/UserRoles/adduser";
//relationship
import VehicleToDriver from "./views/Relationship/VehicleToDriver";
import DROtovehicle from "./views/Relationship/DROtovehicle";

//cash collection
import Cash_Collection from "./views/CashCollection/Cash_Collection";
//revenue
import RevenueRides from "./views/Revenue/RevenueRides";
import RevenueDriver from "./views/Revenue/RevenueDriver";
import RevenueVehicle from "./views/Revenue/RevenueVehicle";
//Collections
import Outstandingreport from "./views/Collections/Outstandingreport";
import CollectionsDrivers from "./views/Collections/CollectionsDrivers";
import CollectionsRides from "./views/Collections/CollectionsRides";
import CollectionsVehicle from "./views/Collections/CollectionsVehicle";



var routes = [
  {
    path: "/warroom",
    name: "War Room",
    icon: "nc-icon nc-bank",
    component: WarRoom,
    layout: "/admin",
    tag: "war_room",
  },
  {
    collapse: true,
    name: "Riders",
    icon: "fa fa-user-circle",
    state: "ridersCollapse",
    tag: "riders",
    views: [
      {
        path: "/AllRiders",
        name: "All Riders",
        mini: "AR",
        component: AllRiders,
        layout: "/admin",
        tagsub: "R",
      },

      {
        path: "/blackListed",
        name: "Blacklisted",
        mini: "B",
        component: BlackListed,
        layout: "/admin",
        tagsub: "U",
      },
      {
        path: "/Blocked",
        name: "Blacklisted Due to Non-Payment",
        mini: "BNP",
        component: BlockedNonPayment,
        layout: "/admin",
        tagsub: "R",
      },
    ],
  },
  {
    collapse: true,
    name: "Drivers",
    icon: "fa fa-taxi",
    state: "driversCollapse",
    tag: "drivers",
    views: [
      {
        path: "/allDrivers",
        name: "All Drivers",
        mini: "A D",
        component: AllDrivers,
        layout: "/admin",
        tagsub: "R",
      },
    ],
  },
  {
    collapse: true,
    name: "Rides",
    icon: "fa fa-address-card",
    state: "ridesCollapse",
    tag: "rides",
    views: [
      {
        path: "/ongoingRides",
        name: "On Going Rides",
        mini: "O R",
        component: OngoinRides,
        layout: "/admin",
        tagsub: "R",
      },
      {
        path: "/completedrides",
        name: "Completed Rides",
        mini: "C R",
        component: CompletedRides,
        layout: "/admin",
        tagsub: "R",
      },
      {
        path: "/pendingrides",
        name: "Pending Rides",
        mini: "P R",
        component: PendingRides,
        layout: "/admin",
        tagsub: "R",
      },
      {
        path: "/cancelledrides",
        name: "Cancelled Rides",
        mini: "C R",
        component: CanceledRides,
        layout: "/admin",
        tagsub: "R",
      },
    ],
  },

  {
    collapse: true,
    name: "Vehicles",
    icon: "fa fa-car",
    state: "vehiclesCollapse",
    tag: "vehicles",
    views: [
      {
        path: "/allVehiclees",
        name: "All Vehicles",
        mini: "A V",
        component: AllVehicles,
        layout: "/admin",
        tagsub: "R",
      },

      {
        path: "/documentexpiredvehicle",
        name: "Document Expired",
        mini: "D V",
        component: DocumentExperied,
        layout: "/admin",
        tagsub: "R",
      },
      {
        path: "/blacklistedvehicle",
        name: "Blacklisted Vehicles",
        mini: "B V",
        component: BlackListedVehicales,
        layout: "/admin",
        tagsub: "R",
      },
    ],
  }, 
  {
    collapse: true,
    name: "Assets",
    icon: "fa fa-plus",
    state: "assetsState",
    tag: "vehicles",
    views: [
      {
        path: "/AddSIMcard",
        name: "SIM Card",
        mini: "S C",
        component: SimCard,
        layout: "/admin",
        tagsub: "R",
      },
      {
        path: "/AddMobiles",
        name: "Mobiles",
        mini: "M",
        component: MobilePhones,
        layout: "/admin",
        tagsub: "R",
      },
      {
        path: "/MobileSIM",
        name: "Mobile SIM Link",
        mini: "MSL",
        component: MobileSimLink,
        layout: "/admin",
        tagsub: "R",
      },
      {
        path: "/MobileVehicle",
        name: "Mobile Vehicle Link",
        mini: "MVL",
        component: MobileVehicleLink,
        layout: "/admin",
        tagsub: "R",
      },
      {
        path: "/addnewvehicles",
        name: "Add New Vehicles",
        mini: "A N V",
        component: AddnewVehicle,
        layout: "/admin",
        tagsub: "W",
      },
      {
        path: "/addDrivers",
        name: "add Drivers",
        mini: "A D",
        component: AddDrivers,
        layout: "/admin",
        tagsub: "W",
      },
    ],
  },
  {
    collapse: true,
    name: "HR Management",
    icon: "fa fa-taxi",
    state: "mapdv",
    tag: "vehicle_driver_linking",
    views: [
      {
        path: "/AddDriverToVehical",
        name: "Add Driver To Vehicle",
        mini: "A V",
        component: VehicleToDriver,
        layout: "/admin",
        tagsub: "W",
      },
      {
        path: "/AddDroToVehical",
        name: "Add DRO To Vehicle",
        mini: "A V",
        component: DROtovehicle,
        layout: "/admin",
        tagsub: "R",
      },
      {
        path: "/Createuserrole",
        name: "Create User Role",
        mini: "UR",
        component: Createuserrole,
        layout: "/admin",
        tagsub: "W",
      },
      {
        path: "/Adduser",
        name: "Add User",
        mini: "UR",
        component: adduser,
        layout: "/admin",
        tagsub: "R",
      },
      {
        path: "/Userrolehierarchy",
        name: "User Role Hierarchy",
        component: Userrolehierarchy,
        mini: "U H",
        layout: "/admin",
        tagsub: "R",
      },
      {
        path: "/referral",
        name: "sales referral",
        component: SalesReferral,
        mini: "S R",
        layout: "/admin",
        tagsub: "R",
      },
    ],
  },
  {
    collapse: true,
    name: "Customer Care",
    icon: "fa fa-users",
    state: "CRmanagement",
    tag: "vehicles",
    views: [
      {
        path: "/CustomerSupport",
        name: "Customer Support",
        mini: "S C",
        component: CustomerSupport,
        layout: "/admin",
        tagsub: "R",
      },
      
     
    ],
  },
  {
    path: "/citycard",
    name: "city rate card",
    icon: "nc-icon nc-calendar-60",
    component: CityRateCards,
    layout: "/admin",
    tag: "city_rate_card",
  },
  {
    collapse: true,
    name: "Localization",
    icon: "fa fa-search",
    state: "localCollapse",
    tag: "localization",
    views: [
      {
        path: "/addCountry",
        name: "Add Country",
        mini: "A C",
        component: AddCountry,
        layout: "/admin",
        tagsub: "W",
      },

      {
        path: "/addCity",
        name: "Add City",
        mini: "AC",
        component: AddCity,
        layout: "/admin",
        tagsub: "W",
      },
    ],
  },
  {
    collapse: true,
    name: "Reports",
    icon: "fa fa-book",
    state: "Reports",
    tag: "localization",
    views: [
      {
        path: "/Driverdetails",
        name: "Driver analytics",
        mini: "A D",
        component: Driveranalytics,
        layout: "/admin",
        tagsub: "R",
      },
    ],
  },
  {
    path: "/CashCollection",
    name: "Cash Collection",
    icon: "fa fa-money",
    component: Cash_Collection,
    layout: "/admin",
    tag: "cash_collection",
  },

  {
    path: "/earningreports",
    name: "Earnings Reports",
    icon: "nc-icon nc-chart-bar-32",
    component: Earningreports,
    layout: "/admin",
    tag: "push_notfications",
  },
  
  {
    collapse: true,
    name: "Marketing",
    icon: "fa fa-area-chart",
    state: "Marketing",
    tag: "push_notfications",
    views: [
      {
        path: "/Promocode",
        name: "Promo Code",
        component: PromoCodeindex,
        mini: "P C",
        layout: "/admin",
        tagsub: "R",
      },
      {
        path: "/PushNotifications",
        name: "push notifications",
        component: PushNotification,
        mini: "P N",
        layout: "/admin",
        tagsub: "R",
      },
    ],
  },
  {
    collapse: true,
    name: "Accounts",
    icon: "fa fa-server",
    state: "accountesCollapse",
    tag: "riders",
    views: [
      
      {
        collapse: true,
        name: "Collections",
        icon: "fa fa-file-text-o",
        state: "CollectionsCollapse",
        layout: "/admin",
        mini: "R C",
        tagsub: "U",
        views: [
          {
            path: "/Collections-Rides",
            name: "Collections Ride Wise",
            component: CollectionsRides,
            mini: "C R",
            layout: "/admin",
            tagsub: "R",
          },
          {
            path: "/Collections-Driver",
            name: "Collections Driver Wise",
            component: CollectionsDrivers,
            mini: "C D",
            layout: "/admin",
            tagsub: "R",
          },
          {
            path: "/Collections-Vehicle",
            name: "Collections Vehicle Wise",
            component: CollectionsVehicle,
            mini: "C V",
            layout: "/admin",
            tagsub: "R",
          },
        ],
      },
      {
        collapse: true,
        name: "Revenue",
        icon: "fa fa-list-alt",
        state: "RevenueCollapse",
        layout: "/admin",
        mini: "R C",
        tagsub: "U",
        views: [
          {
            path: "/RevenueRides/",
            name: " Revenue Ride Wise",
            component: RevenueRides,
            mini: "R",
            layout: "/admin",
            tagsub: "R",
          },
          {
            path: "/Revenue-Driver",
            name: " Revenue Driver Wise",
            component: RevenueDriver,
            mini: "R D",
            layout: "/admin",
            tagsub: "R",
          },
          {
            path: "/Revenue-Vehicle",
            name: "Revenue Vehicle Wise",
            component: RevenueVehicle,
            mini: "R V",
            layout: "/admin",
            tagsub: "R",
          },
        ],
      },
      {
        path: "/outstandingReport",
        name: "Out standing report ",
        component: Outstandingreport,
        mini: "O S",
        layout: "/admin",
        tagsub: "R",
      },
      
    ],
  },
];

export default routes;
