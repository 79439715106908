import React, { useEffect, useState } from "react";

// reactstrap components
import { Button, Card, Spinner } from "reactstrap";
import { addRevenueVehicle } from "../datachane/actions";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// core components
import ReactTable from "components/ReactTable/ReactTable2";

function RevenueVehicle() {
  const dd = useSelector((state) => state.AccountsRevenueVehicle);
  const dispatch = useDispatch();
  let history = useHistory();
  const [nextkey, setnextkey] = useState(0);
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false);
  useEffect(() => {
    if (dd.arr.length === 0) {
      calldatasetApi(0);
    }
  }, [0]);
  async function calldatasetApiRepeat(e) {
    if (e >= 2) {

      var d = e;
      var da = "";
      if (dd.nextKey !== "") {
        da = dd.nextKey;
      } else {
        da = nextkey;
      }
      while (d !== 0) {
        d--;
        if (da !== null && nexttoggle) {
          da = await calldatasetApi(da);
        } else {
          break;
        }
      }
    }
  }
  async function calldatasetApi(nexttokenparam) {
    setspinnershow(true);
    if (nexttokenparam === undefined) {
      nexttokenparam = dd.nextKey || nextkey;
    }
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return await fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/accounts?type=revenue&sub_type=vehicles&start=" +
      nexttokenparam,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true && result.data.length !== 0) {
          result.data.map((dat) => {
            dat.action = (
              <div className="">
                {/* use this button to remove the data row */}
                <Button
                  onClick={() => {
                    history.push({
                      pathname: "/admin/RevenuVehicledetails",
                      state: { id: dat.id },
                    });
                  }}
                  color="info"
                  size="md"
                  className="btn-icon btn-link like"
                >
                  <i className="fa fa-eye" aria-hidden="true"></i>
                </Button>{" "}
              </div>
            );
          });
          dispatch(addRevenueVehicle(result.data, result.start));
          setnexttoggle(true);
          setspinnershow(false);
          return result.start;
        } else {
          setspinnershow(false);
          setnexttoggle(false);
          setmessagttouser("Unable to get data....ERROR : " + result.message);
          console.log("error", result.message);
          alert("No Data To Load");
          return null;
        }
      })
      .catch((error) => {
        setnexttoggle(false);
        setmessagttouser("Unable to get data....ERROR");
        setTimeout(() => {
          setmessagttouser("");
        }, 3000);
        console.log("error", error);
      });

  }
  if (dd.arr.length === 0) {
    return (
      <>
        <div className="content">
          <div className="w-100">
            <h5 style={{ display: "inline-block" }}>
              Revenue Vehicle Wise
            </h5>
            <p>{messagttouser}</p>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="content ">
        <div className="w-100" style={{ display: "inline-block" }}>
          <h5 style={{ display: "inline-block" }}> Revenue Vehicle Wise</h5>
          {"  "}
          {sipnnershow && (
            <div style={{ display: "inline-block" }}>
              Loading data... <Spinner size="sm" color="primary" />
            </div>
          )}
        </div>{" "}
        <ReactTable
          calldata={calldatasetApi}
          data={dd.arr}
          countforcall={calldatasetApiRepeat}
          isnext={nexttoggle}
          counts={dd.TotalCount}
          columns={[
            {
              Header: "vehicle number",
              accessor: "vehicle_number",
            },
            {
              Header: "total_share_rides ",
              accessor: "total_share_rides",
            },

            {
              Header: "total_single_rides",
              accessor: "total_single_rides",
            },
            {
              Header: "View more",
              accessor: "action",
            },
          ]}
          /*
            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
          */
          className="-striped -highlight primary-pagination"
        />
      </div>
    </>
  );
}

export default RevenueVehicle;
