import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,Spinner
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
// core components
function MobileSimLink() {
  const isloading = useSelector((state) => state.Isload);
  const [errormessage1, seterrormessage1] = useState(null);
  const [errormessage2, seterrormessage2] = useState(null);
  const [sipnnershow, setsipnnershow] = useState(false);
  //UnlinkedVehicle
  const dispatch = useDispatch();
  const [vehiclesetdata1, setvehiclesetdata1] = useState(null);
  const [srarchResponse, setsrarchResponse] = useState();
  //response mobile
  const [mobileResponse, setmobileResponse] = useState(null);
  const [SimResponse, setSimResponse] = useState(null);
  //searck key word store
  const [IMEINUMBER, setIMEINUMBER] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  //selected id
  const [selectedSim, setselectedSim] = useState(null);
  const [selectedMobile, setselectedMobile] = useState(null);
  function handleSubmit(event) {
    event.preventDefault();
    setsipnnershow(true);
    if (selectedSim !== null && selectedMobile !== null) {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        type: "mobile_sim",
        sim_id: selectedSim,
        mobile_id: selectedMobile,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://us-central1-africar-9309f.cloudfunctions.net/asset_link",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === false) {
            seterrormessage1(result.message);
            setTimeout(() => {
              seterrormessage1(null);
            }, 2000);
            setsipnnershow(false);

          } else {
            setsipnnershow(false);
            alert("Mobile Sim linked Successfully");
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      alert("Please select the Search Result.");
    }
  }

  async function handleSearchSIM(event) {
    event.preventDefault();
    setsipnnershow(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if(phoneNumber){
      setphoneNumber(phoneNumber.replace(' ',''));
    }
    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/search_asset?asset=sim&search=" +phoneNumber,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === false) {
          seterrormessage2(result.message);
          setsrarchResponse(null);
          setTimeout(() => {
            seterrormessage2(null);
          }, 2000);
          setsipnnershow(false);

        } else {
          setsipnnershow(false);
          setSimResponse(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }
  async function handleSearchMobile(event) {
    event.preventDefault();
    setsipnnershow(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/search_asset?asset=mobile&search=" +
        IMEINUMBER +
        "&type=mobile_sim",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === false) {
          seterrormessage1(result.message);
          setsrarchResponse(null);
          setTimeout(() => {
            seterrormessage1(null);
          }, 2000);
          setsipnnershow(false);

        } else {
          setsipnnershow(false);
          setmobileResponse(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }

  if (0) {
    return (
      <>
        <div className="content">
          <div className="w-100 ">
            <h5 style={{ display: "inline-block" }}>loading data...</h5>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="content ">
        <div className="w-100 mt-3">
          <h5 style={{ display: "inline-block" }}>Link Mobile to SIM</h5>{" "}
          {sipnnershow && (
            <div style={{ display: "inline-block" }}>
              Loading data... <Spinner size="sm" color="primary" />
            </div>
          )}
        </div>

        <Card className="h-75 m-1 p-2">
          <Row>
            <Col md={6}>
              <Card>
                <Card className="m-2 p-1">
                  <div className="content ">
                    <div className="w-100" style={{ display: "inline-block" }}>
                      <h5 style={{ display: "inline-block" }}>
                        Mobile Search{" "}
                      </h5>
                      <p>"Please Enter IMEI Number" </p>
                      <Form onSubmit={handleSearchMobile} className="w-100">
                        <InputGroup className="input-group-focus">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-search" aria-hidden="true" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            required
                            value={IMEINUMBER}
                            name="vSearch"
                            placeholder="IMEI..."
                            onChange={(e) => setIMEINUMBER(e.target.value)}
                            type="text"
                          />
                        </InputGroup>
                        <FormGroup className='text-center'>
                          <Button id="submitm" type="submit">
                            Search Mobile
                          </Button>
                          <div>
                            {errormessage1 != null ? (
                              <p style={{ color: "red" }}>{errormessage1}</p>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </Card>
              </Card>
            </Col>

            <Col md={6}>
              <Card>
                <Card className="m-2 p-1">
                  <div className="content ">
                    <div className="w-100" style={{ display: "inline-block" }}>
                      <h5 style={{ display: "inline-block" }}>SIM Search </h5>
                      <p>"Please Enter a Valid Phone Number" </p>
                      <Form onSubmit={handleSearchSIM} className="w-100">
                        <InputGroup className="input-group-focus">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-search" aria-hidden="true" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            required
                            value={phoneNumber}
                            name="vSearch"
                            placeholder="Phone Number"
                            onChange={(e) => setphoneNumber(e.target.value)}
                            type="text"
                          />
                        </InputGroup>
                        <FormGroup className='text-center'>
                          <Button id="submits" type="submit">
                            Search SIM
                          </Button>
                          <div>
                            {errormessage2 != null ? (
                              <p style={{ color: "red" }}>{errormessage2}</p>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </Card>
              </Card>
            </Col>
          </Row>
        </Card>
        <Card className="p-2 w-100">
          <h5>Search Results</h5>
          <Row className="w-100">
            <Form onSubmit={handleSubmit} className="w-100">
              <Col md={5}>
                {" "}
                {mobileResponse !== null ? (
                  <Card
                    className=" p-2"
                    style={{ backgroundColor: "#EFEFEF", textAlign: "justify" }}
                    id={mobileResponse.id}
                    onClick={() => {
                      setselectedMobile(mobileResponse.id);
                      document.getElementById(
                        mobileResponse.id
                      ).style.backgroundColor = "#EFEF";
                    }}
                  >
                    <Row style={{ textTransform: "capitalize" }}>
                      <Col sm={12}>
                        <p className="marginzero">
                          brand_name: <span>{mobileResponse.brand_name}</span>
                        </p>
                      </Col>

                      <Col sm={12}>
                        <p className="marginzero">
                          build_number :{" "}
                          <span>{mobileResponse.build_number}</span>
                        </p>
                      </Col>
                      <Col sm={12}>
                        <p className="marginzero">
                          .IMEI : <span>{mobileResponse.IMEI}</span>
                        </p>
                      </Col>
                    </Row>{" "}
                  </Card>
                ) : null}
              </Col>
              <Col md={5}>
                {" "}
                {SimResponse !== null ? (
                  <Card
                    className=" p-2"
                    style={{ backgroundColor: "#EFEFEF", textAlign: "justify" }}
                    id={SimResponse.id}
                    onClick={() => {
                      setselectedSim(SimResponse.id);
                      document.getElementById(
                        SimResponse.id
                      ).style.backgroundColor = "#EFEF";
                    }}
                  >
                    <Row style={{ textTransform: "capitalize" }}>
                      <Col sm={12}>
                        <p className="marginzero">
                          SIM Provider: <span>{SimResponse.company}</span>
                        </p>
                      </Col>

                      <Col sm={12}>
                        <p className="marginzero">
                          number : <span>{SimResponse.number}</span>
                        </p>
                      </Col>
                      <Col sm={12}>
                        <p className="marginzero">
                          {" ."}
                          <span></span>
                        </p>
                      </Col>
                    </Row>{" "}
                  </Card>
                ) : null}
              </Col>
              <Col md={12}>
                <Button id="submit" type="submit">
                  Submit
                </Button>
              </Col>{" "}
            </Form>
          </Row>
        </Card>
      </div>
    </>
  );
}

export default MobileSimLink;
