const initailState = []

const Ongoingridesdata=(state=initailState,action)=>{
    switch(action.type){
        case 'permissions':
            return action.payload
            
        default:
            return state;
    }
}

export default Ongoingridesdata;