import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
// core components
function MobileSimLink() {
  const [errormessage1, seterrormessage1] = useState(null);
  const [errormessage2, seterrormessage2] = useState(null);
  const [sipnnershow, setsipnnershow] = useState(false);
  //UnlinkedVehicle
  const [srarchResponse, setsrarchResponse] = useState();
  //response mobile
  const [mobileResponse, setmobileResponse] = useState(null);
  const [VehicalResponse, setVehicalResponse] = useState(null);
  //searck key word store
  const [IMEINUMBER, setIMEINUMBER] = useState("");
  const [vehicleRN, setvehicleRN] = useState("");
  //selected id
  const [selectedVehical, setselectedVehical] = useState(null);
  const [selectedMobile, setselectedMobile] = useState(null);
  function handleSubmit(event) {
    event.preventDefault();
    setsipnnershow(true);
    if (selectedVehical !== null && selectedMobile !== null) {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        type: "mobile_vehicle",
        vehicle_id: selectedVehical,
        mobile_id: selectedMobile,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://us-central1-africar-9309f.cloudfunctions.net/asset_link",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === false) {
            seterrormessage1(result.message);
            setTimeout(() => {
              seterrormessage1(null);
            }, 2000);
            setsipnnershow(false);
          } else {
            setsipnnershow(false);
            setVehicalResponse(null)
            setmobileResponse(null)
            setselectedMobile(null)
            setselectedVehical(null)
            alert("Mobile vehicle linked Successfully");
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      alert("Please select the Search Result.");
    }
  }

  async function handleSearchvehicle(event) {
    event.preventDefault();
    setsipnnershow(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/search_asset?asset=vehicle&search=" +
        vehicleRN +
        "&type=vehicle_mobile",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === false) {
          seterrormessage2(result.message);
          setsrarchResponse(null);
          setTimeout(() => {
            seterrormessage2(null);
          }, 2000);
          setsipnnershow(false);
        } else {
          setsipnnershow(false);
          setVehicalResponse(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }
  async function handleSearchMobile(event) {
    event.preventDefault();
    setsipnnershow(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/search_asset?asset=mobile&search=" +
        IMEINUMBER +
        "&type=mobile_vehicle",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === false) {
          seterrormessage1(result.message);
          setsrarchResponse(null);
          setTimeout(() => {
            seterrormessage1(null);
          }, 2000);
          setsipnnershow(false);
        } else {
          setsipnnershow(false);
          setmobileResponse(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }

  if (0) {
    return (
      <>
        <div className="content">
          <div className="w-100 ">
            <h5 style={{ display: "inline-block" }}>loading data...</h5>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="content ">
        <div className="w-100 mt-3">
          <h5 style={{ display: "inline-block" }}>Mobile to SIM</h5>{" "}
          {sipnnershow && (
            <div style={{ display: "inline-block" }}>
              Loading data... <Spinner size="sm" color="primary" />
            </div>
          )}
        </div>

        <Card className="h-75 m-1 p-2">
          <Row>
            <Col md={6}>
              <Card className="mb-2 p-1 h-100">
                <div className="content ">
                  <div className="w-100" style={{ display: "inline-block" }}>
                    <h5 style={{ display: "inline-block" }}>Mobile Search </h5>
                    <p>"Please Enter IMEI Number" </p>
                    <Form onSubmit={handleSearchMobile} className="w-100">
                      <InputGroup className="input-group-focus">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-search" aria-hidden="true" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          value={IMEINUMBER}
                          name="vSearch"
                          placeholder="IMEI..."
                          onChange={(e) => setIMEINUMBER(e.target.value)}
                          type="text"
                        />
                      </InputGroup>
                      <FormGroup className='text-center'>
                        <Button id="submitm" type="submit">
                          Search Mobile
                        </Button>
                        <div>
                          {errormessage1 != null ? (
                            <p style={{ color: "red" }}>{errormessage1}</p>
                          ) : null}
                        </div>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </Card>
            </Col>

            <Col md={6}>
              <Card className="mb-2 p-1 h-100">
                <div className="content ">
                  <div className="w-100" style={{ display: "inline-block" }}>
                    <h5 style={{ display: "inline-block" }}>Vehicle Search </h5>
                    <p>"Please Enter a Valid Vehicle Registration Number" </p>
                    <Form onSubmit={handleSearchvehicle} className="w-100">
                      <InputGroup className="input-group-focus">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-search" aria-hidden="true" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          value={vehicleRN}
                          name="vSearch"
                          placeholder="Registration Number"
                          onChange={(e) => setvehicleRN(e.target.value)}
                          type="text"
                        />
                      </InputGroup>
                      <FormGroup className='text-center'>
                        <Button id="submits" type="submit">
                          Search Vehicle
                        </Button>
                        <div>
                          {errormessage2 != null ? (
                            <p style={{ color: "red" }}>{errormessage2}</p>
                          ) : null}
                        </div>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Card>
        <Card className="p-2 w-100 h-75">
          <h5>Search Results</h5>
          <Form onSubmit={handleSubmit} className='w-100'>

          <Row className='w-100'>
              <Col md={5}>
                {" "}
                {mobileResponse !== null ? (
                  <Card
                    className=" p-2 h-100"
                    style={{ backgroundColor: "#EFEFEF", textAlign: "justify" }}
                    id={mobileResponse.id}
                    onClick={() => {
                      setselectedMobile(mobileResponse.id);
                      document.getElementById(
                        mobileResponse.id
                      ).style.backgroundColor = "#EFEF";
                    }}
                  >
                    <Row style={{ textTransform: "capitalize" }}>
                      <Col sm={12}>
                        <p className="marginzero">
                          Brand Name: <span>{mobileResponse.brand_name}</span>
                        </p>
                      </Col>

                      <Col sm={12}>
                        <p className="marginzero">
                          Build Number :{" "}
                          <span>{mobileResponse.build_number}</span>
                        </p>
                      </Col>
                      <Col sm={12}>
                        <p className="marginzero">
                          IMEI : <span>{mobileResponse.IMEI}</span>
                        </p>
                      </Col>
                      <Col sm={12}>
                        <p className="marginzero">
                          Phone Number : <span>{mobileResponse.number}</span>
                        </p>
                      </Col>
                    </Row>{" "}
                  </Card>
                ) : null}
              </Col>
              <Col md={5}>
                {" "}
                {VehicalResponse !== null ? (
                  <Card
                    className=" p-2 h-100"
                    style={{ backgroundColor: "#EFEFEF", textAlign: "justify" }}
                    id={VehicalResponse.id}
                    onClick={() => {
                      setselectedVehical(VehicalResponse.id);
                      document.getElementById(
                        VehicalResponse.id
                      ).style.backgroundColor = "#EFEF";
                    }}
                  >
                    <Row style={{ textTransform: "capitalize" }}>
                      <Col sm={12}>
                        <p className="marginzero">
                          Registration Number:{" "}
                          <span>{VehicalResponse.registration_number}</span>
                        </p>
                      </Col>

                      <Col sm={12}>
                        <p className="marginzero">
                          Model : <span>{VehicalResponse.model}</span> 
                        </p>
                      </Col>
                      <Col sm={12}>
                        <p className="marginzero">
                        Brand :
                          <span>{VehicalResponse.brand}</span>
                        </p>
                      </Col>
                      <Col sm={12}>
                        <p className="marginzero">
                          city : <span>{VehicalResponse.city}</span>
                        </p>
                      </Col>
                    </Row>{" "}
                  </Card>
                ) : null}
              </Col>
              <Col md={12}>
                <Button id="submit" type="submit">
                  Submit
                </Button>
              </Col>{" "}
          </Row>            </Form>

        </Card>
      </div>
    </>
  );
}

export default MobileSimLink;
