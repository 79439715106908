const initailState = {
    arr:[],
}
const Searchdata=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_SEARCH':
            return{ 
                arr: state.arr.concat(action.payload),
            }
        default:
            return state;
    }
}

export default Searchdata