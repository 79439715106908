const initailState = {
    prearr:[],
    arr:[],
    driver_avalable:0
}
const Driverlocation=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_LOC':
            return{ 
                prearr:state.arr,
                arr: action.payload,
                driver_avalable:action.length
            }
        default:
            return state;
    }
}

export default Driverlocation