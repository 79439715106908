
import React, { useEffect, useState } from "react";

// reactstrap components
import { Button, Card, Spinner } from "reactstrap";
import { addall_ridersData } from "../datachane/actions";
import { useSelector, useDispatch } from "react-redux";

// core components
import ReactTable from "components/ReactTable/ReactTable2";

function AllRiders() {
  const dd = useSelector((state) => state.All_Riders);
  const dispatch = useDispatch();
  const [nextkey, setnextkey] = useState(dd.nextKey || "");
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false);
  useEffect(() => {
    if (dd.arr.length === 0) {
      calldataall_riders("");
    }
  }, []);
  async function calldataall_ridersRepeat(e) {
    var d = e;
    var da = "";
    if (dd.nextKey !== "") {
      da = dd.nextKey;
    } else {
      da = nextkey;
    }
    while (d !== 0) {
      d--;
      da = await calldataall_riders(da);
    }
  }
  async function calldataall_riders(nexttokenparam) {
    setnexttoggle(false);
    setspinnershow(true);
    if(nexttokenparam===undefined){
      nexttokenparam=dd.nextKey||nextkey;
    }
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    var resultfetch = {};
    await fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/all_riders?start=" +
      nexttokenparam,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        resultfetch = result;
      })
      .catch((error) => {
        setnexttoggle(false);
        setmessagttouser("Unable to get data....ERROR");
        console.log("error", error);
      });
      if(resultfetch.status){
    await setnextkey(resultfetch.start);
    //
    resultfetch.data.map((dat) => {
      dat.full_name = dat.first_name + " " + dat.last_name;
      dat.joined_on = new Date()
        .toISOString(dat.joined_on * 1000)
        .split("T")[0];
    });
    dispatch(addall_ridersData(resultfetch.data, resultfetch.start,resultfetch.count));
    setnexttoggle(true);
    setspinnershow(false);
    return resultfetch.start;
  }else
  {
    alert(resultfetch.message)
    setnexttoggle(false);
    setmessagttouser("Unable to get data....ERROR : "+resultfetch.message);
    console.log("error", resultfetch.message);
  }
  }
  if (dd.arr.length === 0) {
    return (
      <>
        <div className="content">
          <div className="w-100">
            <h5 style={{ display: "inline-block" }}>RIDERS INFO</h5>
            <p>{messagttouser}</p>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="content ">
        <div className="w-100" style={{ display: "inline-block" }}>
          <h5 style={{ display: "inline-block" }}>RIDERS INFO </h5>
          {"  "}
          {sipnnershow && (
            <div style={{ display: "inline-block" }}>
              Loading data... <Spinner size="sm" color="primary" />
            </div>
          )}
        </div>{" "}
        <ReactTable
          calldata={calldataall_riders}
          data={dd.arr}
          countforcall={calldataall_ridersRepeat}
          isnext={nexttoggle}
          counts={dd.TotalCount}

          columns={[
            {
              Header: "Rider name",
              accessor: "full_name",
            },
            {
              Header: "gender",
              accessor: "gender",
            },
            {
              Header: "email Id",
              accessor: "email_id",
            },
            {
              Header: "phone number",
              accessor: "phone_number",
            },
            {
              Header: "joined on",
              accessor: "joined_on",
            },
            {
              Header: "DOB",
              accessor: "dob",
            },
            
          ]}
          /*
            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
          */
          className="-striped -highlight primary-pagination"
        />
      </div>
    </>
  );
}

export default AllRiders;
