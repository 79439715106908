import React, { useState,useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  Input,
  Row,
  Col,
  Form,
  Tooltip,
} from "reactstrap";
import { useSelector } from "react-redux";
// core components
import { useHistory } from "react-router-dom";

function Updatepromocode(props) {
  let history = useHistory();

    let datarr=props.location.customNameData

    if((props.location.customNameData)===null||(datarr)===undefined){
        history.push('/admin/Promocode')
    }
    const isloading = useSelector((state) => state.Isload);
    let cities = useSelector((state) => state.Ratecarddata);

  const [alertdata, setalertdata] = useState(null);
  const [couponname, setcouponname] = useState('');
  const [city, setcity] = useState();
  const [amountoff, setamountoff] = useState('');
  const [percentoff, setpercentoff] = useState('');
  const [coupondescription, setcoupondescription] = useState('');
  const [min_fare, setmin_fare] = useState();
  const [maxredAmount, setmaxredAmount] = useState();
  const [discountduration, setdiscountduration] = useState('');
  const [DiscountDurationInMounth, setDiscountDurationInMounth] =
    useState(null);
    const [Duration_in_days, setDuration_in_days] = useState(null)

  const [maxredemptionperuser, setmaxredemptionperuser] = useState('');
  const [redeem_by, setredeem_by] = useState();
  const [maxRedemption, setmaxRedemption] = useState('');
  const [couponvalidtill, setcouponvalidtill] = useState();
  const [couponapplicableto, setcouponapplicableto] = useState();
  const [couponApliedwhen, setcouponApliedwhen] = useState();
  const [fromdate, setfromdate] = useState();
  const [todate, settodate] = useState();
  const [upto, setupto] = useState();
  //tooltip
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  //
  const toggle = () => setTooltipOpen(!tooltipOpen);
  //week

  useEffect(() => {
    let element = document.getElementById("typeDiscount");
    element.value =datarr.duration;
    let element1 = document.getElementById("setcity");
    element1.value =datarr.city;
    setcouponname(datarr.id||'')
    setamountoff(datarr.amount_off||'')
    setpercentoff(datarr.percent_off||'')
    setupto(datarr.upto||null)
    setcity(datarr.city)
    setdiscountduration(datarr.discountduration)
    setDiscountDurationInMounth(datarr.duration_in_months)
    setmaxredemptionperuser(datarr.max_redemption_per_user)
    setmaxRedemption(datarr.max_redemption);
    setcoupondescription(datarr.description);
    setDuration_in_days(datarr.duration_in_days);
    setredeem_by(new Date(datarr.redeem_by).toISOString().substring(0, 10))
  }, [0])
  //handleform submit
  function handleSubmit(event) {
    event.preventDefault();
    if(amountoff!==null||amountoff!==''&&redeem_by!==null||redeem_by!==''){
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );    myHeaders.append("Content-Type", "application/json");

    var rawdata={
      object: "coupon",
      id: couponname,
      city: city,
      min_fare: Number(min_fare),
      duration: discountduration,
      duration_in_months: DiscountDurationInMounth,
      max_redemption: Number(maxRedemption),
      redeem_by: new Date(redeem_by).getTime() / 1000,
      description: coupondescription,
      max_redemption_per_user:Number(maxredemptionperuser),
      valid: true,
    }
    if(datarr.amount_off===null){
      delete rawdata.amount_off;
    }else{
      rawdata.amount_off=Number(amountoff);
    }
    if(datarr.percent_off===null){
      delete rawdata.percent_off
    }else{
      rawdata.percent_off=Number(percentoff);
    }
    if(discountduration === "repeating" ){
      rawdata.duration_in_months=Number(DiscountDurationInMounth)
    }
    if(discountduration === "repeating_daily"){
      rawdata.duration_in_days=Number(Duration_in_days)
    }
    var raw = JSON.stringify(rawdata);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/coupon",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if(result.status===true){
          alert('Coupon '+result.coupon_after.id+' updated');
          history.goBack();
        }else{
          alert('ERROR',result.message);
        }
      })
      .catch((error) => console.log("error", error));
  }else{
    alert("please Fill The Values ")
  }
}

  if (isloading) {
    return (
      <>
        <div className="content">
          <div className="w-100 ">
            <h5 style={{ display: "inline-block" }}>loading data...</h5>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="content w-100">
        <div className="w-100" style={{ display: "inline-block" }}>
          <h5>Add Coupon</h5>
          <h5>{alertdata}</h5>
        </div>
        <Card className="m-2 p-2">
          {" "}
          <Form onSubmit={handleSubmit} className="w-100">
         
              <Row>
                <Col md={3} className="mt-2">
                  <p className="-Formlable">Coupon Name/ID</p>
                  <Input
                  required
                  disabled
                    placeholder="Enter Coupon Name/ID"
                    id="cnam"
                    type="text"
                    value={couponname}
                    onChange={(e) => {
                      setcouponname(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3} className="mt-2">
                  <p className="-Formlable">Select City</p>

                  <Input
                  required
                  id="setcity"
                    placeholder="select citites"
                    type="select"
                    value={city}
                    onChange={(e) => {
                      setcity(e.target.value);
                    }}
                  >
                    <option value="">Select city </option>
                    {cities.asasa.map((data, i) => {
                      return (
                        <option value={data} key={i}>
                          {data}
                        </option>
                      );
                    })}
                  </Input>
                </Col>
                {datarr.amount_off!==null?
             <Col md={3} className="mt-2">
             <p className="-Formlable">Amount Off</p>

             <Input
             required
               value={amountoff}
               onChange={(e) => {
                 setamountoff(e.target.value);
               }}
               placeholder="Enter amount off"
               type="number"
             />
           </Col>:null    
            }
             {datarr.percent_off!==null?
                 <Col md={3} className="mt-2">
                 <p className="-Formlable">Percent Off</p>

                 <Input
                 required
                   value={percentoff}
                   onChange={(e) => {
                     setpercentoff(e.target.value);
                   }}
                   placeholder="Enter percent off"
                   type="number"
                 />
           </Col>:null}
                <Col md={3} className="mt-2">
                  <p className="-Formlable">Discount Duration</p>
                  <Input id="typeDiscount"
                  required
                    value={discountduration}
                    type="select"
                    onChange={(e) => {
                      setdiscountduration(e.target.value);
                    }}
                    placeholder="Enter country name"
                  ><option value="">select type</option>
                    <option value="once">Once</option>
                    <option value="forever">Forever</option>
                    <option value="repeating">Repeating</option>
                    <option value="repeating_daily" title="repeats daily ">Repeating Daily</option>

                  </Input>
                </Col>
                {discountduration === "repeating" ? (
                  <Col md={3} className="mt-2">
                    <p className="-Formlable">Discount Duration(in Mounths)</p>

                    <Input
                    required
                      value={DiscountDurationInMounth}
                      onChange={(e) => {
                        setDiscountDurationInMounth(e.target.value);
                      }}
                      placeholder="Enter Discount Duration"
                      type="number"
                    />
                  </Col>
                ) : null}
{discountduration === "repeating_daily" ? (
                  <Col md={3} className="mt-2">
                    <p className="-Formlable">Discount Duration Days</p>
                    <Input
                    required
                      value={Duration_in_days}
                      onChange={(e) => {
                        setDuration_in_days(e.target.value);
                      }}
                      placeholder="Enter number of times to repeated"
                      type="number"
                    />
                  </Col>
                ) : null}
                <Col md={3} className="mt-2">
                  <p className="-Formlable">Max Redemption per user</p>

                  <Input
                  required
                    value={maxredemptionperuser||''}
                    onChange={(e) => {
                      setmaxredemptionperuser(e.target.value);
                    }}
                    placeholder="Enter Max Redemption per user"
                    type="number"
                  />
                </Col>

                <Col md={3} className="mt-2">
                  <p className="-Formlable" id="maxRedemption">
                    Max Redemption
                  </p>
                  <Tooltip
                    placement="right"
                    isOpen={tooltipOpen2}
                    target="maxRedemption"
                    toggle={() => setTooltipOpen2(!tooltipOpen2)}
                  >
                    Maximum number of times this coupon can be redeemed, in
                    Total
                  </Tooltip>
                  <Input
                  required
                    value={maxRedemption||''}
                    onChange={(e) => {
                      setmaxRedemption(e.target.value);
                    }}
                    placeholder="Enter Max Redemption"
                    type="number"
                  />
                </Col>
                <Col md={12} className="mt-2">
                  <p className="-Formlable">Coupon Descrition</p>

                  <Input
                  required
                    value={coupondescription||''}
                    onChange={(e) => {
                      setcoupondescription(e.target.value);
                    }}
                    placeholder="Enter country name"
                    type="textarea"
                  />
                </Col>
                <Col md={3} className="mt-2">
                  <p className="-Formlable" id="redeem_by">
                  Valid Till  
                  </p>
                  <Tooltip
                    placement="right"
                    isOpen={tooltipOpen}
                    target="redeem_by"
                    toggle={toggle}
                  >
                    Date after which the coupon can no longer be redeemed{" "}
                  </Tooltip>
                  <Input
                  required
                    value={redeem_by}
                    onChange={(e) => {
                      setredeem_by(e.target.value);
                    }}
                    placeholder="Enter date"
                    type="date"
                  />
                </Col>
                {/* <Col md={12}>
                  <Row>
                    <Col md={12}>
                      {" "}
                      <h5>Applies when days</h5>
                    </Col>
                    <Col sm={12}>
                      <FormGroup check className="pull-left">
                        <Label check>
                          <Input
                            type="checkbox"
                            value={sunday}
                            onChange={(e) => {
                              setsunday(e.target.checked);
                            }}
                          />
                          <span className="form-check-sign" />
                          Sunday
                        </Label>
                      </FormGroup>
              
                      {sunday ? (
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="1">From</Label>
                              <Input
                                type="time"
                                name="times1"
                                id="1"
                                placeholder="Time"
                                onChange={(e) => {
                                  console.log(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label for="2">To</Label>
                              <Input
                                type="time"
                                name="times2"
                                id="2"
                                placeholder="Time"
                                onChange={(e) => {
                                  console.log(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col> <Button className="pt-2"  onClick={()=>{}}>Add</Button></Col>
                          <Col> - </Col>
                        </Row>
                      ) : null}
                    </Col>
                    <Col sm={12}>
                      <FormGroup check className="pull-left">
                        <Label check>
                          <Input
                            type="checkbox"
                            value={sunday}
                            onChange={(e) => {
                              setsunday(e.target.checked);
                            }}
                          />
                          <span className="form-check-sign" />
                          monday
                        </Label>
                      </FormGroup>
              
                      {sunday ? (
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="1">From</Label>
                              <Input
                                type="time"
                                name="times1"
                                id="1"
                                placeholder="Time"
                                onChange={(e) => {
                                  console.log(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label for="2">To</Label>
                              <Input
                                type="time"
                                name="times2"
                                id="2"
                                placeholder="Time"
                                onChange={(e) => {
                                  console.log(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : null}
                    </Col>
                 
                  </Row>
                </Col>
              */}</Row> 
           
          </Form>
          <Row>
            <Col md={6}>
              <Button onClick={(e) => handleSubmit(e)} className="w-75" type="submit">
                Submit
              </Button>
            </Col>
            <Col md={6}>
              <Button
                className="cancelButton w-75"
                onClick={() => history.goBack()}
              >
                Back
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
}

export default Updatepromocode;

