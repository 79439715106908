import React, { useState ,useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  FormGroup,
  Input,
  Row,
  Col,
  Form,
  Tooltip,
  Label,
} from "reactstrap";


function AddCityRateCard() {
  useEffect(() => {
    window.open('http://africar.freshdesk.com/', '_blank');

  }, [0])
    return (
      <>
        <div className="content">
          <div className="w-100 ">
            <h5 style={{ display: "inline-block" }}>Customer support.</h5>
            
<a className='btn' target="_blank" href='http://africar.freshdesk.com/'>Go To Support!</a>

          </div>
        </div>
      </>
    );
  


}

export default AddCityRateCard;
