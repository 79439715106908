import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import AdminNavbar from "../Navbars/AdminNavbar.js";
import Footer from "../Footer/Footer";

import Sidebar from "../Sidebar/Sidebar.js";
import { connect } from "react-redux";
import logo from "../../assets/img/spinerlogo.png";

import routes from "../../routes";
import routesSub from "../../routessub";
import "./loder.css";
var ps;

var ddd;

const mapStateToProps = (state) => {
  return {
    loadingstate: state.Isload,
    permissions: state.Permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
      setisloding: true,
    };
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1 && !this.props.loadingstate) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
    }
  }

  componentWillUnmount() {
    this.setState({ setisloding: false });

    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH" && !this.props.loadingstate) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };
  render() {
    if (this.props.loadingstate) {
      return (
        <div className="wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="300" height="300">
            <circle
              className="circle"
              cx="150"
              cy="150"
              r="140"
              fill="none"
              stroke="#632b26"
              strokeWidth="5"
              strokeLinecap="square"
              strokeOpacity=".99213"
              paintOrder="fill markers stroke"
            />

            <circle
              id="arc4"
              className="circle"
              cx="150"
              cy="150"
              r="120"
              fill="none"
              stroke="#632b26"
              strokeWidth="30"
              strokeLinecap="square"
              strokeOpacity=".99213"
              paintOrder="fill markers stroke"
            />
            <image
              className="circle"
              xlinkHref={logo}
              x="25%"
              y="20%"
              height="200"
              width="150"
            />
            <circle
              id="arc6"
              className="circle"
              cx="150"
              cy="150"
              r="90"
              fill="none"
              stroke="#632b26"
              strokeWidth="16"
              strokeLinecap="square"
              strokeOpacity=".99213"
              paintOrder="fill markers stroke"
            />
            <circle
              id="arc5"
              className="circle"
              cx="150"
              cy="150"
              r="90"
              opacity=".49"
              fill="none"
              stroke="#632b26"
              strokeWidth="16"
              strokeLinecap="square"
              strokeOpacity=".99213"
              paintOrder="fill markers stroke"
            />
          </svg>{" "}
        </div>
      );
    }
    return (
      <div className="wrapper">
        <div></div>
        <Sidebar
          {...this.props}
          routes={this.props.permissions}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          username={this.props.user}
        />
        <div className="main-panel" ref="mainPanel">
          <AdminNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
          <Switch>
            {this.getRoutes(this.props.permissions)}
            {this.getRoutes(routesSub)}
          </Switch>
          {
            // we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf("full-screen-map") !==
            -1 ? null : (
              <Footer fluid />
            )
          }
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
