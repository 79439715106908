/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
} from "reactstrap";
import { addall_ridersData } from "../datachane/actions";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import moment from "moment";
// core components
import ReactTable from "../../components/ReactTable/ReactTable";


function AllRiders() {
  const dd = useSelector((state) => state.All_Riders);
  const dispatch = useDispatch();
  const [nextkey, setnextkey] = useState("");
  const [nexttoggle, setnexttoggle] = useState(true);
  const [alertdata, setalertdata] = useState("");
  const [messagttouser, setmessagttouser] = useState("Loading Data...");

  useEffect(() => {
    if (dd.arr.length === 0) {
      calldataall_riders();
    }
  }, []);
  function calldataall_riders() {
    setnexttoggle(false);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/all_vendors?start=" +
        nextkey,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        result.data.map((dat) => {
          dat.joined_on = new Date()
            .toISOString(dat.joined_on * 1000)
            .split("T")[0];
        });
        setnextkey(result.start);
        dispatch(addall_ridersData(result.data, result.start));
        setnexttoggle(true);
      })
      .catch((error) => {
        setnexttoggle(false);
        setalertdata("No More Data To Fetch...");
        setmessagttouser("Unable to get data....ERROR");

        setTimeout(() => {
          setalertdata("");
        }, 3000);
        console.log("error", error);
      });
  }
  if (dd.arr.length === 0) {
    return (
      <>
        <div className="content">
          <div className="w-100 mt-2">
            <h5 style={{ display: "inline-block" }}>Vendor Details</h5>
            <p>{messagttouser}</p>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="content ">
        <div className="w-100" style={{ display: "inline-block" }}>
          <h5 style={{ display: "inline-block" }}>Vendor Details</h5>
          {alertdata}
        </div>
        <ReactTable
          calldata={calldataall_riders}
          data={dd.arr}
          isnext={nexttoggle}
          hidecolumnsdata={["type", "fleet_address","joined_on",
      "vendor_status"]}
          columns={[
            {
              Header: "user type",
              accessor: "type",
            },
            {
              Header: "fleet owner name",
              accessor: "fleet_owner_name",
            },
            {
              Header: "fleet name",
              accessor: "fleet_name",
            },
            {
              Header: "city",
              accessor: "city",
            },
            {
              Header: "fleet type",
              accessor: "fleet_type",
            },
            {
              Header: "contact no",
              accessor: "contactno",
            },
            {
              Header: "fleet emailid",
              accessor: "fleet_emailid",
            },
            {
              Header: "document status",
              accessor: "doc_status",
            },
            {
              Header: "fleet address",
              accessor: "fleet_address",
            },
            {
              Header: "joined on",
              accessor: "joined_on",
            },
            {
              Header: "vendor status",
              accessor: "vendor_status",
            },
            
          ]}
          /*
            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
          */
          className="-striped -highlight primary-pagination"
        />
      </div>
    </>
  );
}

export default AllRiders;

const ddss = {
  status: true,
  start: "o8TIvwZMSXVqFUCA4Jb1tcl1EAu2",
  data: [
    {
      uid: "Ky5MjpyrI0TvSJ7uZpSQzQejh133",
      cash_collected_from_rider: [19833.25, 19833.25],
      dro_uid: "726e0IFNvqTppBxwazve8f17hpB2",
      last_paid: 1620302581,
      ridesRole: true,
      afc: [3403.45, 3455.45],
      type: "vendor",
      vendor_status: "Active",
      offline_earnings: [16860.440000000002, 17122.440000000002],
      driver_paid: [0, 1620727535, 100],
      fleet_address: "lagos",
      online_earnings: [125.01, 125.01],
      fleet_type: "Individual",
      rateCardRole: true,
      contactno: "+2347894546575",
      fleet_owner_name: "Monty",
      fleet_emailid: "africarvendor@gmail.com",
      govt: [3284.8100000000004, 3338.8100000000004],
      city: "lagos",
      balance: [-6251.25, 1623076257, -6041.25],
      fleet_name: "Africar App",
      doc_status: "Whitelist",
      id: "Ky5MjpyrI0TvSJ7uZpSQzQejh133",
    },
    {
      uid: "PWFEtsCY35OKvPTPP6Sl6BpywlI3",
      DRORole: false,
      balance_backup: [-54120, 1620057774, -55328],
      fleet_emailid: "testvendor@gmail.com",
      offline_earnings: [225552.61999999988, 227252.61999999988],
      fleet_owner_name: "Test Vendor PVT LTD",
      vendor_status: "Active",
      city: "lagos",
      rateCardRole: false,
      fleet_type: "Individual",
      afc: [45103.61999999998, 45442.61999999998],
      fleet_name: "Test Vendor",
      doc_status: "Whitelist",
      ridesRole: true,
      fleet_address: "lagos",
      cash_collected_from_rider: [255805.85000000012, 257730.85000000012],
      balance: [-75356.84999999996, 1623233647, -75920.84999999996],
      govt: [30253.229999999996, 30478.229999999996],
      online_earnings: [0, 0],
      contactno: "+2349658741232",
      dro_uid: "unB6kcONJLQDvRaMP6iXjV71AMW2",
      id: "PWFEtsCY35OKvPTPP6Sl6BpywlI3",
    },
    {
      fleet_address: "lagos",
      ridesRole: false,
      city: "africa",
      fleet_name: "testingVendorForEarningReport",
      doc_status: "Whitelist",
      fleet_owner_name: "testingVendorForEarningReport",
      DRORole: false,
      dro_uid: "726e0IFNvqTppBxwazve8f17hpB2",
      fleet_type: "Individual",
      balance_backup: [-264, 1616259546, -1169],
      fleet_emailid: "03971546465@gmail.com",
      contactno: "+2343971546465",
      uid: "o8TIvwZMSXVqFUCA4Jb1tcl1EAu2",
      vendor_status: "Active",
      rateCardRole: false,
      id: "o8TIvwZMSXVqFUCA4Jb1tcl1EAu2",
    },
  ],
};
