import React, { useState } from "react";
import { Button } from "reactstrap";
import { useAuth } from "./Authcontext.js";
import { Link, useHistory } from "react-router-dom";
function Logoutbutton() {
  const { currentUser, Logout } = useAuth();
  const history = useHistory();

  async function handleLogout() {
    try {
      await Logout();
      localStorage.clear();
      window.location.reload();
      history.push("/login");
    } catch (e) {
      console.log("Failed to log out" + e.message);
    }
  }

  return (
    <Button
      className="sidebar-normal ml-5"
      variant="outline-light"
      onClick={handleLogout}
    >
      Log out
    </Button>
  );
}
export default Logoutbutton;
