import React, { useRef, useState } from "react";
import { Form, Card, Button, CardImg, Alert, Container } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "./Authcontext.js";
import styles from "../assets/css/dem.module.css";

import userprofile2 from "./img/avatar.png";
import userprofile0 from "./img/wave.png";
import userprofile1 from "./img/bg.svg";
export default function Login() {
  const emailref = useRef();
  const pasref = useRef();
  const { login } = useAuth();
  const [error, seterror] = useState("");
  const [loading, setloding] = useState(false);
  const history = useHistory();

  async function handelSubmit(e) {
    e.preventDefault();

    try {
      seterror(``);
      setloding(true);
      await login(emailref.current.value, pasref.current.value);
      history.push("/admin/warroom");
    } catch (e) {
      console.log(e.message);
      seterror(e.message + '"Please Enter Valid Email And Password"');
    }
    setloding(false);
  }
  return (
    <div>
      <img className={styles.wave} src={userprofile0} />
      <div className={styles.container}>
        <div className={styles.img}>
          <img src={userprofile1} />
        </div>
        <div className={styles.logincontent}>
          <Form onSubmit={handelSubmit}>
            <img src={userprofile2} />
            <h2 className={styles.title}>AfricAR</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <div className={styles.inputdiv}>
              <div className={styles.i}></div>
              <div className={styles.i}>
                <input
                  type="email"
                  className={styles.input}
                  ref={emailref}
                  required
                  placeholder="Username"
                />
              </div>
            </div>
            <div className={styles.inputdiv}>
              <div className={styles.i}></div>
              <div className={styles.i}>
                <input
                  type="password"
                  placeholder="Password"
                  className={styles.input}
                  ref={pasref}
                  required
                />
              </div>
            </div>
            <a href="#">Forgot Password?</a>
            <input
              disabled={loading}
              type="submit"
              className={styles.btncustom}
              value="Login"
            />
          </Form>
        </div>
      </div>
    </div>
  );
}
