import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  Spinner,
  Form,
  FormGroup,
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Table,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

// core components
import ReactTable from "components/ReactTable/ReactTable";

import { AddMobileddata } from "../datachane/actions";

import Papa from "papaparse";

function MobilePhones() {
  const dd = useSelector((state) => state.MobileData);
  const [nextkey, setnextkey] = useState("");

  const dispatch = useDispatch();
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [sipnnershow, setspinnershow] = useState(false);
  const [modal, setModal] = useState(false);
  const [isDisabled, setisDisabled] = useState(false);
  const [nexttoggle, setnexttoggle] = useState(true);

  //modal form
  const [Brandname, setBrandname] = useState("");
  const [IMEInumber, setIMEInumber] = useState("");
  const [buildNumber, setbuildNumber] = useState("");
  const [showMultiple, setshowMultiple] = useState(true);
  const [fileselectedjson, setfileselectedjson] = useState({ data: [] });
  useEffect(() => {
    if (dd.arr.length === 0) {
      CallMobileData("");
    }
  }, [0]);
  async function callsimRepeat(e) {
    if (e >= 20) {
      var d = e * 10;
      var da = "";
      if (dd.nextKey !== "") {
        da = dd.nextKey;
      } else {
        da = nextkey;
      }
      if (da !== null) {
        await CallMobileData(da);
      }
    }
  }
  const CSVToJsonData = (e) => {
    const files = e.target.files;
    if (files) {
      Papa.parse(files[0], {
        header: true,
        complete: function (results) {
          setfileselectedjson({ data: results.data });
        },
      });
    }
  };
  function CallMobileData(nexttokenparam) {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/asset?start=" +
        nexttokenparam +
        "&type=mobile&limit=",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true&& result.data.length!==0) {
          result.data.map((doc) => {
            doc.linked_to_vehicle = doc.linked_to_vehicle ? "Yes" : "No";
            doc.linked_to_sim = doc.linked_to_sim ? "Yes" : "No";
            doc.added_on = new Date(doc.added_on * 1000).toLocaleString(
              "en-US",
              {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }
            );
            if (doc.linked_on) {
              doc.linked_on = new Date(doc.linked_on * 1000).toLocaleString(
                "en-US",
                {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                }
              );
            } else {
              doc.linked_on = "-";
            }
          });
          if (result.start === null) {
            // alert("No More Data To Load.");
            setmessagttouser("No Data To Load");
          } else {
            setnextkey(result.start);
            setmessagttouser("");

            dispatch(AddMobileddata(result.data));
          }
        } else {
          setnexttoggle(false)

          alert('No Data To Load');
        }
      })
      .catch((error) => console.log("error", error));
  }
  function DownloadSampleCSV() {
    const header = ["brand_name", "build_number", "IMEI"];
    var CSV = [header.join(",")].join("\r\n");
    //Generate a file name
    var fileName = "Sample";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += "MobileUpload".replace(/ /g, "_");
    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);
    var link = document.createElement("a");
    link.href = uri;
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  function handleSubmitMultiple(e) {
    e.preventDefault();
    setspinnershow(true);

    document.getElementById("multiple").setAttribute("disabled", "disabled");
    if (fileselectedjson.data.length !== 0) {
      var heads = Object.keys(fileselectedjson.data[0]);
      if (
        heads.includes("IMEI") &&
        heads.includes("build_number") &&
        heads.includes("IMEI") &&
        heads.length === 3
      ) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("token")
        );
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(fileselectedjson);
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          "https://us-central1-africar-9309f.cloudfunctions.net/asset?type=mobile",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status === true) {
              setspinnershow(false);

              alert("Mobiles added Successfully");
              document.getElementById("multiple").removeAttribute("disabled");
              setfileselectedjson({ data: [] });
            } else {
              alert("Error", result.message);
              document.getElementById("multiple").removeAttribute("disabled");
              setspinnershow(false);
            }
          })
          .catch((error) => console.log("error", error));
      } else {
        document.getElementById("multiple").removeAttribute("disabled");
        setspinnershow(false);
        alert(
          "The Uploaded File Contains foreign fields.Please Check the file format"
        );
      }
    } else {
      document.getElementById("multiple").removeAttribute("disabled");
      setspinnershow(false);
      alert("The uploaded file contain Zero Fields");
    }
  }
  function handleSubmit1(e) {
    e.preventDefault();
    setspinnershow(true);

    setisDisabled(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      type: "mobile",
      brand_name: Brandname,
      build_number: buildNumber,
      IMEI: IMEInumber,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/asset",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          alert("Mobile added Successfully");
          document.getElementById("submit").removeAttribute("disabled");
          setIMEInumber("");
          setbuildNumber("");
          setBrandname("");
          setisDisabled(false);
          setspinnershow(false);
        } else {
          setmessagttouser(result.message);
          alert("Error", result.message);
          document.getElementById("submit").removeAttribute("disabled");
          setisDisabled(false);
          setspinnershow(false);
        }
      })
      .catch((error) => console.log("error", error));
  }
  const toggle = () => {
    setModal(!modal);
  };

  return (
    <>
      <div className="content">
        <div className="w-100">
          <h5 style={{ display: "inline-block" }}> Mobile Info</h5>
        </div>
        <p>{messagttouser}</p>

        <Row>
          <Col></Col>
          <Col>
            <Button
              className="w-100"
              onClick={() => {
                toggle();
              }}
            >
              Add Mobile
            </Button>
          </Col>
        </Row>

        {dd.arr.length !== 0 ? (
          <ReactTable
            calldata={CallMobileData}
            countforcall={callsimRepeat}
            data={dd.arr}
            isnext={nexttoggle}
            hidecolumnsdata={["linked_on", "company", "number"]}
            columns={[
              {
                Header: "Mobile Brand",
                accessor: "brand_name",
              },
              {
                Header: "IMEI Number",
                accessor: "IMEI",
              },

              {
                Header: "Build Number",
                accessor: "build_number",
              },
              {
                Header: "added on",
                accessor: "added_on",
              },
              {
                Header: "linked to vehicle",
                accessor: "linked_to_vehicle",
              },
              {
                Header: "linked to SIM",
                accessor: "linked_to_sim",
              },
              {
                Header: "linked_on",
                accessor: "linked_on",
              },
              {
                Header: "SIM Provider",
                accessor: "company",
              },

              {
                Header: "Phone number",
                accessor: "number",
              },
            ]}
            className="-striped -highlight primary-pagination"
          />
        ) : null}
        <Modal size="lg" className="w-100" isOpen={modal} toggle={toggle}>
          <ModalHeader>Add Mobile </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={12}>
                <FormGroup check className="pull-left">
                  <Label check>
                    Upload CSV file.
                    <Input
                      name={"ss"}
                      type="checkbox"
                      value={showMultiple}
                      onChange={(e) => {
                        setshowMultiple(!showMultiple);
                      }}
                    />
                    <span className="form-check-sign" />
                  </Label>
                </FormGroup>
              </Col>
              <Col md={12}>
                {showMultiple === true ? (
                  <div>
                    {sipnnershow ? (
                      <div style={{ display: "inline-block" }}>
                        Processing data... <Spinner size="sm" color="primary" />
                      </div>
                    ) : null}
                    <div className="">
                      <p>
                        Date OF Adding Mobile{" "}
                        <span>
                          {new Date().toLocaleString("en-US", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })}
                        </span>
                      </p>
                      <Form onSubmit={handleSubmit1} className="w-100">
                        <Row>
                          <Col md={6} className="mt-2">
                            <FormGroup>
                              <p className="Labelstyle">Brand Name </p>
                              <Input
                                required
                                placeholder="Enter Brand Name "
                                type="text"
                                value={Brandname}
                                onChange={(e) => {
                                  setBrandname(e.target.value);
                                }}
                              />
                            </FormGroup>{" "}
                          </Col>

                          <Col md={6} className="mt-2">
                            <FormGroup>
                              <p className="Labelstyle">
                                IMEI Number for SIM one
                              </p>
                              <Input
                                required
                                placeholder="Enter IMEI Number"
                                type="text"
                                value={IMEInumber}
                                onChange={(e) => {
                                  setIMEInumber(e.target.value);
                                }}
                              />
                            </FormGroup>{" "}
                          </Col>
                          <Col md={6} className="mt-2">
                            <FormGroup>
                              <p className="Labelstyle">Build Number</p>
                              <Input
                                required
                                placeholder="Enter Build Number"
                                type="text"
                                value={buildNumber}
                                onChange={(e) => {
                                  setbuildNumber(e.target.value);
                                }}
                              />
                            </FormGroup>{" "}
                          </Col>

                          <Col md={12}></Col>
                          <Col md={6}>
                            <Button
                              className="w-75"
                              disabled={isDisabled}
                              id="submit"
                              type="submit"
                            >
                              Submit
                            </Button>
                          </Col>
                          <Col md={6}>
                            <Button
                              className="cancelButton w-75"
                              onClick={() => {
                                setModal(!modal);
                              }}
                            >
                              Close
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                ) : (
                  <div>
                    {sipnnershow ? (
                      <div style={{ display: "inline-block" }}>
                        Processing data... <Spinner size="sm" color="primary" />
                      </div>
                    ) : null}
                    <Form onSubmit={handleSubmitMultiple} className="w-100">
                      <Row>
                        <Col md={12}>
                          <p>
                            Please follow the format given while uploading the
                            .csv
                          </p>
                          <p>
                            Download sample CSV{" "}
                            <Button
                              style={{ width: "100px" }}
                              onClick={() => {
                                DownloadSampleCSV();
                              }}
                            >
                              <i
                                className="fa fa-download"
                                aria-hidden="true"
                              ></i>
                            </Button>
                          </p>

                          <Table bordered dark>
                            <thead>
                              <tr>
                                <th style={{ textTransform: "lowercase" }}>
                                  brand_name
                                </th>
                                <th style={{ textTransform: "lowercase" }}>
                                  build_number
                                </th>
                                <th style={{ textTransform: "lowercase" }}>
                                  IMEI
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Samsung</td>
                                <td>S2043xx</td>
                                <td>14212XXXXXXX</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>

                        <Col md={12}>
                          <hr />

                          <input
                            style={{ borderLeft: "6px solid red" }}
                            required
                            type="file"
                            accept=".csv"
                            onChange={(e) => {
                              CSVToJsonData(e);
                            }}
                          />
                          <hr />
                        </Col>
                        <Col md={6}>
                          <Button
                            className="w-75"
                            disabled={isDisabled}
                            id="multiple"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Col>
                        <Col md={6}>
                          <Button
                            className="cancelButton w-75"
                            onClick={() => {
                              setModal(!modal);
                            }}
                          >
                            Close
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                )}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

export default MobilePhones;
