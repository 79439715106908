import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Row,
  Col,
  Form,
  Progress,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
// core components
import { fire_storage } from "../../components/firebase";
import {
  BrowserRouter as Router,
  Link,
  Route,
  useHistory,
} from "react-router-dom";

function AddnewVehicle() {
  const isloading = useSelector((state) => state.Isload);
  let cities = useSelector((state) => state.Ratecarddata);
  let history = useHistory();
  const [isget, setisget] = useState(true);
  const [alertdata, setalertdata] = useState(null);
  const [message, setmessage] = useState("loading data....");
  const [displayvalue, setdisplayvalue] = useState(true);
  const [VehicleRegistrationNumber, setVehicleRegistrationNumber] =
    useState("");
  const [Vehiclebrand, setVehiclebrand] = useState("");
  const [VehicleModel, setVehicleModel] = useState("");
  const [vehicleCategory, setvehicleCategory] = useState("");
  const [VehicleJoindata, setVehicleJoindata] = useState("");
  const [VehicleCity, setVehicleCity] = useState("");
  const [vehicleregistrationcardimage, setvehicleregistrationcardimage] =
    useState("");
  const [vehicalVendor, setvehicalVendor] = useState("");
  const [insuranceValidity, setinsuranceValidity] = useState("");
  const [VehicleInsurance, setVehicleInsurance] = useState("");
  const [VehicleInsurancedate, setVehicleInsurancedate] = useState("");
  const [VehicleFC, setVehicleFC] = useState("");
  const [vehiclefcValidDate, setvehiclefcValidDate] = useState("");
  const [VehicleagInspctionRep, setVehicleagInspctionRep] = useState("");
  const [proofofOwnershipDoc, setproofofOwnershipDoc] = useState("");
  const [autogenius_inspection_report, setautogenius_inspection_report] =
    useState("");
  const [VehicleColor, setVehicleColor] = useState();
  const [MaxDueAmount, setMaxDueAmount] = useState();
  const [vehicleAGIRdate, setvehicleAGIRdate] = useState("");
  const [documentstatus, setdocumentstatus] = useState("");
  const [uplodedisplay, setuplodedisplay] = useState(false);
  const [uplode2, setuplode2] = useState({ label: "", value: 0 });
  const [uplode3, setuplode3] = useState({ label: "", value: 0 });
  const [uplode1, setuplode1] = useState({ label: "", value: 0 });
  const [uplode4, setuplode4] = useState({ label: "", value: 0 });
  const [uplode5, setuplode5] = useState({ label: "", value: 0 });
  const [vendorshow, setvendorshow] = useState(false);
  const [dropvalue, setdropvalue] = useState("");
  const [setlodingdata, setsetlodingdata] = useState(true);
  async function FileUplodeToFireStore(e, Name, upoldevalue) {
    //     if(e!==''){
    //     upoldevalue({ label: Name, value: 50 });
    //     const file = e;
    // var res='';
    //     const file_data = e;
    //     const storage_ref = fire_storage.ref();
    //     var regex = new RegExp('[^.]+$');
    //     const metadata = {
    //     contentType: file.type
    //     };
    //     const fileref = storage_ref.child('TESTUPLOAD_Dinesh/'+timese+file.name).put(file,metadata);
    //     return await fileref.snapshot.ref.getDownloadURL().then((downloadURL) => {
    //       upoldevalue({label:Name,value:100});
    //       return downloadURL;
    //       });
    //   }
  }
  const datesecond = (e) => {
    return new Date(e).getTime() / 1000;
  };
  async function fileuplode(e, setdata, id, upoldevalue) {
    e.preventDefault();
    if (e.target.files[0] !== undefined) {
      const storage_ref = fire_storage.ref();

      const file = e.target.files[0];
      console.log("file", file);
      const metadata = {
        contentType: file.type,
      };
      var timese = Math.floor(new Date().getTime() / 1000);

      const fileref = await storage_ref
        .child("vehicle_info/" + timese + file.name)
        .put(file, metadata)
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then((url) => {
          upoldevalue({ label: "file", value: 100 });
          setdata(url);
          console.log(url);
        })
        .catch(console.error);

      let reader = new FileReader();
      reader.onloadend = () => {
        document.getElementById(id).style.backgroundImage =
          "url(" + reader.result + ")";
      };
      reader.readAsDataURL(file);
    }
  }
  async function handleSubmit(event) {
    event.preventDefault();
    console.log("calling");
    if (localStorage.getItem("token") !== undefined) {
      document.getElementById("submit").setAttribute("disabled", "disabled");
      setuplodedisplay(true);
      // setuplode1({ label: "RCI", value: 25 });
      // const result1 = await FileUplodeToFireStore(
      //   vehicleregistrationcardimage,
      //   "RCI",
      //   setuplode1
      // );
      // setuplode2({ label: "Insurance", value: 25 });
      // const result2 = await FileUplodeToFireStore(
      //   VehicleInsurance,
      //   "Insurance",
      //   setuplode2
      // );

      // setuplode3({ label: "FC", value: 25 });
      // const result3 = await FileUplodeToFireStore(
      //   VehicleFC,
      //   "FC",
      //   setuplode3
      // );

      // setuplode4({ label: "AGIR", value: 25 });
      // const result4 = await FileUplodeToFireStore(
      //   autogenius_inspection_report,
      //   "AGIR",
      //   setuplode4
      // );
      // setuplode5({ label: "AGIR", value: 25 });
      // const result5 = await FileUplodeToFireStore(
      //   proofofOwnershipDoc,
      //   "POD",
      //   setuplode5
      // );

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        vendor_id: vehicalVendor,
        registration_number: VehicleRegistrationNumber,
        brand: Vehiclebrand,
        model: VehicleModel,
        Category: vehicleCategory,
        joindate: datesecond(VehicleJoindata),
        city: VehicleCity,
        registration_card_image: vehicleregistrationcardimage,
        insurance: VehicleInsurance,
        insurance_validity: VehicleInsurancedate,
        fc: VehicleFC,
        fc_validity: datesecond(vehiclefcValidDate),
        autogenius_inspection_report: autogenius_inspection_report,
        autogenius_inspection_report_validity: datesecond(vehicleAGIRdate),
        proof_of_ownership: proofofOwnershipDoc,
        document_status: documentstatus,
        vehicle_color: VehicleColor,
      });
      console.log(raw);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
    

      fetch(
        "https://us-central1-africar-9309f.cloudfunctions.net/add_vehicles",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.status === true) {
            alert("vehicle added Successfully");
            document.getElementById("submit").removeAttribute("disabled");
          } else {
            document.getElementById("submit").removeAttribute("disabled");

            alert("Error :", result.message);
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      alert("Token error");
    }
  }
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/add_vehicles",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setsetlodingdata(true);
        if (result.status === false) {
          if (result.message !== undefined) {
            alert(result.message);
            setmessage(result.message);
            setvendorshow(false);
            setdisplayvalue(false);
            setsetlodingdata(true);
          } else {
            setdropvalue([]);
            setvendorshow(false);
          }
        } else {
          setdropvalue(result.vendors);
          setisget(false);
          setvendorshow(true);
          setsetlodingdata(false);
        }
      })
      .catch((error) => {
        setalertdata(JSON.stringify(error.TypeError));
        setvendorshow(false);
        setdisplayvalue(false);
        console.log("error", error);
      });
  }, [0]);

  if (setlodingdata) {
    return (
      <>
        <div className="content">
          <div className="w-100 ">
            <h5 style={{ display: "inline-block" }}>{message}</h5>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="content w-100">
        <div className="w-100" style={{ display: "inline-block" }}>
          <h5>Add New Vehicle</h5>
          <h5>{alertdata}</h5>
        </div>
        <Card className="m-2 p-2">
          {" "}
          <Form onSubmit={handleSubmit} className="w-100">
            <FormGroup>
              <Row>
                <Col md={4} className="mt-2">
                  <p className="Labelstyle">Vehicle Registration Number</p>

                  <Input
                    required
                    placeholder="Enter Vehicle Registration Number"
                    type="text"
                    value={VehicleRegistrationNumber}
                    onChange={(e) => {
                      setVehicleRegistrationNumber(e.target.value);
                    }}
                  ></Input>
                </Col>
                <Col md={4} className="mt-2">
                  <p className="Labelstyle">Vehicle Brand</p>

                  <Input
                    required
                    value={Vehiclebrand}
                    onChange={(e) => {
                      setVehiclebrand(e.target.value);
                    }}
                    placeholder="Enter Vehicle Brand"
                    type="text"
                  />
                </Col>
                <Col md={4} className="mt-2">
                  <p className="Labelstyle">Vehicle Model</p>

                  <Input
                    required
                    value={VehicleModel}
                    onChange={(e) => {
                      setVehicleModel(e.target.value);
                    }}
                    placeholder="Enter vehicle model"
                    type="text"
                  />
                </Col>
                
                <Col md={4} className="mt-2">
                  <p className="Labelstyle">Vehicle Category</p>

                  <Input
                    required
                    value={vehicleCategory}
                    onChange={(e) => {
                      setvehicleCategory(e.target.value);
                    }}
                    placeholder="select category"
                    type="select"
                  >
                    <option value="single">Single</option>
                    <option value="share">Share</option>
                  </Input>
                </Col>

                <Col md={4} className="mt-2">
                  <p className="Labelstyle">vehicle join date</p>
                  <Input
                    required
                    value={VehicleJoindata}
                    onChange={(e) => {
                      setVehicleJoindata(e.target.value);
                    }}
                    type="date"
                  />
                </Col>
                <Col md={4} className="mt-2">
                  <p className="Labelstyle">Vehicle city</p>
                  {cities.asasa.length !== 0 ? (
                    <Input
                      required
                      value={VehicleCity}
                      onChange={(e) => {
                        setVehicleCity(e.target.value);
                      }}
                      placeholder="Enter vehicle city"
                      type="select"
                    >
                      <option value="">Select city </option>
                      {cities.asasa.map((data, i) => {
                        return (
                          <option value={data} key={i}>
                            {data}
                          </option>
                        );
                      })}
                    </Input>
                  ) : null}
                </Col>
                {vendorshow ? (
                  dropvalue.length !== 0 ? (
                    <Col md={4} className="mt-2">
                      <p className="Labelstyle">Vendor Name</p>

                      <Input
                        required
                        value={vehicalVendor}
                        onChange={(e) => {
                          setvehicalVendor(e.target.value);
                        }}
                        type="select"
                      >
                        <option value="">Select Vendor </option>
                        {dropvalue.map((data, i) => {
                          return (
                            <option value={data.id} key={i}>
                              {data.name}
                            </option>
                          );
                        })}{" "}
                      </Input>
                    </Col>
                  ) : null
                ) : null}
                <Col md={4} className="mt-2">
                  <p className="Labelstyle">Vehicle Color</p>

                  <Input
                    required
                    value={VehicleColor}
                    onChange={(e) => {
                      setVehicleColor(e.target.value);
                    }}
                    type="select"
                  >
                    <option value="">Select Color </option>
                    <option value="Green">Green </option>
                    <option value="Blue">Blue </option>
                    <option value="Red">Red </option>
                    <option value="Black">Black </option>
                    <option value="White">White </option>

                    <option value="Yellow">Yellow </option>
                  </Input>
                </Col>
                <Col md={8} className="mt-2">
                  <p className="Labelstyle">Vehicle Registration Card Image</p>
                  <div className="ghor " id="Rcard_image">
                    {" "}
                  </div>
                  <Input
                    required
                    onChange={(e) => {
                      fileuplode(
                        e,
                        setvehicleregistrationcardimage,
                        "Rcard_image",
                        setuplode1
                      );
                    }}
                    placeholder="chose file"
                    type="file"
                  />
                </Col>
                <Col md={6} className="mt-2">
                  <p className="Labelstyle">Vehicle Insurance</p>
                  <div className="ghor " id="insurance">
                    {" "}
                  </div>
                  <Input
                    required
                    onChange={(e) => {
                      fileuplode(e, setVehicleInsurance, "insurance");
                    }}
                    type="file"
                  />
                </Col>

                <Col md={6} className="mt-2">
                  <p className="Labelstyle">Vehicle Insurance Validity</p>
                  <Input
                    required
                    value={insuranceValidity}
                    onChange={(e) => {
                      setinsuranceValidity(e.target.value);
                    }}
                    type="date"
                  />
                </Col>

                <Col md={6} className="mt-2 ">
                  <p className="Labelstyle">vehicle Fc Document</p>
                  <div className="ghor " id="fc">
                    {" "}
                  </div>
                  <Input
                    required
                    onChange={(e) => {
                      fileuplode(e, setVehicleFC, "fc", setuplode2);
                    }}
                    type="file"
                  />
                </Col>

                <Col md={6} className="mt-2">
                  <p className="Labelstyle">Vehicle FC Valid Till</p>

                  <Input
                    required
                    value={vehiclefcValidDate}
                    onChange={(e) => {
                      setvehiclefcValidDate(e.target.value);
                    }}
                    type="date"
                  />
                </Col>

                <Col md={6} className="mt-2">
                  <p className="Labelstyle">
                    Vehicle AutoGenius Inspection Report
                  </p>
                  <div className="ghor " id="air">
                    {" "}
                  </div>
                  <Input
                    required
                    onChange={(e) => {
                      fileuplode(
                        e,
                        setautogenius_inspection_report,
                        "air",
                        setuplode3
                      );
                    }}
                    type="file"
                  />
                </Col>

                <Col md={6} className="mt-2">
                  <p className="Labelstyle">
                    Vehicle AutoGenius inspection Report Validity
                  </p>

                  <Input
                    required
                    value={vehicleAGIRdate}
                    onChange={(e) => {
                      setvehicleAGIRdate(e.target.value);
                    }}
                    type="date"
                  />
                </Col>

                <Col md={6} className="mt-2">
                  <p className="Labelstyle">Proof Of Ownership</p>
                  <div className="ghor " id="POO">
                    {" "}
                  </div>
                  <Input
                    required
                    onChange={(e) => {
                      fileuplode(e, setproofofOwnershipDoc, "POO", setuplode4);
                    }}
                    type="file"
                  />
                </Col>
                <Col md={6} className="mt-2">
                  <FormGroup>
                    <p className="Labelstyle">Document status</p>

                    <Input
                      required
                      value={documentstatus}
                      onChange={(e) => {
                        setdocumentstatus(e.target.value);
                      }}
                      type="select"
                    >
                      {" "}
                      <option value="">Select Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Approved">Approved</option>
                      <option value="Rejected">Rejected</option>
                    </Input>
                  </FormGroup>{" "}
                </Col>
                <Col md={6}>
                  <p className="Labelstyle"> </p>
                  <Button id="submit" type="submit">
                    Submit
                  </Button>
                </Col>
                <Col md={6}>
                  <p className="Labelstyle"> </p>
                  <Button
                    className="cancelButton"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          </Form>
          {uplodedisplay ? (
            <Card>
              <div>
                <div className="text-center">
                  Uploding {uplode1.label}
                  {"...  "}
                  {uplode1.value}%
                </div>
                <Progress value={uplode1.value} />
              </div>
              <div>
                <div className="text-center">
                  Uploding {uplode2.label}
                  {"...  "}
                  {uplode2.value}%
                </div>
                <Progress value={uplode2.value} />
              </div>
              <div>
                <div className="text-center">
                  Uploding {uplode3.label}
                  {"...  "}
                  {uplode3.value}%
                </div>
                <Progress value={uplode3.value} />
              </div>
              <div>
                <div className="text-center">
                  Uploding {uplode4.label}
                  {"...  "}
                  {uplode4.value}%
                </div>
                <Progress value={uplode4.value} />
              </div>{" "}
              <div>
                <div className="text-center">
                  Uploding {uplode5.label}
                  {"...  "}
                  {uplode5.value}%
                </div>
                <Progress value={uplode5.value} />
              </div>
            </Card>
          ) : null}
        </Card>
      </div>
    </>
  );
}

export default AddnewVehicle;
