/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

// core components

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topic: "",
      title: "",
      body: "",
      topicState: false,
      titleState: false,
      bodyState: false,
    };
  }
  // function that returns true if value is body, false otherwise

  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
 
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
          this.setState({ filled: false });

        }
       
    this.setState({ filled: true });

  };
  isValidated = () => {
    if (
      this.state.topicState === "has-success" &&
      this.state.titleState === "has-success" &&
      this.state.bodyState === "has-success"
    ) {
      return true;
    } else {
      if (this.state.topicState !== "has-success") {
        this.setState({ topicState: "has-danger" });
      }
      if (this.state.titleState !== "has-success") {
        this.setState({ titleState: "has-danger" });
      }
      if (this.state.bodyState !== "has-success") {
        this.setState({ bodyState: "has-danger" });
      }
      return false;
    }
  };
  
  render() {
    return (
      <>
        <h5 className="info-text">
          Let's start with the basic information 
        </h5>
        <Row className="justify-content-center">
         
          <Col sm={10} className="mt-2">
            <InputGroup
              className={classnames(this.state.topicState, {
                "input-group-focus": this.state.topicFocus,
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-single-02" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="topic"
                placeholder="Topic (required)"
                type="text"
                onChange={(e) => this.change(e, "topic", "length", 1)}
                onFocus={(e) => this.setState({ topicFocus: true })}
                onBlur={(e) => this.setState({ topicFocus: false })}
              />
              {this.state.topicState === "has-danger" ? (
                <label className="error">This field is required.</label>
              ) : null}
            </InputGroup>
            <InputGroup
              className={classnames(this.state.titleState, {
                "input-group-focus": this.state.titleFocus,
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-circle-10" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="title"
                placeholder="Title (required)"
                type="text"
                onChange={(e) => this.change(e, "title", "length", 1)}
                onFocus={(e) => this.setState({ titleFocus: true })}
                onBlur={(e) => this.setState({ titleFocus: false })}
              />
              {this.state.titleState === "has-danger" ? (
                <label className="error">This field is required.</label>
              ) : null}
            </InputGroup>
            <InputGroup
              className={classnames(this.state.bodyState, {
                "input-group-focus": this.state.bodyFocus,
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-send" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="body"
                placeholder="Body (required)"
                type="body"
                onChange={(e) => this.change(e, "body", "length",1)}
                onFocus={(e) => this.setState({ bodyFocus: true })}
                onBlur={(e) => this.setState({ bodyFocus: false })}
                
              />
              {this.state.bodyState === "has-danger" ? (
                <label className="error">This field is required.</label>
              ) : null}
            </InputGroup>
         
          </Col>
          
        </Row>
      </>
    );
  }
}

export default Wizard;
