import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./Authcontext.js";

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();
  if (localStorage.getItem("user") === null) {
    return <Redirect to="/login" />;
  } else {
    return <Route {...rest} component={Component} user={currentUser}></Route>;
  }
}
