
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Spinner,
} from "reactstrap";
import { addvehicalsData } from "../datachane/actions";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
// core components
import ReactTable from "../../components/ReactTable/ReactTable";
function AllRiders() {
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [errormessage, seterrormessage] = useState(null);
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false);
  const dd = useSelector((state) => state.All_Vehiclesstore);
  const dispatch = useDispatch();
  const [nextkey, setnextkey] = useState("");
  useEffect(() => {
    if (dd.arr.length === 0) {
      calldataall_vehicles("");
    }
  }, [0]);
  async function callvehicleRepeat(e) {

      if (e >= 2) {
        var d = e;
        var da = "";
        if (dd.nextKey !== null) {
          da = dd.nextKey;
          while (d !== 0) {
         
            var star=  await calldataall_vehicles(da);
            console.log(star);
            if (star) {
              d--;
            } else {
              d = 0;
             break;
            }
    
            
          }
        }
       
      }
    }

 async function calldataall_vehicles(nexttokenparam) {
    if(nexttokenparam===undefined){
      nexttokenparam=nextkey;
    }
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/all_vehicles?start=" +
        nexttokenparam,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setnextkey(result.start);
          dispatch(addvehicalsData(result.data, result.start));
          setnexttoggle(true);
          setspinnershow(false);
          return true;
        } else {
          dispatch(addvehicalsData(null, null));
          alert(result.message);
          setnexttoggle(false);

          return false;

        }
      })

      .catch((error) => {
        setnexttoggle(false);
        setmessagttouser("Unable to get data....ERROR");
        seterrormessage("No data left to load");
        setTimeout(() => {
          seterrormessage("");
        }, 3000);
        return false;
        console.log("error", error);
      });
  }

  if (dd.arr.length === 0) {
    return (
      <>
        <div className="content">
          <div className="w-100">
            <h5 style={{ display: "inline-block" }}>Vehicles Details</h5>
            <p>{messagttouser}</p>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="content ">
        <div className="w-100" style={{ display: "inline-block" }}>
          <h5 style={{ display: "inline-block" }}>Vehicles Details </h5>
          {errormessage}
          {"  "}
          {sipnnershow && (
            <div style={{ display: "inline-block" }}>
              Loading data... <Spinner size="sm" color="primary" />
            </div>
          )}
        </div>
        <ReactTable
          calldata={calldataall_vehicles}
          countforcall={callvehicleRepeat}
          isnext={nexttoggle}
          hidecolumnsdata={[
            "fc_validity",
            "document_status",
            "autogenius_inspection_report_validity",
            "insurance_validity",
            "action",
          ]}
          data={dd.arr}
          columns={[
            {
              Header: "vehicle driver name",
              accessor: "driver_name",
            },
            {
              Header: "contactno",
              accessor: "contactno",
            },
            {
              Header: "vehicle Registration number",
              accessor: "registration_number",
            },
            {
              Header: "vehicle brand",
              accessor: "brand",
            },
            {
              Header: "vehicle model",
              accessor: "model",
            },
            {
              Header: "vehicle joined date",
              accessor: "joindate",
            },
            {
              Header: "vehicle city",
              accessor: "city",
            },
            {
              Header: "vehicle vendor name",
              accessor: "vendor",
            },
            {
              Header: "vehicle insurance validity",
              accessor: "insurance_validity",
            },
            {
              Header: "vehicle_Auto inspection_report validity",
              accessor: "autogenius_inspection_report_validity",
            },

            {
              Header: "document_status",
              accessor: "document_status",
            },
            {
              Header: "vehicle status",
              accessor: "vehicle_status",
            },

            {
              Header: "vehicle fc_validity",
              accessor: "fc_validity",
            },
            {
              Header: "action",
              accessor: "",
            },
          ]}
          /*
            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
          */
          className="-striped -highlight primary-pagination"
        />
      </div>
    </>
  );
}

export default AllRiders;
