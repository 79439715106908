import React, { useEffect, useState } from "react";

// reactstrap components
import { Button, Card, Spinner } from "reactstrap";
import { addOutstandingDetails } from "../datachane/actions";
import { useSelector, useDispatch } from "react-redux";

// core components
import ReactTable from "components/ReactTable/ReactTable2";

function Outstandingreport() {
  const dd = useSelector((state) => state.AccountsOutStandingReport);
  const dispatch = useDispatch();
  const [nextkey, setnextkey] = useState(0);
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false);
  useEffect(() => {
    if (dd.arr.length === 0) {
      calldatasetApi(0);
    }
  }, []);
  async function calldataallRepeat(e) {
    var d = e;
    var da = "";
    if (dd.nextKey !== "") {
      da = dd.nextKey;
    } else {
      da = nextkey;
    }
    while (d !== 0) {
      d--;
      if (da !== null && nexttoggle) {
        da = await calldatasetApi(da);
      } else {
        break;
      }
    }
  }
  async function calldatasetApi(nexttokenparam) {
    setspinnershow(true);
    if (nexttokenparam === undefined) {
      nexttokenparam = dd.nextKey || nextkey;
    }
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return await fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/accounts?type=outstanding&start=" +
        nexttokenparam,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true && result.data.length !== 0) {
          result.data.map((doc)=>{
            doc.last_paid_date=new Date(doc.last_paid_date*1000).toLocaleString("en-US", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
          })
          dispatch(addOutstandingDetails(result.data, result.start));
          setnextkey(result.start);

          setnexttoggle(true);
          setspinnershow(false);
          return result.start;
        } else {
          setspinnershow(false);
          setnexttoggle(false);
          setmessagttouser("No Data To Load ");
          console.log("error", result.message);
          return null;
        }
      })
      .catch((error) => {
        setspinnershow(false);
        setnexttoggle(false);
        setmessagttouser("Unable to get data....ERROR");
        console.log("error", error);
      });
  }
  if (dd.arr.length === 0) {
    return (
      <>
        <div className="content">
          <div className="w-100">
            <h5 style={{ display: "inline-block" }}>
            Out Standing Report
            </h5>
            <p>{messagttouser}</p>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="content ">
        <div className="w-100" style={{ display: "inline-block" }}>
          <h5 style={{ display: "inline-block" }}>Out Standing Report </h5>
          {"  "}
          {sipnnershow && (
            <div style={{ display: "inline-block" }}>
              Loading data... <Spinner size="sm" color="primary" />
            </div>
          )}
        </div>{" "}
        <ReactTable
          calldata={calldatasetApi}
          data={dd.arr}
          countforcall={calldataallRepeat}
          isnext={nexttoggle}
          counts={dd.TotalCount}
          columns={[
           
            {
              Header: "Driver name",
              accessor: "name",
            },
            
            {
              Header: "Driver email id",
              accessor: "email",
            },

            {
              Header: "last paid date",
              accessor: "last_paid_date",
            },
            
            {
              Header: "Balance Amount",
              accessor: "balance",
            },
            
          ]}
          /*
            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
          */
          className="-striped -highlight primary-pagination"
        />
      </div>
    </>
  );
}

export default Outstandingreport;



