
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Input, Row, Col, Spinner, Form,

  InputGroup,
  InputGroupAddon,
  InputGroupText,

} from "reactstrap";
import { BrowserRouter as Router, Link, Route, useHistory } from "react-router-dom";

import { addPromocode } from "../datachane/actions";
import { useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux'
// core components
import ReactTable from "../../components/ReactTable/ReactTable";


function PromCode() {
  const dispatch = useDispatch();
  const [messagttouser, setmessagttouser] = useState("Loading Data...")
  const [errormessage, seterrormessage] = useState(null)
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false)
  const [city, setcity] = useState('')
  let history = useHistory();
  const dd = useSelector(state => state.Promocode)
  let cities = useSelector((state) => state.Ratecarddata);

  const [nextkey, setnextkey] = useState('')


  async function handleSearchSubmit(event) {
    event.preventDefault();
    setspinnershow(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`https://us-central1-africar-9309f.cloudfunctions.net/coupon?city=${city}&start=`+'', requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === true) {
          setnextkey(result.start);
          result.data.map((data) => {
            data.redeem_by = new Date(data.redeem_by * 1000).toISOString().split('T')[0];
            if (data.valid === true) { data.valid1 = <i classname="fa fa-check btn" disabled aria-hidden="true"></i> } else {
              data.valid1 = <i class="fa fa-times btn" disabled aria-hidden="true"></i>;
            }
            data.update = <div><Button onClick={() => history.push({
              pathname: '/admin/UpdatePromoCode',
              customNameData: data,
            })}>Edit</Button></div>
          })
          dispatch(addPromocode(result.data, result.start))
          setnexttoggle(true)
          setspinnershow(false)
        } else {
          setspinnershow(false)
          dispatch(addPromocode([], ''))
          seterrormessage(result.message);
        }
      })

      .catch(error => {
        setnexttoggle(false)
        seterrormessage("No data left to load");
        setTimeout(() => {
          seterrormessage("");

        }, 3000);
        console.log('error', error)
      });
  }

  if (cities===null) {
    return (
      <>
        <div className="content">
          <div className="w-100 ">
            <h5 style={{ display: "inline-block" }}>{errormessage}</h5>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="content w-100">
        <div className="w-100" style={{ display: 'inline-block' }}>
          <h5 style={{ display: 'inline-block' }}>Promo Code  </h5>
          <p>{errormessage !== null ? errormessage : ''}</p>
          {'  '}{sipnnershow && <div style={{ display: 'inline-block' }}>
            Loding data... <Spinner size="sm" color="primary" />
          </div>}
          <Form onSubmit={(e)=>handleSearchSubmit(e)} className='w-100'>

            <Row>
              <Col md={4}  ><FormGroup>
                <p className="-Formlable">Select City</p>

                <Input
                  required
                  type="select"
                  value={city}
                  onChange={(e) => {
                    setcity(e.target.value);
                  }}
                >
                  <option value="">Select City </option>
                  {cities.asasa.map((data, i) => {
                    return (
                      <option value={data} key={i}>
                        {data}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup></Col>

              <Col md={4} className='mt-4' ><FormGroup>
                <Button className="w-100"  type="submit">
                  Search Promo Code
                </Button>
                <div>
                  {errormessage != null ? (
                    <p style={{ color: "red" }}>{errormessage}</p>
                  ) : null}
                </div>
              </FormGroup></Col>

              <Col md={4} className='mt-4' ><Button className='ml-5' onClick={() => { history.push('/admin/AddPromoCode') }}>Add Promo Code</Button></Col>
            </Row>
          </Form>
        </div>
        <Card className=" p-3"> <ReactTable
          calldata={() => { }}
          isnext={nexttoggle}
          data={dd.arr}
          isnext={nexttoggle}
          hidecolumnsdata={[
            "contactno",
            "vendor",
            "joindate",
            "duration_in_months",
            "max_redemption",
            "max_redemption_per_user",
            "times_redeemed",
          ]}
          columns={[
            {
              Header: "Coupon Name",
              accessor: "id",
            },
            {
              Header: "City",
              accessor: "city",
            },
            {
              Header: "Amount Off",
              accessor: "amount_off",

            },
            {
              Header: "Apply When",
              accessor: "contactno",
            },

            {
              Header: "Created On",
              accessor: "vendor",
            },
            {
              Header: "Description",
              accessor: "description",
            }, {
              Header: "Min Fare",
              accessor: "joindate",
            }, {
              Header: "Percent Off",
              accessor: "percent_off",
            }, {
              Header: "Duration",
              accessor: "duration",
            }, {
              Header: "Duration in Mounth",
              accessor: "duration_in_months",
            }, {
              Header: "Reedem By",
              accessor: "redeem_by",
            }, {
              Header: "Max Redemption",
              accessor: "max_redemption",
            },
            {
              Header: "Max Redemption(Per User)",
              accessor: "max_redemption_per_user",
            }, {
              Header: "Times Reedemed",
              accessor: "times_redeemed",
            }, {
              Header: "Total Discount",
              accessor: "total_discount",
            }, {
              Header: "Action",
              accessor: "update",
            },

          ]}
          /*
            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
          */
          className="-striped -highlight primary-pagination"
        />
        </Card>
      </div>
    </>
  );
}

export default PromCode