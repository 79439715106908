import React, { useState } from "react";

// reactstrap components
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useSelector } from "react-redux";
// core components
import ReactTable from "../../components/ReactTable/ReactTable";
import { useHistory } from "react-router-dom";

function CityRateCards() {
  let Ratecarddata = useSelector((state) => state.Ratecarddata);
  const isloading = useSelector((state) => state.Isload);
  let history = useHistory();
  Ratecarddata.arr.map((data) => {
    data.action = (
      <div className="">
        {/* use this button to add a edit kind of action */}
        <Button
          onClick={() => {history.push({
            pathname: '/admin/UpdateCityRateCard',
            customCRCData: data,
          })}}
          color="warning"
          size="sm"
          className="btn-icon btn-link edit"
        >
          <i className="fa fa-edit" />
        </Button>{" "}
        {/* use this button to remove the data row */}
        <Button
          onClick={() => {
            toggle(data);
          }}
          color="info"
          size="sm"
          className="btn-icon btn-link like"
        >
          <i className="fa fa-eye" aria-hidden="true"></i>
        </Button>{" "}
      </div>
    );
  });
  //  const dispatch = useDispatch();
  const [nexttoggle, setnexttoggle] = useState(false);
  const [modal, setModal] = useState(false);
  const [modaldata, setmodaldata] = useState("");
  const toggle = (e) => {
    setmodaldata(e);
    setModal(!modal);
  };
  if (Ratecarddata === null) {
    return (
      <>
        <div className="content">
          <div className="w-100 ">
            <h5 style={{ display: "inline-block" }}>no data available...</h5>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="content mt-5 p-3">
        <Card className="h-75 m-1 p-2">
          <div className="w-100 mt-3">
            <Row>
              <Col md={9} className="m-1">
                <h5 style={{ display: "inline-block" }}>Rate Card Details</h5>{" "}
              </Col>
              <Col md={2} className="m-1">
                <Button
                  onClick={() => {
                    history.push("/admin/AddCityRateCard");
                  }}
                >
                  Add City Rate Card
                </Button>
              </Col>
            </Row>
          </div>
          <ReactTable
                  calldata={()=>{}}

            data={Ratecarddata.arr}
            isnext={nexttoggle}
            hidecolumnsdata={[
              "base_fare",
              "lasg_fee",
              "time_multiplier",
              "share_percentage_2",
              "0-4",
"4-8",
"8-12",
"12-16",
"16>",
            ]}
            columns={[
              {
                Header: "city name",
                accessor: "city_name",
              },
              {
                Header: "ride Type",
                accessor: "type",
              },
              {
                Header: "base fare",
                accessor: "base_fare",
              },
              {
                Header: "GOVT. FEE",
                accessor: "lasg_fee",
              },

              {
                Header: "Minimum fare",
                accessor: "min_fare",
              },
              {
                Header: "Convenience multiplier",
                accessor: "convenience_multiplier",
              },
              {
                Header: "peak time charge",
                accessor: "peak_multiplier",
              },
              {
                Header: "time multiplier",
                accessor: "time_multiplier",
              },

              {
                Header: "Share Percentage for 2 seats",
                accessor: "share_percentage_2",
              },

              {
                Header: "Taxes",
                accessor: "taxes",
              },
              {
                Header: "distance_multiplier 0-4 KM",
                accessor: "0-4",
              },{
                Header: "distance_multiplier 4-8 KM",
                accessor: "4-8",
              },{
                Header: "distance_multiplier 8-12 KM",
                accessor: "8-12",
              },
              {
                Header: "distance_multiplier 12-16 KM",
                accessor: "12-16",
              },
              {
                Header: "distance_multiplier 16> KM",
                accessor: "16>",
              },
              {
                Header: "Toll Charges",
                accessor: "toll_charges",
              },
              {
                Header: "action",
                accessor: "action",
              },
            ]}
            /*
            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
          */
            className="-striped -highlight info-pagination"
          />
        </Card>
        <Modal size="lg" className="w-100" isOpen={modal} toggle={toggle}>
          <ModalHeader>Rate Card Details</ModalHeader>
          <ModalBody>
            <div>
              <div className="">
                <h5 style={{textTransform: 'capitalize'}}>{modaldata.city_name} City</h5>
              </div>
              <Row>
                <Col md={12} xl={6} style={{borderLeft:'2px solid black'}}>
                  <Row>
                    <Col md={6}>
                      <h6> Type :</h6>
                    </Col>
                    <Col md={6}>
                      <p>{modaldata.type}</p>
                    </Col>
                    <Col md={6}>
                      <h6> Base Fare :</h6>
                    </Col>
                    <Col md={6}>
                      <p>{modaldata.base_fare}</p>
                    </Col>
                    <Col md={6}>
                      <h6> GOVT. FEE :</h6>
                    </Col>
                    <Col md={6}>
                      <p>{modaldata.lasg_fee}</p>
                    </Col>
                    <Col md={6}>
                      <h6> Min Fare :</h6>
                    </Col>
                    <Col md={6}>
                      <p>{modaldata.min_fare}</p>
                    </Col>
                    <Col md={6}>
                      <h6> Convenience Multiplier :</h6>
                    </Col>
                    <Col md={6}>
                      <p>{modaldata.convenience_multiplier}</p>
                    </Col>
                    <Col md={6}>
                      <h6> Peak Multiplier :</h6>
                    </Col>
                    <Col md={6}>
                      <p>{modaldata.peak_multiplier}</p>
                    </Col>
                    <Col md={6}>
                      <h6> Time Multiplier :</h6>
                    </Col>
                    <Col md={6}>
                      <p>{modaldata.time_multiplier}</p>
                    </Col>
                  </Row>
                </Col>
                <Col md={12} xl={6} style={{borderLeft:'2px solid black'}} >
                  <Row>
                    <Col md={6}>
                      <h6 > Share Percentage for 2 seats :</h6>
                    </Col>
                    <Col md={6}>
                      <p>{modaldata.share_percentage_2}</p>
                    </Col>
                    <Col md={6}>
                      <h6> Taxes :</h6>
                    </Col>
                    <Col md={6}>
                      <p>{modaldata.taxes}</p>
                    </Col>
                    <Col md={6}>
                      <h6> Toll Charges :</h6>
                    </Col>
                    <Col md={6}>
                      <p>{modaldata.toll_charges}</p>
                    </Col>
                    <Col md={6}>
                      <h6> Distance Multiplier (KM):</h6>
                    </Col>
                    <Col md={6}>
                    <Row>
                   <Col sm={6}> <h6>0-4 :</h6></Col>
                   <Col sm={6}>{modaldata['0-4']}</Col>
                   <Col sm={6}> <h6>4-8 :</h6></Col>
                   <Col sm={6}>{modaldata['4-8']}</Col>
                   <Col sm={6}> <h6>8-12 :</h6></Col>
                   <Col sm={6}>{modaldata['8-12']}</Col>
                   <Col sm={6}> <h6>12-16 :</h6></Col>
                   <Col sm={6}>{modaldata['12-16']}</Col>    
                   <Col sm={6}> <h6>16{'>'} :</h6></Col>
                   <Col sm={6}>{modaldata['16>']}</Col>  
                                 </Row>

                   </Col>

                  </Row>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={()=>{
               setmodaldata('');
               setModal(!modal);
            }}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default CityRateCards;
