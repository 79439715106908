
const initailState = {
    arr:[],
    nextKey:''
}
const CashCollection=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_CASH_COLLECTION_DATA':
            return{ 
                ...state,
                arr: state.arr.concat(action.payload),
                nextKey:action.keynext
            }
            case 'REMOVE_CASH_COLLECTION_DATA':
            return{ 
                arr:state.arr.filter(p=>p.id!==action.payload)
            }
        default:
            return state;
    }
}

export default CashCollection