
const initailState = {
    arr:[],
    nextKey:''
}

const userhie=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_user_DATA':
            return{ 
                ...state,
                arr: action.payload,
            }
        default:
            return state;
    }
}

export default userhie