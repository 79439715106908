/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{useEffect,useState} from "react";

// reactstrap components
import {
  Card,
  Spinner 

} from "reactstrap";
import {useSelector,useDispatch} from 'react-redux';

// core components
import ReactTable from "../../components/ReactTable/ReactTable2";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";



function Earningreports() {
  const dd=useSelector(state => state.All_Riders)
  const dispatch = useDispatch();
const [nextkey, setnextkey] = useState('')
const [messagttouser, setmessagttouser] = useState("Loading Data...")
const [nexttoggle, setnexttoggle] = useState(true);
const [sipnnershow, setspinnershow] = useState(false)
  useEffect(() => {
    if(dd.arr.length===0){
  }
}, []);

if(false){
  return (
    <>
      <div className="content">
        <div className="w-100">
          <h5 style={{ display: 'inline-block' }}>RIDERS INFO</h5>
<p>{messagttouser}</p>     
        </div> 
        </div>
        </>)
}
  return (
    <>
      <div className="content ">
        <Card className="p-2">
          <Card className="mt-2 p-2">
        <div className="w-100"  style={{ display: 'inline-block' }}>
          <h5 style={{ display: 'inline-block' }}>Earning reports  </h5>
          {'  '}{sipnnershow&&<div  style={{ display: 'inline-block' }}>  
         Loading data... <Spinner size="sm" color="primary" />  
    </div>}

    <Row className="m-auto">
      <Col md={5} className="colorbg1 text-center p-3 m-auto">
     <div className='textlarge'>Total Vendor Paid</div>
      <div>0</div>
      </Col>
      <Col md={5} className="colorbg1 text-center p-3 m-auto">
      <div className='textlarge'>Total Vendor Due</div>
      <div>0</div>
      </Col>
      <Col sm={12}><div className="m-3 p-2 "> 
      <span className="textlarge">Africar Earnings :</span><span>{" "}&#8358;{" "}</span>{"  "}<span>0</span></div></Col>
    </Row>
        </div>  </Card> 
        <Card className="p-2">
        <ReactTable
                calldata={()=>{}}

          data={[]}
          isnext={false}
          columns={[
            {
              Header: "Vendor name",
              accessor: "first_name",
            },
            {
              Header: "africar commission",
              accessor: "gender",
            },
            {
              Header: "commision paid",
              accessor: "email_id",
            },
            {
              Header: "commision Due",
              accessor: "phone_number",
            },
            {
              Header: "Action",
              accessor: "joined_on",
            },
            
          ]}
         
          className="-striped -highlight primary-pagination"
        />
        </Card>
</Card>
      </div>
    </>
  );
}

export default Earningreports

