const initailState = []

const Ongoingridesdata=(state=initailState,action)=>{
    switch(action.type){
        case 'ON_GOING_RIDES':
            return action.payload
        default:
            return state;
    }
}

export default Ongoingridesdata