import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Input, Row, Col, Form, Label, Spinner

} from "reactstrap";

// core components
import { adduserpermition as add_user_permission, removeuserpermition,Clearuserpermition } from "../datachane/actions";
import { useSelector, useDispatch } from 'react-redux';
//display userroles
function Userrolesdisplay({ data, keys, updatedata1, removeuser }) {

  const [display, setdisplay] = useState(false)
  const [datas, setdatas] = useState({
    [data[0]]: []
  })
  const [state12, setstate12] = useState(data[0])
  function dasdkf(data, state) {
    if (state) {
      datas[state12].push(data)

    } else {
      datas[state12] = datas[state12].filter(item => item !== data)
    }
    updatedata1(datas);
  }

  return (
    <Col md={6} key={keys} >
      <FormGroup check className="pull-left">
        <Label check>{data[0]}
          <Input
            name={data[0]}
            type="checkbox"
            onChange={(e) => {
              if (e.target.checked) {
                setdisplay(!display)
                dasdkf("R", true)
              } else {
                setdisplay(!display);
                setdatas({ [data[0]]: [] });
                removeuser(data[0]);
              }
            }
            }
          />
          <span className="form-check-sign" />
        </Label>
        <p></p>


      </FormGroup>

      {display ? <div className="w-100">
        <Row>
          {data[1].map((roles, key) => {
            return <Col key={key} md={4}><FormGroup check className="pull-left">
              <Label check>
                <Input key={key} value={roles} name={roles} type="checkbox"
                  defaultChecked={roles === "R" ? true : false}
                  onChange={(e) => {
                    dasdkf(e.target.value, e.target.checked)
                  }}
                />
                <span className="form-check-sign" />
                {roles}
              </Label>
            </FormGroup></Col>
          })}
        </Row>

      </div> : null}</Col>



  )
}
//main creat euser role
function Createuserrole() {
  const userPermissionsdata = useSelector(state => state.userrolecreation);

  const [permissiondata, setpermissiondata] = useState()
  const [parentdata, setparentdata] = useState()

  const [messagttouser, setmessagttouser] = useState("Loading Data...")
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(true);

  //form value
  const [Comes_under, set_comes_under] = useState()
  const [RoleName, setRoleName] = useState('')
const [comesunder, setcomesunder] = useState(false)
//disatch function
  const dispatch = useDispatch();

//rudex function call to update userrole values
  function updatedata(data) {
    dispatch(add_user_permission(data, Comes_under))
  }
  function deletedata(data) {
    dispatch(removeuserpermition(data))
  }

  useEffect(() => {
    callPermissition();
    return () => {
      dispatch(Clearuserpermition())
    }
  }, [])
  function createuserroleaicall() {
    if(userPermissionsdata.length!==0&&RoleName!==''&&Comes_under!==null){
      setnexttoggle(false);
      setspinnershow(true);
      var permissionsdata = {}
      userPermissionsdata.map((data) => {
          if (data != null) {
              var dda = Object.entries(data);
              permissionsdata[dda[0][0]] = dda[0][1];
          }
      }
      )
      if(comesunder){
        permissionsdata.mapping=comesunder
      }else{
        permissionsdata.mapping=comesunder
      }
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
          "name": RoleName,
          "permissions": permissionsdata,
          "parent": Comes_under
      });
  console.log({
    "name": RoleName,
    "permissions": permissionsdata,
    "parent": Comes_under
});
      var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
      };
      // fetch("https://us-central1-africar-9309f.cloudfunctions.net/role", requestOptions)
      //     .then(response => response.text())
      //     .then(result => {
      //         alert("user created succssfull")
      //         setnexttoggle(true)
      //         setspinnershow(false)
  
      //     })
      //     .catch(error => {
      //         alert("error")
      //         setnexttoggle(false)
  
      //         console.log('Error : ', error)
      //     });
  
    }else{
alert("please Give the correct values");
    }



}
//apicall for to et permissition list
  function callPermissition() {
    setspinnershow(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch("https://us-central1-africar-9309f.cloudfunctions.net/role?type=permissions", requestOptions)
      .then(response => response.json())
      .then(result => {
        let permissions = result.permissions
        const dda = Object.entries(permissions);
        setparentdata(result.parent)
        setpermissiondata(dda)
        setspinnershow(false)

      })

      .catch(error => {
        setmessagttouser("Unable to get data....ERROR"); console.log('Error : ', error)
      });
  }


  if (sipnnershow) {
    return (
      <>
        <div className="content">
          <div className="w-100">
            <h5 style={{ display: 'inline-block' }}>Create User Role</h5>
            <p>{messagttouser}</p>
          </div>
        </div>
      </>)
  }
  return (
    <>
      <div className="content ">
        <div className="w-100" style={{ display: 'inline-block' }}>
          <h5 style={{ display: 'inline-block' }}></h5>
          {'  '}{sipnnershow && <div style={{ display: 'inline-block' }}>
            Loading data... <Spinner size="sm" color="primary" />
          </div>}

        </div>
        <Card>

          <CardHeader>
            <CardTitle tag="h4">Create User Role</CardTitle>
          </CardHeader>
          <CardBody>
            <FormGroup className={`has-label `}>
              <p> User Role Comes Under</p>
              <Input
                placeholder="Comes under" type="select"
                value={Comes_under} required
                onChange={(e) => {
                  set_comes_under(e.target.value)
                }}
              ><option value={null}> </option>
                {parentdata.map((data, i) => {
                  return <option value={data} key={i}>{data}</option>

                })}
              </Input>
            </FormGroup >

            <FormGroup className={`has-label `}>
              <h6>User Role </h6>
              <Input required
                name="userrole"
                type="text"
                value={RoleName}
                onChange={(e) => setRoleName(e.target.value)}
              />
            </FormGroup>
            <Row className="w-100">
              <Col md={12}>  <h6>User Role Permissions</h6></Col>
              <Col md={6}>
              <FormGroup check className="pull-left">
              <Label check>
                <Input type="checkbox"
                 value={comesunder}
                  onChange={(e) => {
                    setcomesunder(e.target.checked)
                  }}
                />
                <span className="form-check-sign" />
                Drivers/Vehicles can be mapped to this user role.
                              </Label>
            </FormGroup>
              </Col>
              {permissiondata.map((dataset, key) => {
                return <Userrolesdisplay key={key} keys={key} removeuser={deletedata} data={dataset} updatedata1={updatedata} />

              })

              }
            </Row>
          </CardBody>
          <Row>
            <Col md={6}></Col>
            <Col md={4}>
            <Button                 disabled={!nexttoggle}
 onClick={() => createuserroleaicall()} className="w-100">Add User Role</Button>

            </Col>

          </Row>
        </Card>

      </div>
    </>
  );
}

export default Createuserrole