
const initailState = {
    war_room:null,
}
const Warroomcity=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_WAR_ROOM_CITY':
            return{ 
                war_room: action.payload,
            }
        default:
            return state;
    }
}

export default Warroomcity