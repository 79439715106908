import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,Spinner,
} from "reactstrap";
import { searchdata, } from "../datachane/actions";
import ReactTable from "components/ReactTable/ReactTable2.js";

import { useSelector, useDispatch } from "react-redux";
import { func } from "prop-types";
// core components
function CashCollection() {
  const isloading = useSelector((state) => state.Isload);
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [errormessage, seterrormessage] = useState(null);
  const Searchdataset = useSelector((state) => state.Searchdata.arr);
  const [sipnnershow, setspinnershow] = useState(false);

  //UnlinkedVehicle
  const dispatch = useDispatch();
  const [search_value, setsearch_value] = useState("");

  async function handleSearchSubmit(event) {
    event.preventDefault();
    setspinnershow(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = "";
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    
    fetch("https://us-central1-africar-9309f.cloudfunctions.net/search_users?user=driver&search=" +
    search_value +
    "&type=cash_collection", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === false) {
          setspinnershow(false);

          seterrormessage(result.message);
          setmessagttouser(result.message)
          setTimeout(() => {
            seterrormessage(null);
          }, 2000);
        } else {
          const data1 = {
              id: result.data.driver_id,
              name: result.data.name,
              paid_today: result.data.paid_today.toFixed(2)||0,
              pending_payment: result.data.pending_payment.toFixed(2)||0,
              total_due: result.data.total_due?result.data.total_due.toFixed(2):0,
              total_revenue_for_current_day: result.data.total_revenue_for_current_day||0,
              true1:result.data.pending_payment !== 0 ? (
                  <div className="actions-right" style={{ fontsize: "60px" }}>
                    <button
                      id={"B" + result.data.driver_id}
                      className="btn w-100"
                      onClick={() => {
                        document.getElementById("s" + result.data.driver_id).innerHTML =
                          result.data.total_due;
                        document.getElementById("i" + result.data.driver_id).value =
                          result.data.total_due;
                        document
                          .getElementById("B" + result.data.driver_id)
                          .setAttribute("disabled", "disabled");
                      }}
                    >
                      <i className="fa fa-check-circle" />
                    </button>
                  </div>
                ) : null,
              true2:
                result.data.pending_payment !== 0 ? (
                  <div className=" mt-2" style={{ fontsize: "60px" }}>
                      <FormGroup>
                        <Input
                          placeholder="Amount"
                          type="text"
                          id={"i" + result.data.driver_id}
                          onChange={(e) => {
                            document.getElementById(
                              "s" + result.data.driver_id
                            ).innerHTML = e.target.value;
                          }}
                        />
                      </FormGroup>
                  </div>
                ) : null,
              actions:
                result.data.pending_payment !== 0 ? (
                  <div className="mt-2">
                    <div>
                      <span>&#8358;</span>
                      <span className="mr-3" id={"s" + result.data.driver_id}></span>
                    </div>
                    {/* use this button to add a like kind of action */}
                    <Button
                     id={"C" + result.data.driver_id}
                      onClick={() => {
                        addpaiddriver(result.data);
                      }}
                      color="info"
                      size="xl"
                      className="btn "
                    >
                      COLLECT{" "}
                    </Button>{" "}
                  </div>
                ) : null,
            
          }
          dispatch(searchdata(data1));
          setspinnershow(false);

        }
      })
      .catch((error) => {console.log("error", error);    setspinnershow(false);
    });
  }
  function addpaiddriver(data) {
    var amount = Number(document.getElementById("i" + data.driver_id).value);
    if (amount !== 0 || Number(data.total_due) !== 0) {
      if (Number(amount) <= Number(data.total_due)) {
         document.getElementById('B' + data.driver_id).setAttribute('disabled', 'disabled');
         document.getElementById('C' + data.driver_id).setAttribute('disabled', 'disabled');

        document.getElementById("s" + data.driver_id).innerHTML = amount;

        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("token")
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          driver_id: data.id,
          driver_paid: amount,
        });
        console.log(JSON.parse(raw));
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          "https://us-central1-africar-9309f.cloudfunctions.net/driver_payments",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status === true) {
              alert("Payment Collected");
            } else {
              alert(result.message);
            }
          })
          .catch((error) => console.log("error", error));
        document.getElementById("s" + data.driver_id).innerHTML = "";
        document.getElementById("i" + data.driver_id).value = "";
        document
          .getElementById("B" + data.driver_id)
          .removeAttribute("disabled");
          document
          .getElementById("C" + data.driver_id)
          .removeAttribute("disabled");
      } else {
        alert("Collection amount must not exceed pending amount");
      }
    } else {
      alert("Payment cannot be Made");
    }
  }


  if (isloading) {
    return (
      <>
        <div className="content">
          <div className="w-100 ">
            <h5 style={{ display: "inline-block" }}>{messagttouser}</h5>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="content ">
        <div className="w-100 mt-3">
          <h5 style={{ display: "inline-block" }}>REVENUE COLLECTION</h5>
        </div>

        <Card className="h-75 m-1 p-2">
          <Row>
            <Col md={12}>
              <Card className="m-2 p-1">
                <div className="content ">
                  <div className="w-100" style={{ display: "inline-block" }}>
                    <h5 style={{ display: "inline-block" }}> Search Driver</h5>
                    <p>"Please Enter a Valid Contact Number/Email ID " </p>
                    {sipnnershow && (
            <div style={{ display: "inline-block" }}>
              Loading data... <Spinner size="sm" color="primary" />
            </div>
          )}
                    <Form onSubmit={handleSearchSubmit} className="w-100">
                      <Row>
                        <Col>
                      <InputGroup className="input-group-focus mt-2">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-search" aria-hidden="true" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          value={search_value}
                          name="vSearch"
                          placeholder="Email Id / Contact Number"
                          onChange={(e) => setsearch_value(e.target.value)}
                          type="text"
                        />
                      </InputGroup>
                      </Col>
                      <Col>
                      <FormGroup>
                        <Button className="w-100" id="submit" type="submit">
                          Search Drivers
                        </Button>
                        <div>
                          {errormessage != null ? (
                            <p style={{ color: "red" }}>{errormessage}</p>
                          ) : null}
                        </div>
                      </FormGroup>
                      </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Card>
        {
          <Card className="p-2">
            <ReactTable
              calldata={() => {}}
              data={Searchdataset}
              countforcall={() => {}}
              isnext={false}
              columns={[
                {
                  Header: "Driver Name",
                  accessor: "name",
                },
                {
                  Header: "Pending Payment",
                  accessor: "pending_payment",
                },
                {
                  Header: "paid today",
                  accessor: "paid_today",
                },
                {
                  Header: "total revenue for current day ",
                  accessor: "total_revenue_for_current_day",
                },
                {
                  Header: "total due",
                  accessor: "total_due",
                },

                {
                  Header: "Collect Full Amount",
                  accessor: "true1",
                },
                {
                  Header: "Collect other Amount",
                  accessor: "true2",
                },
                {
                  Header: "confirm collect",
                  accessor: "actions",
                },
              ]}
              /*
              You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
            */
              className="-striped -highlight primary-pagination"
            />
          </Card>
        }
      </div>
    </>
  );
}

export default CashCollection;
