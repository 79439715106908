

const Adminloding=(state=true,action)=>{
    switch(action.type){
        case 'SETadminloding':
            return false
        default:
            return state;
    }
}

export default Adminloding;