import React, { useEffect, useState } from "react";

// reactstrap components
import { Spinner, Button, Row, Col, Input, Form, FormGroup } from "reactstrap";
import {
  addCollectionRides,
  addCollectionRidesSearch,
} from "../datachane/actions";
import { useSelector, useDispatch } from "react-redux";

// core components
import ReactTable from "components/ReactTable/ReactTable2";

function CollectionsRides() {
  const dd = useSelector((state) => state.AccountsCollectionRides);
  const dispatch = useDispatch();
  const [nextkey, setnextkey] = useState(0);
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false);
  //data date
  const [fromdate, setfromdate] = useState(new Date());
  const [todate, settodate] = useState(new Date());
  const [isDisabled, setisDisabled] = useState(false);
  const [setclear, setsetclear] = useState(true);
  //dataexchange
  const [arrayData, setarrayData] = useState(dd.arr);

  const DownloadCSV = async () => {
    setspinnershow(true);

    setisDisabled(true);
    if(todate!==null&&fromdate!==null){
    var todateseconds = new Date(todate).getTime() / 1000;
    var fromdateseconds = new Date(fromdate).getTime() / 1000;
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    await fetch(
      `https://us-central1-africar-9309f.cloudfunctions.net/accounts?type=collections&sub_type=rides&from=${fromdateseconds}&to=${todateseconds}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setisDisabled(false);
        
          fileJson(result.data,'Collection Rides')
          
        } else {
          alert(result.message);
        }
        setspinnershow(false);
      })
      .catch((error) => {
        setspinnershow(false);

        setmessagttouser("Unable to get data....ERROR");
        console.log("error", error);
      });
    }else{
      setspinnershow(false);

      alert('Please select the date !')
    }
  };
  const fileJson = async (JSONData, ReportTitle, ShowLabel) => {
    var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
 
    var CSV = "";
    var datasest = arrData.map((data) => {
     
      return {
        Id:data.id,
        Name:data.name,
        email:data.email,
        ARN:data.ARN,
        'Driver Pays To Africar':data.driver_pays_to_africar?'Yes':'No',
        'Driver Due':data.driver_due,
        'Due Paid':data.due_paid
      };
    });

    const items = datasest;
    const replacer = (key, value) => (value === null ? " " : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    CSV = [
      header.join(","), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ].join("\r\n");
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    //Generate a file name
    var fileName = "Report_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setisDisabled(false);
    setfromdate(0)
    settodate(0)  
  };

  //search date
  const handleSubmitSearch = async (event) => {
    event.preventDefault();
    setspinnershow(true);

    setisDisabled(true);
    var todateseconds = new Date(todate).getTime() / 1000;
    var fromdateseconds = new Date(fromdate).getTime() / 1000;
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    await fetch(
      `https://us-central1-africar-9309f.cloudfunctions.net/accounts?type=collections&sub_type=rides&from=${fromdateseconds}&to=${todateseconds}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setisDisabled(false);

          setsetclear(false);

          dispatch(
            addCollectionRidesSearch(result.data, result.start, result.count)
          );
        } else {
          alert(result.message);
        }
        setspinnershow(false);
      })
      .catch((error) => {
        setspinnershow(false);

        setmessagttouser("Unable to get data....ERROR");
        console.log("error", error);
      });
  };

   //runes after opens page
   useEffect(() => {
    if (dd.arr.length === 0) {
      calldataall_riders(0);
    } else {
      setarrayData(dd.arr);
    }
  }, [dd.arr]);

  async function calldataall_ridersRepeat(e) {
    var d = e;
    var da = "";
    if (dd.nextKey !== "") {
      da = dd.nextKey;
    } else {
      da = nextkey;
    }
    while (d !== 0) {
      d--;
      if (da !== null && nexttoggle) {
        da = await calldataall_riders(da);
      } else {
        break;
      }
    }
  }
  useEffect(() => {
    if (setclear) {
      setarrayData(dd.arr);
    } else {
      setarrayData(dd.arrsearch);
    }
  }, [dd.arrsearch]);
  async function calldataall_riders(nexttokenparam) {
    setspinnershow(true);
    if (nexttokenparam === undefined) {
      nexttokenparam = dd.nextKey || nextkey;
    }
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return await fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/accounts?type=collections&sub_type=rides&start=" +
        nexttokenparam,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true && result.data.length !== 0) {
          setnextkey(result.start);
          dispatch(addCollectionRides(result.data, result.start));
          setnexttoggle(true);
          setspinnershow(false);
          return result.start;
        } else {
          setspinnershow(false);
          setnexttoggle(false);
          setmessagttouser("Unable to get data....ERROR : " + result.message);
          console.log("error", result.message);
          alert("No Data To Load");
          return null;
        }
      })
      .catch((error) => {
        setnexttoggle(false);
        setmessagttouser("Unable to get data....ERROR");
        console.log("error", error);
      });
  }
  if (dd.arr.length === 0) {
    return (
      <>
        <div className="content">
          <div className="w-100">
            <h5 style={{ display: "inline-block" }}>Collections Ride Wise</h5>
            <p>{messagttouser}</p>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="content ">
        <div className="w-100" style={{ display: "inline-block" }}>
          <h5 style={{ display: "inline-block" }}>Collections Ride Wise</h5>
          {"  "}
          {sipnnershow && (
            <div style={{ display: "inline-block" }}>
              Loading data... <Spinner size="sm" color="primary" />
            </div>
          )}
          <hr className="m-0" />
          <div>
            <Form onSubmit={handleSubmitSearch} className="w-100">
              <Row>
                <Col md={3} className="mt-2">
                  <FormGroup>
                    <p className="-Formlable" id="fromdate">
                      From Date{" "}
                    </p>
                    <Input
                      required
                      value={fromdate}
                      onChange={(e) => {
                        setfromdate(e.target.value);
                      }}
                      type="date"
                    />{" "}
                  </FormGroup>
                </Col>
                <Col md={3} className="mt-2">
                  <FormGroup>
                    <p className="-Formlable" id="todate">
                      To Date{" "}
                    </p>
                    <Input
                      required
                      value={todate}
                      onChange={(e) => {
                        settodate(e.target.value);
                      }}
                      type="date"
                    />{" "}
                  </FormGroup>
                </Col>
                <Col md={2} className="mt-auto">
                  {setclear ? (
                    <Button className="W-100" id="search" type="submit">
                      Submit
                    </Button>
                  ) : null}

                  {!setclear ? (
                    <Button
                      className="W-100"
                      type="button"
                      onClick={() => {
                        dispatch(addCollectionRides([]));
                        setsetclear(true);
                        setfromdate('')
                        settodate('')                      }}
                    >
                      clear
                    </Button>
                  ) : null}
                </Col>
                <Col md={3} className="mt-auto">
                  {setclear ? (
                    <Button className="W-100" onClick={()=>{DownloadCSV()}}>
                      Download csv file
                    </Button>
                  ) : null}

                 
               
                </Col>
              </Row>
            </Form>
          </div>
          <hr className="m-0" />
        </div>
        <br />
        <ReactTable
          calldata={calldataall_riders}
          data={dd.arr}
          countforcall={calldataall_ridersRepeat}
          isnext={nexttoggle}
          counts={dd.TotalCount}
          columns={[
            {
              Header: "ride number",
              accessor: "ARN",
            },
            {
              Header: "driver name",
              accessor: "name",
            },
            {
              Header: "driver email id",
              accessor: "email",
            },

            {
              Header: "Ride Amount",
              accessor: "due_paid",
            },
            {
              Header: "Total Amount Paid",
              accessor: "driver_due",
            },
          ]}
          /*
            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
          */
          className="-striped -highlight primary-pagination"
        />
      </div>
    </>
  );
}

export default CollectionsRides;
