
const initailState = {
    arr: [],
  arrsearch: [],
  nextKey: "",
}
const AccountsRevenuDriverdetails=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_REVENUE_DRIVER_DETAIL_DATA':
            return{ 
                arr: state.arr.concat(action.payload),
                nextKey:action.nextKey
            }
            case "ADD_REVENUE_DRIVER_DETAI_SEARCH_DATA":
                return { ...state, arrsearch: action.payload };
   

            case 'CLEAN':
                return initailState;
        default:
            return state;
    }
}

export default AccountsRevenuDriverdetails