import React, { useEffect, useState } from "react";

// reactstrap components
import { Spinner, Button, Row, Col, Input, Form, FormGroup } from "reactstrap";
import { addRevenueRides, addRevenueRidesSearch } from "../datachane/actions";
import { useSelector, useDispatch } from "react-redux";
// core components
import ReactTable from "../../components/ReactTable/ReactTable";

function RevenueRides(props) {
  const dd = useSelector((state) => state.AccountsRevenueRides);

  const dispatch = useDispatch();
  const [nextkey, setnextkey] = useState(dd.nextKey || 0);
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false);
  //data date
  const [fromdate, setfromdate] = useState();
  const [todate, settodate] = useState();
  const [isDisabled, setisDisabled] = useState(false);
  const [setclear, setsetclear] = useState(true);
  //dataexchange
  const [arrayData, setarrayData] = useState(dd.arr);

  const handleSubmitSearch = async (event) => {
    event.preventDefault();
    setspinnershow(true);

    setisDisabled(true);
    var todateseconds = new Date(todate).getTime() / 1000;
    var fromdateseconds = new Date(fromdate).getTime() / 1000;
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    await fetch(
      `https://us-central1-africar-9309f.cloudfunctions.net/accounts?type=revenue&sub_type=rides&from=${fromdateseconds}&to=${todateseconds}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setisDisabled(false);
          result.data.map((pro) => {
            pro.netfare = pro.ride_cost - pro.rate_card.tax;
            if (pro.booked_on) {
              pro.booked_on = new Date(pro.booked_on).toLocaleString("en-US", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              });
            }
          });

          setsetclear(false);

          dispatch(
            addRevenueRidesSearch(result.data, result.start, result.count)
          );
        } else {
          alert(result.message);
        }
        setspinnershow(false);
      })
      .catch((error) => {
        setspinnershow(false);

        setmessagttouser("Unable to get data....ERROR");
        console.log("error", error);
      });
  };
  const DownloadCSV = async () => {
    setspinnershow(true);

    setisDisabled(true);
    if(todate!==null&&fromdate!==null){
    var todateseconds = new Date(todate).getTime() / 1000;
    var fromdateseconds = new Date(fromdate).getTime() / 1000;
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    await fetch(
      `https://us-central1-africar-9309f.cloudfunctions.net/accounts?type=revenue&sub_type=rides&from=${fromdateseconds}&to=${todateseconds}`,      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setisDisabled(false);
          result.data.map((pro) => {
            pro.netfare = pro.ride_cost - pro.rate_card.tax;
            if (pro.booked_on) {
              pro.booked_on = new Date(pro.booked_on).toLocaleString("en-US", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              });
            }
          });

          setsetclear(false);
          fileJson(result.data,'Revenue Rides')
          
        } else {
          alert(result.message);
        }
        setspinnershow(false);
      })
      .catch((error) => {
        setspinnershow(false);

        setmessagttouser("Unable to get data....ERROR");
        console.log("error", error);
      });
    }else{
      setspinnershow(false);

      alert('Please select the date !')
    }
  };
  const fileJson = async (JSONData, ReportTitle, ShowLabel) => {
    var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
 
    var CSV = "";
    var datasest = [];
    datasest = arrData.map((data) => {
      data.rider_name = data.rider_lname + " " + data.rider_fname;
      const header = Object.keys(data);
      var rt = data.rate_card;
      var coupon = data.coupon_used;
      var cstate = data.coupon;
      delete data.rate_card;
      var dd = data.type;
      data.ride_type = dd;
    
    
      var con,
        aon,
        bon,
        von = "";
        if (data.completed_on) {
          con = new Date(data.completed_on * 1000).toLocaleString("en-US", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
        }
      if (data.accepted_on) {
        aon = new Date(data.accepted_on).toLocaleString("en-US", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      }
      if (data.booked_on) {
        bon = new Date(data.booked_on ).toLocaleString("en-US", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      }
      if (data.verified_on) {
        von = new Date(data.verified_on * 1000).toLocaleString("en-US", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      }
      if (cstate) {
        data.coupon_name = coupon.id;
        data.coupon_discount = coupon.discount;
      } else {
        data.coupon_name = "";
        data.coupon_discount = "";
      }
      return {
        ARN: data.ARN,
        "Booking Time": bon,
        "Accepted Time": aon,
        "Rider name": data.rider_name,
        "Driver name": data.driver_name,
        "Registration number": data.registration_number,
        "Ride Type": data.type,
        "Required seats": data.required_seats,
       
        "Distance Travelled": data.distance_str,
        "Base Fare": rt.base_fare,
        "Distance Fare": rt.distance_fare,
        "Time Fare": rt.time_fare,
        VAT: rt.tax,
        "Ride Cost": data.ride_cost,
        "Africar Net Gain": data.africar_net_gain,
        "Driver Commission": data.driver_commission,
        Coupon: data.coupon,
        "Coupon Name": data.coupon_name,
        "Coupon Discount": data.coupon_discount,
        "Rider Ratings": data.ratings,
      };
    });

    const items = datasest;
    const replacer = (key, value) => (value === null ? " " : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    CSV = [
      header.join(","), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ].join("\r\n");
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    //Generate a file name
    var fileName = "Report_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setisDisabled(false);
    setfromdate(0)
    settodate(0) 
  };
  //runes after opens page
  useEffect(() => {
    if (dd.arr.length === 0) {
      calldataall_riders(0);
    } else {
      setarrayData(dd.arr);
    }
  }, [dd.arr]);
  async function calldataall_ridersRepeat(e) {
    var d = e;
    var da = "";
    if (dd.nextKey !== 0) {
      da = dd.nextKey;
    } else {
      da = nextkey;
    }
    while (d !== 0) {
      d--;

      if (da !== null && nexttoggle) {
        da = await calldataall_riders(da);
      } else {
        break;
      }
    }
  }
  useEffect(() => {
    if (setclear) {
      setarrayData(dd.arr);
    } else {
      setarrayData(dd.arrsearch);
    }
  }, [dd.arrsearch]);
  //function call api
  async function calldataall_riders(nexttokenparam) {
    setspinnershow(true);
    setsetclear(true);
    if (nexttokenparam === undefined) {
      nexttokenparam = dd.nextKey || nextkey;
    }
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return await fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/accounts?type=revenue&sub_type=rides&start=" +
        nexttokenparam,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setnextkey(result.start);

          result.data.map((pro) => {
            if(pro.original_cost){
            pro.netfare = pro.original_cost - (pro.rate_card.tax+pro.rate_card.lasg_fee)}else{

            }            pro.netfare = pro.ride_cost - (pro.rate_card.tax+pro.rate_card.lasg_fee);

            if(pro.coupon){
              pro.discount=pro.coupon_used.discount
              pro.netfare=pro.coupon_used.discount
            }else{
              pro.discount=0

            }
            pro.rate_card.tax=pro.rate_card.tax+pro.rate_card.lasg_fee;

            if (pro.booked_on) {
              pro.booked_on = new Date(pro.booked_on).toLocaleString("en-US", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              });
            }
          });
          dispatch(addRevenueRides(result.data, result.start, result.count));
          setnexttoggle(true);
          setspinnershow(false);
          return result.start;
        } else {
          setspinnershow(false);
          setnexttoggle(false);
          setmessagttouser("Unable to get data....ERROR : " + result.message);
          console.log("error", result.message);
          alert("No Data To Load");
          return null;
        }
      })
      .catch((error) => {
        setnexttoggle(false);
        setmessagttouser("Unable to get data....ERROR");
        console.log("error", error);
      });
    //
  }
  if (dd.arr.length === 0) {
    return (
      <>
        <div className="content">
          <div className="w-100">
            <h5 style={{ display: "inline-block" }}> Revenue Ride Wise </h5>
            <p>{messagttouser}</p>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="content ">
        <div className="w-100" style={{ display: "inline-block" }}>
          <h5 style={{ display: "inline-block" }}> Revenue Ride Wise </h5>
          {sipnnershow && (
            <div style={{ display: "inline-block", marginRight: "10px" }}>
              Loading data... <Spinner size="sm" color="primary" />
            </div>
          )}
          <hr className="m-0" />
          <div>
            <Form onSubmit={handleSubmitSearch} className="w-100">
              <Row>
                <Col md={3} className="mt-2">
                  <FormGroup>
                    <p className="-Formlable" id="fromdate">
                      From Date{" "}
                    </p>
                    <Input
                      required
                      value={fromdate}
                      onChange={(e) => {
                        setfromdate(e.target.value);
                      }}
                      type="date"
                    />{" "}
                  </FormGroup>
                </Col>
                <Col md={3} className="mt-2">
                  <FormGroup>
                    <p className="-Formlable" id="todate">
                      To Date{" "}
                    </p>
                    <Input
                      required
                      value={todate}
                      onChange={(e) => {
                        settodate(e.target.value);
                      }}
                      type="date"
                    />{" "}
                  </FormGroup>
                </Col>
                <Col md={2} className="mt-auto">
                  {setclear ? (
                    <Button className="W-100" id="search" type="submit">
                      Submit
                    </Button>
                  ) : null}

                  {!setclear ? (
                    <Button
                      className="W-100"
                      type="button"
                      onClick={() => {
                        dispatch(addRevenueRidesSearch([]));
                        setsetclear(true);
                        setfromdate('')
                        settodate('')                      }}
                    >
                      clear
                    </Button>
                  ) : null}
                </Col>
                <Col md={3} className="mt-auto">
                  {setclear ? (
                    <Button className="W-100" onClick={()=>{DownloadCSV()}}>
                      Download csv file
                    </Button>
                  ) : null}
               
                </Col>
              </Row>
            </Form>
          </div>
          <hr className="m-0" />
        </div>
        <br />
        <ReactTable
          calldata={calldataall_riders}
          countforcall={calldataall_ridersRepeat}
          data={arrayData}
          isnext={nexttoggle}
          counts={dd.TotalCount}
          hidecolumnsdata={["coupon", "verified_on"]}
          columns={[
            {
              Header: "Ride Number   ",
              accessor: "ARN",
            },
            {
              Header: "vehicle number",
              accessor: "registration_number",
            },
            {
              Header: "driver name",
              accessor: "driver_name",
            },
            {
              Header: "date",
              accessor: "booked_on",
            },
            {
              Header: "time of travel",
              accessor: "duration_str",
            },
            {
              Header: "distance ",
              accessor: "distance_str",
            },
            {
              Header: "GROSS FARE ",
              accessor: "netfare",
            },
            {
              Header: "DISCOUNTS ",
              accessor: "discount",
            },
            {
              Header: "VAT",
              accessor: "rate_card.tax",
            },
            {
              Header: "final amount",
              accessor: "ride_cost",
            },
          ]}
          /*
            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
          */
          className="-striped -highlight primary-pagination"
        />
      </div>
    </>
  );
}

export default RevenueRides;
