/* global google
 */
import React, { useState, useEffect, useRef } from "react";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  withScriptjs,
  InfoWindow,
  Polyline,
} from "react-google-maps";
import { useSelector, useDispatch } from "react-redux";
import mapStyles from "./Mapstyles";
import { dbfirestore } from "../components/firebase";
import MapSvgcodeValue from "./MapSvgcodeValue";
var velocity = 100;
var initialDate = new Date();
const getDistance = () => {
  // seconds between when the component loaded and now
  const differentInTime = (new Date() - initialDate) / 1000; // pass to seconds
  return differentInTime * velocity; // d = v*t -- thanks Newton!
};

const Markers = ({ call, places }) => {
  const places2data = useSelector((state) => state.Driverlocation.prearr);
  const findAngle = (place) => {
    var actualAngle = 0;
    var places2 = {}
    places2data.map((dd) => {
      if (dd.id === place.id) {
        places2 = dd
      }
    })
    if (places2data.length !== 0) {
      const distance = getDistance();
      if (!distance) {
        return;
      }

      const point1LatLng = new window.google.maps.LatLng(
        place.l.lat,
        place.l.lng
      );
      const point2LatLng = new window.google.maps.LatLng(
        places2.l.lat,
        places2.l.lng
      );
      const angle = window.google.maps.geometry.spherical.computeHeading(
        point1LatLng,
        point2LatLng
      );
      actualAngle = Math.abs(Math.round(angle - 90));
      // console.log(actualAngle);
    }
    return actualAngle;
  };
  return places.map((place, key) => {
    if (place !== null && place.id !== undefined) {
      return <Marker
        key={place.id}
        id={place.id}
        position={{ lat: place.l.lat, lng: place.l.lng }}
        icon={{
          path: MapSvgcodeValue,
          strokeColor: place.colorCode,
          fillColor: place.colorCode,
          anchor: new google.maps.Point(17, 34),
          origin: new google.maps.Point(0, 0),
          fillOpacity: 1.0,
          strokeWeight: 1.5,
          rotation: findAngle(place),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={() => {
          call(place);
        }}
      />
    }
  });
};
function Map({ latlng }) {
  const dataplaces = useSelector((state) => state.Driverlocation.arr);
  const [selectedmarker, setselectedmarker] = useState(null);
  const [avalablemarker, setavalablemarker] = useState(false);
  const [rnum, setrnum] = useState();
  const [nameset, setnameset] = useState();
  const [modelset, setmodelset] = useState();
  const [gen, setgen] = useState();
  const [stat, setstat] = useState();
  const [ty, setty] = useState(null);
  const [precod, setprecod] = useState();
  const [currentcod, setcurrentcod] = useState();
  const [zoomVal, setzoomVal] = useState(11);
  const isMounted = useRef(false);

  const [markerData, setmarkerData] = useState(dataplaces)
  const firebasecall = (e) => {
    if (e.id !== undefined) {
      var ids = e.id;
      dbfirestore
        .collection("/users/appUsers/drivers/")
        .doc(ids)
        .get()
        .then((snapshot) => {
          var datasnsp = snapshot.data();
          setnameset(datasnsp.fname + " " + datasnsp.lname);
          setgen(datasnsp.gender);
          setrnum(datasnsp.registration_number);
          setmodelset(datasnsp.model);
          setty(e.type);
          setstat(e.status);
        });
      setselectedmarker(e);
    }
  };
  useEffect(() => {
    setcurrentcod({ lat: latlng.cords.lat, lng: latlng.cords.long });
  }, [latlng]);
  useEffect(() => {
    if (selectedmarker !== null) {
      setprecod(currentcod);
      setzoomVal(17);
      setcurrentcod({ lat: selectedmarker.l.lat, lng: selectedmarker.l.lng });
    }
  }, [selectedmarker]);
  useEffect(() => {
    setmarkerData(dataplaces)

    if (isMounted.current) {
      /////////
      var ss = dataplaces.map((doc) => {
        if (doc.status === latlng.typeSelect) {
          return doc
        } else if (latlng.typeSelect === 'All') {
          return doc;
        } else if (latlng.typeSelect === 'OnGoing') {
          return doc.status !== 'Waiting' ? doc : null;
        } else {
          return null
        }
      })
      setmarkerData(ss)
      /////////
    } else {
      isMounted.current = true;
    }
  }, [latlng.typeSelect])
  return (
    <div>
      <GoogleMap
        center={currentcod}
        defaultCenter={{ lat: 6.5244, lng: 3.3792 }}
        zoom={zoomVal}
        defaultOptions={{ styles: mapStyles, scrollwheel: false }}
      >
        <Markers call={firebasecall} places={markerData} />
        {selectedmarker && (
          <InfoWindow
            position={{ lat: selectedmarker.l.lat, lng: selectedmarker.l.lng }}
            onCloseClick={() => {
              // setcurrentcod(precod);
              setzoomVal(11);
              setselectedmarker(null);
            }}
          >
            <>
              {" "}
              <div>Name :{nameset}</div>
              <div>Status : {stat}</div>
              {ty !== null ? <div> Type :{ty}</div> : null}

              <div> Registration Number:{rnum}</div>
              <div> Model:{modelset}</div>
              <div> Gender:{gen}</div>
            </>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
}
const MapComponent = withScriptjs(
  withGoogleMap((cords) => <Map latlng={cords} />)
);

export default function Mapapp({ cord, typeSelect }) {
  return (
    <div style={{ width: "100%", height: "50%" }}>
      <MapComponent
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyD6IFjP0WDoxI6sYhMp4qQZdmjGtGS5_Ws&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px`, width: "100%" }} />}
        mapElement={<div style={{ height: `100%` }} />}
        cords={cord}
        typeSelect={typeSelect}
      />
    </div>
  );
}
