import React, { useState, useEffect } from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Collapse,
  Col,
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";
import Mapapp from "../Map";

function WarRoom() {
  const warroom = useSelector((state) => state.WarRoom.war_room);
  const citiesstore = useSelector((state) => state.Warroomcity.war_room);
  var cities = []
  if (citiesstore) {
    cities = Object.keys(citiesstore);

  }
  const [selectCity, setselectCity] = useState("lagos");
  const [SelectType, setSelectType] = useState('All');

  const isloading = useSelector((state) => state.Isload);
  const [sharedridetilldate, setsharedridetilldate] = useState(false);
  const [singleridetilldate, setsingleridetilldate] = useState(false);

  const [sharedride, setsharedride] = useState(false);
  const [singleride, setsingleride] = useState(false);
  const [sharedrideCount, setsharedrideCount] = useState(0);
  const [singlerideCount, setsinglerideCount] = useState(0);
  const [ongoing, setongoingCount] = useState(0);

  const avalabledrivers = useSelector(
    (state) => state.Driverlocation.driver_avalable
  );
  function tilldate(data) {
    if (data !== 0 && data !== undefined) {
      if (data[1] !== null || data[1] !== 0) {
        let previous_date = new Date(data[1] * 1000);
        let present_date = new Date();
        if (
          previous_date.getFullYear() === present_date.getFullYear() &&
          previous_date.getMonth() === present_date.getMonth() &&
          previous_date.getDate() === present_date.getDate()
        ) {
          return data[2] - data[0];
        } else return 0;
      } else {
        return 0;
      }
    } else { return 0 }
  }
  useEffect(() => {
    if (!isloading && warroom.war_room !== null) {
      setsharedrideCount(warroom[selectCity].share.rides_accepted[0]);
      setsinglerideCount(warroom[selectCity].single.rides_accepted[0]);
      setongoingCount(
        tilldate(warroom[selectCity].single.rides_verified) +
        tilldate(warroom[selectCity].share.rides_verified)
      );
    }
    return () => { };
  }, []);

  if (isloading && warroom.war_room === null) {
    return (
      <>
        <div className="content">
          <div className="w-100 ">
            <h5 style={{ display: "inline-block" }}>loading data...</h5>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md={1}>
            <h4 className="mt-0"> City</h4>
          </Col>
          <Col md={3}>
            {cities.length !== 0 ? (
              <Input
                required
                value={selectCity}
                onChange={(e) => {
                  setselectCity(e.target.value);
                }}
                placeholder="Enter vehicle city"
                type="select"
              >
                {cities.map((data, i) => {
                  return (
                    <option value={data} key={i}>
                      {data}
                    </option>
                  );
                })}
              </Input>
            ) : null}
          </Col>
          <Col md={6}></Col>
          <Col lg="12" md="12" sm="12">
            <Card className="card-stats  ">
              <Mapapp cord={citiesstore[selectCity]} typeSelect={SelectType} />
            </Card>
          </Col>
        </Row>
        <Row >
          <Col  lg="6" md="12" sm="12">
            <Card className="m-0 p-2">
              <Row>
                <Col lg="4" md="4" sm="4">
                  {" "}
                  <Card className="card-stats h-75 ">
                    <CardBody>
                      <Row>
                        <Col md="12" xs="12">
                          <CardTitle> Available Drivers in City</CardTitle>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <div
                        style={{
                          marginTop: "20px",
                          textAlign: "center",
                          color: "black",
                          fontSize: "20px",
                        }}
                      >
                        {avalabledrivers}{" "}
                      </div>{" "}
                    </CardFooter>
                  </Card>
                </Col>
                <Col lg="4" md="4" sm="4">
                  {" "}
                  <Card className="card-stats h-75">
                    <CardBody>
                      <Row>
                        <Col md="12" xs="12">
                          <CardTitle> Single Rides</CardTitle>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "20px",
                        }}
                      >
                        {tilldate(warroom[selectCity].single !== undefined ? warroom[selectCity].single.rides_completed : 0)}
                      </div>{" "}
                    </CardFooter>
                  </Card>
                </Col>
                <Col lg="4" md="4" sm="4">
                  {" "}
                  <Card className="card-stats h-75 ">
                    <CardBody>
                      <Row>
                        <Col md="12" xs="12">
                          <CardTitle> Shared Rides</CardTitle>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "20px",
                        }}
                      >
                        {tilldate(warroom[selectCity].share ? warroom[selectCity].share.rides_completed : 0)}
                      </div>{" "}
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg="6" md="8" sm="12">
                  {" "}
                  <Card className="card-stats  ">
                    <CardBody>
                      <Row>
                        <Col md="12" xs="12">
                          <CardTitle>OnGoing Trips</CardTitle>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "20px",
                        }}
                      >
                        {ongoing}
                      </div>{" "}
                    </CardFooter>
                  </Card>
                </Col>
                <Col lg="6" md="8" sm="12">
                  <Card className="card-stats  ">
                    <CardBody>
                      <Row>
                        <Col md="12" xs="12">
                          <CardTitle> Total Booking</CardTitle>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "20px",
                        }}
                      >
                        {tilldate(warroom[selectCity].single ? warroom[selectCity].single.rides_requested : 0) +
                          tilldate(warroom[selectCity].share ? warroom[selectCity].share.rides_requested : 0)}
                      </div>{" "}
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xl={6} lg="6" md="12" sm="12" className="mt-2">
            <Row >
              <Col lg="12" md="12" sm="12">
                <Card className="card-stats  p-2">
                  <CardBody>
                    <Row>
                      <Col md="12" xs="12">
                        <CardTitle> Targeted Rides For The day : </CardTitle>
                      </Col>
                      <Col md="12" xs="12">
                        <CardTitle>
                          Number of Rides Yet To Achieve :
                        </CardTitle>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={12}/>
              <Col md="4" sm="4" >
                <button className='button-All' onClick={() => { setSelectType('All') }} > All Drivers
                </button>              </Col>
              <Col md="4" sm="4" >
                <button className='button-Available' onClick={() => { setSelectType('Waiting') }} > Available Drivers
                </button>              </Col>
              <Col md="4" sm="4" >
                <button className='button-Ongoing' onClick={() => { setSelectType('OnGoing') }} >OnGoing Trip</button>

              </Col>
            </Row>

          </Col>

          <Col md={12} className="mt-2 h-100">
            <Card className="card-stats  w-100 p-2">
              <Row>
                {warroom[selectCity].single !== undefined ? (
                  <Col lg="6" md="8" sm="12">
                    <Card
                      className="card-stats"
                      style={{
                        backgroundColor: "#EFEFEF",
                        textAlign: "center",
                      }}
                    >
                      <CardHeader role="tab">
                        <a
                          style={{ textAlign: "center" }}
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => setsingleride(!singleride)}
                        >
                          <h6>
                            Single Rides{" "}
                            <i className="nc-icon nc-minimal-down" />
                          </h6>
                        </a>
                      </CardHeader>
                      <Collapse role="tabpanel" isOpen={singleride}>
                        <CardBody style={{ textAlign: "left" }}>
                          <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">rides accepted : </h6>
                            </Col>
                            <Col sm={6}>
                              <span>
                                {tilldate(
                                  warroom[selectCity].single.rides_accepted
                                )}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">
                                rides cancelled by drivers :{" "}
                              </h6>
                            </Col>
                            <Col sm={6}>
                              <span>
                                {tilldate(
                                  warroom[selectCity].single
                                    .rides_cancelled_by_drivers
                                )}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">
                                rides cancelled by riders :{" "}
                              </h6>
                            </Col>
                            <Col sm={6}>
                              <span>
                                {tilldate(
                                  warroom[selectCity].single
                                    .rides_cancelled_by_riders
                                )}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">rides completed : </h6>
                            </Col>
                            <Col sm={6}>
                              <span>
                                {tilldate(
                                  warroom[selectCity].single.rides_completed
                                )}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">rides pending : </h6>
                            </Col>
                            <Col sm={6}>
                              <span>
                                {tilldate(
                                  warroom[selectCity].single.rides_pending
                                )}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">rides requested : </h6>
                            </Col>
                            <Col sm={6}>
                              <span>
                                {tilldate(
                                  warroom[selectCity].single.rides_requested
                                )}
                              </span>
                            </Col>
                          </Row>
                        </CardBody>
                      </Collapse>
                      <CardFooter style={{ textAlign: "center" }}>
                        {new Date().toISOString().split("T")[0]}{" "}
                      </CardFooter>
                    </Card>
                  </Col>
                ) : null}
                {warroom[selectCity].share !== undefined ? (
                  <Col lg="6" md="8" sm="12">
                    <Card
                      className="card-stats"
                      style={{
                        backgroundColor: "#EFEFEF",
                        textAlign: "center",
                      }}
                    >
                      <CardHeader role="tab">
                        <a
                          style={{ textAlign: "center" }}
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => setsharedride(!sharedride)}
                        >
                          <h6>
                            Shared Rides{" "}
                            <i className="nc-icon nc-minimal-down" />
                          </h6>
                        </a>
                      </CardHeader>
                      <Collapse role="tabpanel" isOpen={sharedride}>
                        <CardBody style={{ textAlign: "left" }}>
                          <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">rides accepted : </h6>
                            </Col>
                            <Col sm={6}>
                              <span className="ridedataspan">
                                {tilldate(
                                  warroom[selectCity].share.rides_accepted
                                )}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">
                                rides cancelled by drivers :{" "}
                              </h6>
                            </Col>
                            <Col sm={6}>
                              <span className="ridedataspan">
                                {tilldate(
                                  warroom[selectCity].share
                                    .rides_cancelled_by_drivers
                                )}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">
                                rides cancelled by riders :{" "}
                              </h6>
                            </Col>
                            <Col sm={6}>
                              <span className="ridedataspan">
                                {tilldate(
                                  warroom[selectCity].share
                                    .rides_cancelled_by_riders
                                )}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">rides completed : </h6>
                            </Col>
                            <Col sm={6}>
                              <span>
                                {tilldate(
                                  warroom[selectCity].share.rides_completed
                                )}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">rides pending : </h6>
                            </Col>
                            <Col sm={6}>
                              <span>
                                {tilldate(
                                  warroom[selectCity].share.rides_pending
                                )}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">rides requested : </h6>
                            </Col>
                            <Col sm={6}>
                              <span>
                                {tilldate(
                                  warroom[selectCity].share.rides_requested
                                )}
                              </span>
                            </Col>
                          </Row>
                        </CardBody>
                      </Collapse>
                      <CardFooter style={{ textAlign: "center" }}>
                        {new Date().toISOString().split("T")[0]}{" "}
                      </CardFooter>
                    </Card>
                  </Col>
                ) : null}
                <Col lg="12" />
                {warroom[selectCity].single !== undefined ? (
                  <Col lg="6" md="8" sm="12">
                    <Card
                      className="card-stats"
                      style={{
                        backgroundColor: "#EFEFEF",
                        textAlign: "center",
                      }}
                    >
                      <CardHeader role="tab">
                        <a
                          style={{ textAlign: "center" }}
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() =>
                            setsingleridetilldate(!singleridetilldate)
                          }
                        >
                          <h6>
                            Single Rides Till Date{" "}
                            <i className="nc-icon nc-minimal-down" />
                          </h6>
                        </a>
                      </CardHeader>
                      <Collapse role="tabpanel" isOpen={singleridetilldate}>
                        <CardBody style={{ textAlign: "left" }}>
                          {warroom[selectCity].single.rides_accepted ? <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">rides accepted : </h6>
                            </Col>
                            <Col sm={6}>
                              <span>
                                {warroom[selectCity].single.rides_accepted[2]}
                              </span>
                            </Col>
                          </Row> : null}
                          {
                            warroom[selectCity].single
                              .rides_cancelled_by_drivers ? <Row>
                              <Col sm={6}>
                                <h6 className="ml-3">
                                  rides cancelled by drivers :{" "}
                                </h6>
                              </Col>
                              <Col sm={6}>
                                <span>
                                  {
                                    warroom[selectCity].single
                                      .rides_cancelled_by_drivers[2]
                                  }
                                </span>
                              </Col>
                            </Row> : null}
                          {
                            warroom[selectCity].single
                              .rides_cancelled_by_riders ? <Row>
                              <Col sm={6}>
                                <h6 className="ml-3">
                                  rides cancelled by riders :{" "}
                                </h6>
                              </Col>
                              <Col sm={6}>
                                <span>
                                  {
                                    warroom[selectCity].single
                                      .rides_cancelled_by_riders[2]
                                  }
                                </span>
                              </Col>
                            </Row> : null}
                          {warroom[selectCity].single.rides_completed ? <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">rides completed : </h6>
                            </Col>
                            <Col sm={6}>
                              <span>
                                {warroom[selectCity].single.rides_completed[2]}
                              </span>
                            </Col>
                          </Row> : null}
                          {warroom[selectCity].single.rides_pending ? <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">rides pending : </h6>
                            </Col>
                            <Col sm={6}>
                              <span>
                                {warroom[selectCity].single.rides_pending[2]}
                              </span>
                            </Col>
                          </Row> : null}
                          {warroom[selectCity].single.rides_requested ? <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">rides requested : </h6>
                            </Col>
                            <Col sm={6}>
                              <span>
                                {warroom[selectCity].single.rides_requested[2]}
                              </span>
                            </Col>
                          </Row> : null}
                        </CardBody>
                      </Collapse>
                      <CardFooter style={{ textAlign: "center" }}></CardFooter>
                    </Card>
                  </Col>
                ) : null}
                {warroom[selectCity].share !== undefined ? (
                  <Col lg="6" md="8" sm="12">
                    <Card
                      className="card-stats"
                      style={{
                        backgroundColor: "#EFEFEF",
                        textAlign: "center",
                      }}
                    >
                      <CardHeader role="tab">
                        <a
                          style={{ textAlign: "center" }}
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() =>
                            setsharedridetilldate(!sharedridetilldate)
                          }
                        >
                          <h6>
                            Share Rides Till Date{" "}
                            <i className="nc-icon nc-minimal-down" />
                          </h6>
                        </a>
                      </CardHeader>
                      <Collapse role="tabpanel" isOpen={sharedridetilldate}>
                        <CardBody style={{ textAlign: "left" }}>
                          {warroom[selectCity].share.rides_accepted ? <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">rides accepted : </h6>
                            </Col>
                            <Col sm={6}>
                              <span className="ridedataspan">
                                {warroom[selectCity].share.rides_accepted[2]}
                              </span>
                            </Col>
                          </Row> : null}
                          {
                            warroom[selectCity].share
                              .rides_cancelled_by_drivers ? <Row>
                              <Col sm={6}>
                                <h6 className="ml-3">
                                  rides cancelled by drivers :{" "}
                                </h6>
                              </Col>
                              <Col sm={6}>
                                <span className="ridedataspan">
                                  {
                                    warroom[selectCity].share
                                      .rides_cancelled_by_drivers[2]
                                  }
                                </span>
                              </Col>
                            </Row> : null}
                          {warroom[selectCity].share
                            .rides_cancelled_by_riders ? <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">
                                rides cancelled by riders :{" "}
                              </h6>
                            </Col>
                            <Col sm={6}>
                              <span className="ridedataspan">
                                {
                                  warroom[selectCity].share
                                    .rides_cancelled_by_riders[2]
                                }
                              </span>
                            </Col>
                          </Row> : null}
                          {warroom[selectCity].share.rides_completed ? <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">rides completed : </h6>
                            </Col>
                            <Col sm={6}>
                              <span>
                                {warroom[selectCity].share.rides_completed[2]}
                              </span>
                            </Col>
                          </Row> : null}
                          {warroom[selectCity].share.rides_pending ? <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">rides pending : </h6>
                            </Col>
                            <Col sm={6}>
                              <span>
                                {warroom[selectCity].share.rides_pending[2]}
                              </span>
                            </Col>
                          </Row> : null}
                          {warroom[selectCity].share.rides_requested ? <Row>
                            <Col sm={6}>
                              <h6 className="ml-3">rides requested : </h6>
                            </Col>
                            <Col sm={6}>
                              <span>
                                {warroom[selectCity].share.rides_requested[2]}
                              </span>
                            </Col>
                          </Row> : null}
                        </CardBody>
                      </Collapse>
                      <CardFooter style={{ textAlign: "center" }}></CardFooter>
                    </Card>
                  </Col>
                ) : null}
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default WarRoom;
