
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Spinner,
  InputGroupText,
} from "reactstrap";





function BankView() {
  const [messagttouser, setmessagttouser] = useState(null)
  const [sipnnershow, setspinnershow] = useState(false)
  const [wallet_amount, setwallet_amount] = useState(0)
  const [addAmount, setaddAmount] = useState()
  async function CallFunction() {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    await fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/bank",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          setwallet_amount(result.data.amount)
        } else {
          setwallet_amount(0)
          setmessagttouser("Error unable to get data");
        }
      })
      .catch((error) => {
        setmessagttouser("Unable to get data....ERROR");
        console.log("error", error);
      });

  }
  useEffect(() => {
    CallFunction()
  }, []);
  async function handleSubmit(event) {
    event.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "amount": Number(addAmount)
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch("https://us-central1-africar-9309f.cloudfunctions.net/bank", requestOptions)
      .then(response => response.json())
      .then((result) => {
            if (result.status) {
              alert('Amount Added to Wallet')
              setaddAmount('')
              CallFunction();
            } else {
              setmessagttouser(result.message);
              setTimeout(() => {
                setmessagttouser(null);
              }, 3000);
            }
          })      .catch(error => console.log('error', error));
  }


  return (
    <>
      <div className="content ">
        <Card className="p-2">
          <div className="w-100" style={{ display: 'inline-block' }}>
            <h3 style={{ display: 'inline-block', marker: '10px', marginBottom: '0' }}>Afri Charge </h3>
            {'  '}{sipnnershow && <div style={{ display: 'inline-block' }}>
              Loading data... <Spinner size="sm" color="primary" />
            </div>}

          </div>
          <h4 className='m-4'>Wallet Balance :<span> {wallet_amount}</span></h4>
        </Card>
        <Card className='p-4'>
          <h4 style={{ margin: '0', marginBottom: '20px' }}>Add Money to Wallet </h4>
          <Form onSubmit={handleSubmit} className="w-100">
            <InputGroup className="input-group-focus px-5">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  &#8358;
                </InputGroupText>
              </InputGroupAddon>
              <Input
                required
                value={addAmount}
                name="vSearch"
                placeholder="Enter amount"
                onChange={(e) => setaddAmount(e.target.value)}
                type="text"
              />
            </InputGroup>
            <FormGroup className='text-center'>
              <Button id="submits" type="submit">
                Submit              </Button>
              {messagttouser !== null ? <p style={{ color: "red" }}>{messagttouser}</p> : null}
            </FormGroup>
          </Form>
        </Card>
      </div>
    </>
  );
}

export default BankView

