import React, { useState, useEffect } from "react";

// reactstrap components
import { Button, Card, FormGroup, Input, Row, Col, Form } from "reactstrap";
// core components
import { database } from "../../components/firebase";
import { useHistory } from "react-router-dom";

function UpdateCityRateCard(props) {
  let datarr = props.location.customCRCData;
  let history = useHistory();
  const [nexttoggle, setnexttoggle] = useState(true);
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [isDisabled, setisDisabled] = useState(false);

  //city
  const [cityName, setcityName] = useState("");
  const [typename, settypename] = useState("");
  //share
  const [baseFare, setbaseFare] = useState(0);
  const [convenience_multiplier, setconvenience_multiplier] = useState(0);
  const [lasg_fee, setlasg_fee] = useState(0);
  const [min_fare, setmin_fare] = useState(0);
  const [peak_multiplier, setpeak_multiplier] = useState(0);
  const [taxes, settaxes] = useState(0);
  const [time_multiplier, settime_multiplier] = useState(0);
  const [toll_charges, settoll_charges] = useState(0);
  const [percentage_2, setpercentage_2] = useState(0);

  //sdistance peak_multiplier
  const [Value0_4, setValue0_4] = useState(0);
  const [Value4_8, setValue4_8] = useState(0);
  const [Value8_12, setValue8_12] = useState(0);
  const [Value12_16, setValue12_16] = useState(0);
  const [Value16, setValue16] = useState(0);
  //single timegrid
  const [singletg1, setsingletg1] = useState(0);
  const [singletg2, setsingletg2] = useState(0);
  const [singletg3, setsingletg3] = useState(0);
  useEffect(() => {
    if (datarr === undefined) {
      setnexttoggle(true);

      history.goBack();
    } else {
      setcityName(datarr.city_name);
      settypename(datarr.type);
      setbaseFare(datarr.base_fare);
      setconvenience_multiplier(datarr.convenience_multiplier);
      setlasg_fee(datarr.lasg_fee);
      setmin_fare(datarr.min_fare);
      setpeak_multiplier(datarr.peak_multiplier);
      settaxes(datarr.taxes);
      settime_multiplier(datarr.time_multiplier);
      settoll_charges(datarr.toll_charges);
      setpercentage_2(datarr.share_percentage_2 || 0);
      setValue0_4(datarr["0-4"]);
      setValue4_8(datarr["4-8"]);
      setValue8_12(datarr["8-12"]);
      setValue12_16(datarr["12-16"]);
      setValue16(datarr["16>"]);
      if (datarr.time_grid !== undefined) {
        setsingletg1(datarr.time_grid.tg1);
        setsingletg2(datarr.time_grid.tg2);
        setsingletg3(datarr.time_grid.tg3);
      }
      setnexttoggle(false);
    }
  }, [datarr]);

  const handleSubmit1 = (e) => {
    e.preventDefault();
    setisDisabled(true);
    var cname = cityName.toLowerCase();
    var data = {
      [typename]: {
        base_fare: Number(baseFare),
        convenience_multiplier: Number(convenience_multiplier),
        distance_multiplier: {
          "0-4": Number(Value0_4),
          "12-16": Number(Value12_16),
          "16>": Number(Value16),
          "4-8": Number(Value4_8),
          "8-12": Number(Value8_12),
        },
        time_grid: {
          tg1: Number(singletg1),
          tg2: Number(singletg1),
          tg3: Number(singletg1),
        },
        lasg_fee: Number(lasg_fee),
        min_fare: Number(min_fare),
        peak_multiplier: Number(peak_multiplier),
        taxes: Number(taxes),
        time_multiplier: Number(time_multiplier),
        toll_charges: Number(toll_charges),
      },
    };
    if (typename === "share") {
      data[typename].share_percentage_2 = Number(percentage_2);
    }
    console.log(data);
    var dbSelect = database.ref("price_calculation/" + cname);
    dbSelect
      .update(data)
      .then(() => {
        alert("successfully");
        setisDisabled(true);
        history.goBack();
      })
      .catch((e) => alert(e));
  };

  if (datarr === undefined || (datarr === null && nexttoggle)) {
    return (
      <>
        <div className="content">
          <div className="w-100 ">
            <h5 style={{ display: "inline-block" }}>City Rate Card</h5>
            <p>{messagttouser}</p>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="content w-100">
        <div className="w-100" style={{ display: "inline-block" }}>
          <h5>City Rate Card</h5>
        </div>
        <Card className="m-2 p-2">
          <Form onSubmit={handleSubmit1} className="w-100">
            <Row>
              <Col sm={12}>
                <hr className="m-1" />
                <h5 className="m-2">City Name</h5>
              </Col>
              <Col md={4} className="ml-2">
                <FormGroup>
                  <Input
                    required
                    placeholder="Enter city name"
                    disabled
                    type="text"
                    value={cityName}
                    onChange={(e) => {
                      setcityName(e.target.value);
                    }}
                  />
                </FormGroup>{" "}
              </Col>
              <Col sm={12}>
                <hr className="m-1" />
                <h4 className="m-2" style={{ textTransform: "capitalize" }}>
                  {typename} Ride{" "}
                </h4>
                <hr className="mb-3" />
              </Col>
              <Col md={4} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">Base Fare</p>
                  <Input
                    required
                    placeholder="Enter Base Fare"
                    type="number"
                    value={baseFare}
                    onChange={(e) => {
                      setbaseFare(e.target.value);
                    }}
                  />
                </FormGroup>{" "}
              </Col>
              <Col md={4} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">convenience multiplier</p>

                  <Input
                    required
                    placeholder="Enter convenience multiplier"
                    type="number"
                    value={convenience_multiplier}
                    onChange={(e) => {
                      setconvenience_multiplier(e.target.value);
                    }}
                  ></Input>
                </FormGroup>{" "}
              </Col>
              <Col md={12} className=" px-5">
                <hr className="m-0" />
                <h5 className="m-2">Distance Multiplier</h5>
                <hr className="m-1" />
                <Row>
                  <Col md={4} className="mt-2">
                    <FormGroup>
                      <p className="Labelstyle">0 - 4</p>

                      <Input
                        required
                        value={Value0_4}
                        onChange={(e) => {
                          setValue0_4(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-2">
                    <FormGroup>
                      <p className="Labelstyle">4 - 8</p>

                      <Input
                        required
                        value={Value4_8}
                        onChange={(e) => {
                          setValue4_8(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-2">
                    <FormGroup>
                      <p className="Labelstyle">8 - 12</p>

                      <Input
                        required
                        value={Value8_12}
                        onChange={(e) => {
                          setValue8_12(e.target.value);
                        }}
                        placeholder="Enter  number"
                        type="number"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-2">
                    <FormGroup>
                      <p className="Labelstyle">12 - 16</p>

                      <Input
                        required
                        value={Value12_16}
                        onChange={(e) => {
                          setValue12_16(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-2">
                    <FormGroup>
                      <p className="Labelstyle"> 16 +</p>

                      <Input
                        required
                        value={Value16}
                        onChange={(e) => {
                          setValue16(e.target.value);
                        }}
                        placeholder="Enter  number"
                        type="number"
                      />
                    </FormGroup>{" "}
                  </Col>
                </Row>
              </Col>
              <Col md={12} className="mt-2 px-5">
                <hr className="m-0" />
                <h5 className="m-2">Time Grid Multiplier</h5>
                <hr className="m-1" />
                <Row>
                  <Col md={4} className="mt-4">
                    <FormGroup>
                      <p className="Labelstyle">Less than 20 minutes</p>

                      <Input
                        required
                        value={singletg1}
                        onChange={(e) => {
                          setsingletg1(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-1">
                    <FormGroup>
                      <p className="Labelstyle">
                        Greater than 20 minutes,Less than 40 minutes
                      </p>

                      <Input
                        required
                        value={singletg2}
                        onChange={(e) => {
                          setsingletg2(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-4">
                    <FormGroup>
                      <p className="Labelstyle">Greater than 40 minutes</p>

                      <Input
                        required
                        value={singletg3}
                        onChange={(e) => {
                          setsingletg3(e.target.value);
                        }}
                        placeholder="Enter  number"
                        type="number"
                      />
                    </FormGroup>{" "}
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">LASG Fee</p>

                  <Input
                    required
                    value={lasg_fee}
                    onChange={(e) => {
                      setlasg_fee(e.target.value);
                    }}
                    type="number"
                  ></Input>
                </FormGroup>{" "}
              </Col>
              <Col md={4} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">Min Fare</p>

                  <Input
                    required
                    value={min_fare}
                    onChange={(e) => {
                      setmin_fare(e.target.value);
                    }}
                    placeholder="Enter  number"
                    type="number"
                  />
                </FormGroup>{" "}
              </Col>
              <Col sm={4}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle">Peak Multiplier</p>

                  <Input
                    required
                    value={peak_multiplier}
                    onChange={(e) => {
                      setpeak_multiplier(e.target.value);
                    }}
                    placeholder="Enter Peak Multiplier"
                    type="number"
                  ></Input>
                </FormGroup>{" "}
              </Col>
              <Col sm={4}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle">Taxes</p>

                  <Input
                    required
                    value={taxes}
                    onChange={(e) => {
                      settaxes(e.target.value);
                    }}
                    placeholder="Enter taxes"
                    type="number"
                  ></Input>
                </FormGroup>{" "}
              </Col>
              {typename === "share" ? (
                <Col sm={4}>
                  {" "}
                  <FormGroup>
                    <p className="Labelstyle">
                      Percentage Increase for 2 Seats %
                    </p>

                    <Input
                      required
                      value={percentage_2}
                      onChange={(e) => {
                        setpercentage_2(e.target.value);
                      }}
                      placeholder="Enter Percentage Increase "
                      type="number"
                    ></Input>
                  </FormGroup>{" "}
                </Col>
              ) : null}
              <Col sm={4}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle">Time Multiplier</p>

                  <Input
                    required
                    value={time_multiplier}
                    onChange={(e) => {
                      settime_multiplier(e.target.value);
                    }}
                    placeholder=""
                    type="number"
                  ></Input>
                </FormGroup>{" "}
              </Col>{" "}
              <Col sm={4}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle">toll_charges</p>

                  <Input
                    required
                    value={toll_charges}
                    onChange={(e) => {
                      settoll_charges(e.target.value);
                    }}
                    type="number"
                  ></Input>
                </FormGroup>{" "}
              </Col>{" "}
              <Col md={12} />
              <Col md={6}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle"> </p>
                  <Button id="submit" disabled={isDisabled} type="submit">
                    Submit
                  </Button>
                </FormGroup>{" "}
              </Col>
              <Col md={6}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle"> </p>
                  <Button
                    className="cancelButton"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                </FormGroup>{" "}
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </>
  );
}

export default UpdateCityRateCard;
