import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from "history";

import { AuthProvider } from "./components/Authcontext"
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import Login from "./components/Login"

import PrivateRoute from "./components/PrivateRoute"

import Admin from "./components/layouts/Admin.js";
import { Provider } from "react-redux";
import store from './views/datachane/store';

import "./assets/demo/demo.css"
import "bootstrap/dist/css/bootstrap.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "./assets/scss/paper-dashboard.scss?v=1.2.0"

const hist = createBrowserHistory();
store.subscribe(() => console.log)

function App() {
  return (
      <Router history={hist}>
        <Provider store={store}>
          <AuthProvider>
            <Switch>
              <PrivateRoute exact path="/" component={Admin}>
              <Redirect to="/admin/warroom" />
              </PrivateRoute>
              <Route path="/login" component={Login}/>
              <PrivateRoute  path="/admin" component={Admin} />
              <Redirect to="/admin/warroom" />
            </Switch>
          </AuthProvider>
        </Provider>
      </Router>

  )
}

 
ReactDOM.render( <React.Fragment>
<App/>
</React.Fragment>,
  document.getElementById("root")
);

