import React, { useState ,useEffect} from "react";

// reactstrap components
import { Button, Card, FormGroup, Input, Row, Col, Form } from "reactstrap";
// core components
import { database } from "../../components/firebase";
import { useHistory } from "react-router-dom";

function AddCityRateCard(props) {
  let history = useHistory();
  let datarr=props.location.customNameData;

  const [messagttouser, setmessagttouser] = useState("Loding Data...");
  //city
  const [cityName, setcityName] = useState();
  //single
  const [baseFare, setbaseFare] = useState(0);
  const [convenience_multiplier, setconvenience_multiplier] = useState(0);
  const [lasg_fee, setlasg_fee] = useState();
  const [min_fare, setmin_fare] = useState();
  const [peak_multiplier, setpeak_multiplier] = useState(0);
  const [taxes, settaxes] = useState();
  const [time_multiplier, settime_multiplier] = useState();
  const [toll_charges, settoll_charges] = useState(0);

  //share
  const [share_baseFare, setshare_baseFare] = useState(0);
  const [share_convenience_multiplier, setshare_convenience_multiplier] =
    useState(0);
  const [share_lasg_fee, setshare_lasg_fee] = useState();
  const [share_min_fare, setshare_min_fare] = useState();
  const [share_peak_multiplier, setshare_peak_multiplier] = useState(0);
  const [share_taxes, setshare_taxes] = useState();
  const [share_time_multiplier, setshare_time_multiplier] = useState();
  const [share_toll_charges, setshare_toll_charges] = useState(0);
  const [share_percentage_2, setshare_percentage_2] = useState();

  //single distance peak_multiplier time_grid
  const [Value0_4, setValue0_4] = useState();
  const [Value4_8, setValue4_8] = useState();
  const [Value8_12, setValue8_12] = useState();
  const [Value12_16, setValue12_16] = useState();
  const [Value16, setValue16] = useState();
  //single timegrid
  const [singletg1, setsingletg1] = useState(0);
  const [singletg2, setsingletg2] = useState(0);
  const [singletg3, setsingletg3] = useState(0);

  //share distance peak_multiplier
  const [shareValue0_4, setshareValue0_4] = useState();
  const [shareValue4_8, setshareValue4_8] = useState();
  const [shareValue8_12, setshareValue8_12] = useState();
  const [shareValue12_16, setshareValue12_16] = useState();
  const [shareValue16, setshareValue16] = useState();
  //share timegrid
  const [sharetg1, setsharetg1] = useState(0);
  const [sharetg2, setsharetg2] = useState(0);
  const [sharetg3, setsharetg3] = useState(0);
  const [country, setcountry] = useState([]);
  const [countryname, setcountryname] = useState('');
const [cityname, setcityname] = useState([])
const [selectedcity, setselectedcity] = useState()
  useEffect(() => {
if(datarr!==undefined){
  setcountry(datarr.country);
  setcountryname(datarr.country)
 // window.document.getElementById("countryrtc").setAttribute("disabled", "disabled");
  handleselect(datarr.country);
  setselectedcity(datarr.cityname);
  //window.document.getElementById("cityrtc").setAttribute("disabled", "disabled");

}
    var ratecard = database.ref("localization/countries");
    ratecard.on("value", (snapshot) => {
      const datarate = snapshot.val();
      setcountry(datarate);
    });
  }, []);
 const handleselect=(e)=>{
   if(e!==''){
  setcountryname(e)
  var jsondata=JSON.parse(e)
  var ratecard = database.ref("localization/"+jsondata.name);
    ratecard.on("value", (snapshot) => {
      const datacity = snapshot.val();
      if(datacity===null){
        alert('no cities avalable under this country. Please add city to this country')
      }else{
      setcityname(datacity.cities);
      }
    });
  }else{
    setcountryname('')
    setcityname([])
  }
 }
  const handleSubmit1 = (e) => {
    e.preventDefault();
    var cname = selectedcity.toLowerCase();
    var data = {
      [cname]: {
        share: {
          base_fare: Number(share_baseFare),
          convenience_multiplier: Number(share_convenience_multiplier),
          distance_multiplier: {
            "0-4": Number(shareValue0_4),
            "12-16": Number(shareValue12_16),
            "16>": Number(shareValue16),
            "4-8": Number(shareValue4_8),
            "8-12": Number(shareValue8_12),
          },
          time_grid: {
            tg1: sharetg1,
            tg2: sharetg2,
            tg3: sharetg3,
          },
          lasg_fee: Number(share_lasg_fee),
          min_fare: Number(share_min_fare),
          peak_multiplier: Number(share_peak_multiplier),
          share_percentage_2: Number(share_percentage_2),
          taxes: Number(share_taxes),
          time_multiplier: Number(share_time_multiplier),
          toll_charges: Number(share_toll_charges),
        },
        single: {
          base_fare: Number(baseFare),
          convenience_multiplier: Number(convenience_multiplier),
          distance_multiplier: {
            "0-4": Number(Value0_4),
            "12-16": Number(Value12_16),
            "16>": Number(Value16),
            "4-8": Number(Value4_8),
            "8-12": Number(Value8_12),
          },
          time_grid: {
            tg1: singletg1,
            tg2: singletg2,
            tg3: singletg3,
          },
         
          lasg_fee: Number(lasg_fee),
          min_fare: Number(min_fare),
          peak_multiplier: Number(peak_multiplier),
          taxes: Number(taxes),
          time_multiplier: Number(time_multiplier),
          toll_charges: Number(toll_charges),
        },
      },
    };
    console.log(data);
    var dbSelect = database.ref("price_calculation");
    dbSelect
      .update(data)
      .then(alert("successfully"))
      .catch((e) => alert(e));
  };

  if (country===null) {
    return (
      <>
        <div className="content">
          <div className="w-100 ">
            <h5>Add City Rate Card</h5>
           {country===null?<p>No country Data found</p>:<p></p>}
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="content w-100">
        <div className="w-100" style={{ display: "inline-block" }}>
          <h5>City Rate Card</h5>
        </div>
        <Card className="m-2 p-2">
          <Form onSubmit={handleSubmit1} className="w-100">
            <Row>
            <Col md={4} className="mt-2">
                <FormGroup>
                <hr className="m-1" />

                  <p className="Labelstyle">Country Name</p>
                  <Input
                  id="countryrtc"
                    required
                    value={countryname}
                    onChange={(e) => {
                      handleselect(e.target.value);
                    }}
                    placeholder="select category"
                    type="select"
                  >
                    <option value="">select country</option>
                    {country.map((data, key) => {
                      return (
                        <option key={key} value={JSON.stringify(data)}>
                          {data.name}
                        </option>
                      );
                    })}{" "}
                  </Input>
                </FormGroup>{" "}
              </Col>
              {  cityname.length!==0?<><Col sm={12}>
                <p className="m-2">City Name</p>
              </Col><Col md={4} className="">
                <FormGroup>
                <Input
                id="cityrtc"
                    required
                    value={selectedcity}
                    onChange={(e) => {
                      setselectedcity(e.target.value);
                    }}
                    type="select"
                  >
                    <option value="">select cities</option>
                    {cityname.map((data, key) => {
                      return (
                        <option key={key} value={data}>
                          {data}
                        </option>
                      );
                    })}{" "}
                  </Input>
                
                </FormGroup>{" "}
              </Col></>:null
           

              }
              
              <Col sm={12}>
                <hr className="m-1" />
                <h4 className="m-2">Single Ride</h4>
                <hr className="m-1" />
              </Col>
              <Col md={4} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">Base Fare</p>
                  <Input
                    required
                    placeholder="Enter Base Fare"
                    type="number" min="0"
                    value={baseFare}
                    onChange={(e) => {
                      setbaseFare(e.target.value);
                    }}
                  />
                </FormGroup>{" "}
              </Col>
              <Col md={4} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">convenience multiplier</p>

                  <Input
                    required
                    placeholder="Enter convenience multiplier"
                    type="number" min="0"
                    value={convenience_multiplier}
                    onChange={(e) => {
                      setconvenience_multiplier(e.target.value);
                    }}
                  ></Input>
                </FormGroup>{" "}
              </Col>
              <Col md={12} className="mt-2 px-5">
                <hr className="m-0" />
                <h5 className="m-2">Distance Multiplier</h5>
                <hr className="m-1" />
                <Row>
                  <Col md={4} className="mt-2">
                    <FormGroup>
                      <p className="Labelstyle">0 - 4</p>

                      <Input
                        required
                        value={Value0_4}
                        onChange={(e) => {
                          setValue0_4(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number" min="0"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-2">
                    <FormGroup>
                      <p className="Labelstyle">4 - 8</p>

                      <Input
                        required
                        value={Value4_8}
                        onChange={(e) => {
                          setValue4_8(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number" min="0"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-2">
                    <FormGroup>
                      <p className="Labelstyle">8 - 12</p>

                      <Input
                        required
                        value={Value8_12}
                        onChange={(e) => {
                          setValue8_12(e.target.value);
                        }}
                        placeholder="Enter  number"
                        type="number" min="0"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-2">
                    <FormGroup>
                      <p className="Labelstyle">12 - 16</p>

                      <Input
                        required
                        value={Value12_16}
                        onChange={(e) => {
                          setValue12_16(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number" min="0"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-2">
                    <FormGroup>
                      <p className="Labelstyle"> 16 +</p>

                      <Input
                        required
                        value={Value16}
                        onChange={(e) => {
                          setValue16(e.target.value);
                        }}
                        placeholder="Enter  number"
                        type="number" min="0"
                      />
                    </FormGroup>{" "}
                  </Col>
                </Row>
              </Col>
              <Col md={12} className="mt-2 px-5">
                <hr className="m-0" />
                <h5 className="m-2">Time  Multiplier</h5>
                <hr className="m-1" />
                <Row>
                  <Col md={4} className="mt-4">
                    <FormGroup>
                      <p className="Labelstyle">Less than 20 minutes</p>

                      <Input
                        required
                        value={singletg1}
                        onChange={(e) => {
                          setsingletg1(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number" min="0"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-1">
                    <FormGroup>
                      <p className="Labelstyle">
                        Greater than 20 minutes,Less than 40 minutes
                      </p>

                      <Input
                        required
                        value={singletg2}
                        onChange={(e) => {
                          setsingletg2(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number" min="0"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-4">
                    <FormGroup>
                      <p className="Labelstyle">Greater than 40 minutes</p>

                      <Input
                        required
                        value={singletg3}
                        onChange={(e) => {
                          setsingletg3(e.target.value);
                        }}
                        placeholder="Enter  number"
                        type="number" min="0"
                      />
                    </FormGroup>{" "}
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">GOVT. Fee</p>

                  <Input
                    required
                    value={lasg_fee}
                    onChange={(e) => {
                      setlasg_fee(e.target.value);
                    }}
                    type="number" min="0"
                  ></Input>
                </FormGroup>{" "}
              </Col>
              <Col md={4} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">Min Fare</p>

                  <Input
                    required
                    value={min_fare}
                    onChange={(e) => {
                      setmin_fare(e.target.value);
                    }}
                    placeholder="Enter  number"
                    type="number" min="0"
                  />
                </FormGroup>{" "}
              </Col>
              <Col sm={4}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle">Peak Multiplier</p>

                  <Input
                    required
                    value={peak_multiplier}
                    onChange={(e) => {
                      setpeak_multiplier(e.target.value);
                    }}
                    placeholder="Enter Peak Multiplier"
                    type="number" min="0"
                  ></Input>
                </FormGroup>{" "}
              </Col>
              <Col sm={4}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle">Taxes</p>

                  <Input
                    required
                    value={taxes}
                    onChange={(e) => {
                      settaxes(e.target.value);
                    }}
                    placeholder="Enter taxes"
                    type="number" min="0"
                  ></Input>
                </FormGroup>{" "}
              </Col>{" "}
              <Col sm={4}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle">Time Multiplier</p>

                  <Input
                    required
                    value={time_multiplier}
                    onChange={(e) => {
                      settime_multiplier(e.target.value);
                    }}
                    placeholder=""
                    type="number" min="0"
                  ></Input>
                </FormGroup>{" "}
              </Col>{" "}
              <Col sm={4}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle">toll charges</p>

                  <Input
                    required
                    value={toll_charges}
                    onChange={(e) => {
                      settoll_charges(e.target.value);
                    }}
                    placeholder=""
                    type="number" min="0"
                  ></Input>
                </FormGroup>{" "}
              </Col>
              <Col sm={12}>
                <hr className="m-1" />
                <h4 className="m-2">Share Ride </h4>
                <hr className="mb-3" />
              </Col>
              <Col md={4} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">Base Fare</p>
                  <Input
                    required
                    placeholder="Enter Base Fare"
                    type="number" min="0"
                    value={share_baseFare}
                    onChange={(e) => {
                      setshare_baseFare(e.target.value);
                    }}
                  />
                </FormGroup>{" "}
              </Col>
              <Col md={4} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">convenience multiplier</p>

                  <Input
                    required
                    placeholder="Enter convenience multiplier"
                    type="number" min="0"
                    value={share_convenience_multiplier}
                    onChange={(e) => {
                      setshare_convenience_multiplier(e.target.value);
                    }}
                  ></Input>
                </FormGroup>{" "}
              </Col>
              <Col md={12} className=" px-5">
                <hr className="m-0" />
                <h5 className="m-2">Distance Multiplier</h5>
                <hr className="m-1" />
                <Row>
                  <Col md={4} className="mt-2">
                    <FormGroup>
                      <p className="Labelstyle">0 - 4</p>

                      <Input
                        required
                        value={shareValue0_4}
                        onChange={(e) => {
                          setshareValue0_4(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number" min="0"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-2">
                    <FormGroup>
                      <p className="Labelstyle">4 - 8</p>

                      <Input
                        required
                        value={shareValue4_8}
                        onChange={(e) => {
                          setshareValue4_8(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number" min="0"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-2">
                    <FormGroup>
                      <p className="Labelstyle">8 - 12</p>

                      <Input
                        required
                        value={shareValue8_12}
                        onChange={(e) => {
                          setshareValue8_12(e.target.value);
                        }}
                        placeholder="Enter  number"
                        type="number" min="0"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-2">
                    <FormGroup>
                      <p className="Labelstyle">12 - 16</p>

                      <Input
                        required
                        value={shareValue12_16}
                        onChange={(e) => {
                          setshareValue12_16(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number" min="0"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-2">
                    <FormGroup>
                      <p className="Labelstyle"> 16 +</p>

                      <Input
                        required
                        value={shareValue16}
                        onChange={(e) => {
                          setshareValue16(e.target.value);
                        }}
                        placeholder="Enter  number"
                        type="number" min="0"
                      />
                    </FormGroup>{" "}
                  </Col>
                </Row>
              </Col>
              <Col md={12} className="mt-2 px-5">
                <hr className="m-0" />
                <h5 className="m-2">Time  Multiplier</h5>
                <hr className="m-1" />
                <Row>
                  <Col md={4} className="mt-4">
                    <FormGroup>
                      <p className="Labelstyle">Less than 20 minutes</p>

                      <Input
                        required
                        value={sharetg1}
                        onChange={(e) => {
                          setsharetg1(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number" min="0"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-1">
                    <FormGroup>
                      <p className="Labelstyle">
                        Greater than 20 minutes,Less than 40 minutes
                      </p>

                      <Input
                        required
                        value={sharetg2}
                        onChange={(e) => {
                          setsharetg2(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number" min="0"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-4">
                    <FormGroup>
                      <p className="Labelstyle">Greater than 40 minutes</p>

                      <Input
                        required
                        value={sharetg3}
                        onChange={(e) => {
                          setsharetg3(e.target.value);
                        }}
                        placeholder="Enter  number"
                        type="number" min="0"
                      />
                    </FormGroup>{" "}
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">GOVT. Fee</p>

                  <Input
                    required
                    value={share_lasg_fee}
                    onChange={(e) => {
                      setshare_lasg_fee(e.target.value);
                    }}
                    type="number" min="0"
                  ></Input>
                </FormGroup>{" "}
              </Col>
              <Col md={4} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">Min Fare</p>

                  <Input
                    required
                    value={share_min_fare}
                    onChange={(e) => {
                      setshare_min_fare(e.target.value);
                    }}
                    placeholder="Enter  number"
                    type="number" min="0"
                  />
                </FormGroup>{" "}
              </Col>
              <Col sm={4}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle">Peak Multiplier</p>

                  <Input
                    required
                    value={share_peak_multiplier}
                    onChange={(e) => {
                      setshare_peak_multiplier(e.target.value);
                    }}
                    placeholder="Enter Peak Multiplier"
                    type="number" min="0"
                  ></Input>
                </FormGroup>{" "}
              </Col>
              <Col sm={4}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle">Taxes</p>

                  <Input
                    required
                    value={share_taxes}
                    onChange={(e) => {
                      setshare_taxes(e.target.value);
                    }}
                    placeholder="Enter taxes"
                    type="number" min="0"
                  ></Input>
                </FormGroup>{" "}
              </Col>
              <Col sm={4}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle">
                    Percentage Increase for 2 Seats %
                  </p>

                  <Input
                    required
                    value={share_percentage_2}
                    onChange={(e) => {
                      setshare_percentage_2(e.target.value);
                    }}
                    placeholder="Enter Percentage Increase "
                    type="number" min="0"
                  ></Input>
                </FormGroup>{" "}
              </Col>
              <Col sm={4}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle">Time Multiplier</p>

                  <Input
                    required
                    value={share_time_multiplier}
                    onChange={(e) => {
                      setshare_time_multiplier(e.target.value);
                    }}
                    placeholder=""
                    type="number" min="0"
                  ></Input>
                </FormGroup>{" "}
              </Col>{" "}
              <Col sm={4}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle">toll charges</p>

                  <Input
                    required
                    value={share_toll_charges}
                    onChange={(e) => {
                      setshare_toll_charges(e.target.value);
                    }}
                    type="number" min="0"
                  ></Input>
                </FormGroup>{" "}
              </Col>{" "}
              <Col md={12} />
              <Col md={6}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle"> </p>
                  <Button id="submit" type="submit">
                    Submit
                  </Button>
                </FormGroup>{" "}
              </Col>
              <Col md={6}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle"> </p>
                  <Button
                    className="cancelButton"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                </FormGroup>{" "}
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </>
  );
}

export default AddCityRateCard;
