const initailState = {
  arr: [],
  arrsearch: [],
  nextKey: "",
};
const AccountsRevenueRides = (state = initailState, action) => {
  switch (action.type) {
    case "ADD_REVENUE_RIDES_DATA":
      return {
        arr: state.arr.concat(action.payload),
        nextKey: action.nextKey,
        TotalCount: action.counts,
        arrsearch: [],
      };
    case "ADD_REVENUE_RIDES_SEARCH_DATA":
      return { ...state, arrsearch: action.payload };
      case 'CLEAN_REVENUE_RIDES':
        return initailState;
    default:
      return state;
  }
};

export default AccountsRevenueRides;
