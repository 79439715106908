
import React,{useEffect,useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Input,

} from "reactstrap";
import {addpermition} from "../datachane/actions";
import {useSelector,useDispatch} from 'react-redux';
import { connect } from 'react-redux'
// core components
import ReactTable from "../../components/ReactTable/ReactTable";

var dataser;
function Userrolehierarchy() {
  const dd=useSelector(state => state.userhie)
  const dispatch = useDispatch();
const [nexttoggle, setnexttoggle] = useState(true)
const [messagttouser, setmessagttouser] = useState("Loading Data...")
const [alertdata, setalertdata] = useState(null)
  useEffect(() => {
    if(true){
            calldataall_drivers();
  }
}, []);
function calldataall_drivers(){
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer "+localStorage.getItem('token'));
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  
  fetch("https://us-central1-africar-9309f.cloudfunctions.net/role?type=roles", requestOptions)
    .then(response => response.json())
    .then(result => {
      
        var dataresult=result.data;
var arrayvalue=[]
var objectvalue={}
result.data.roles_created.map((roles)=>{
    const dda = Object.entries(dataresult[roles]);
    const hierarchy_reverse = Object.entries(dataresult.hierarchy_reverse);
    hierarchy_reverse.map((hir)=>{
      if(roles===hir[0]){
        objectvalue.parent=hir[1]
      }
    })

    
    dda.map((dd)=>{
      objectvalue.name=roles
      objectvalue[dd[0]]=dd[1].toString();
      })
      arrayvalue.push(objectvalue)
      objectvalue={}
    })
dispatch(addpermition(arrayvalue));

       })
    .catch(error => {
      setmessagttouser('error');

      console.log('error', error)});

}

if(dd.arr.length===0){
  return (
    <>
      <div className="content">
        <div className="w-100 ">
          <h5 style={{ display: 'inline-block' }}>Hierarchy details</h5>
<p>{messagttouser}</p>     
        </div> 
        </div>
        </>)
}
  return (
    <>
      <div className="content w-100">
        <div className="w-100" style={{ display: 'inline-block' }}>
          <h5 >Hierarchy details</h5><h5>{alertdata}</h5>
        </div> 
         <ReactTable className='p-2'
        calldata={()=>{}}
          data={dd.arr}
          isnext={nexttoggle}
          countforcall={()=>{}}

          hidecolumnsdata={[
            
              "rides",
              "vendor",
              "drivers",
              "riders",     
       "push_notfications",
       "city_rate_card"
          ]}
          columns={[
            {
              Header: "User Role Name",
              accessor: "name",
            },
            {
              Header: "war_room",
              accessor: "war_room",
            },
            {
              Header: "add_users",
              accessor: "add_users",
            },
            {
              Header: "city_rate_card",
              accessor: "city_rate_card",
             
            },
            {
              Header: "push_notfications",
              accessor: "push_notfications",
            },
           
            {
              Header: "vehicles",
              accessor: "vehicles",
            },
            {
              Header: "coupons",
              accessor: "coupons",
            },{
              Header: "rides",
              accessor: "rides",
            },{
              Header: "vendor",
              accessor: "vendor",
            },{
              Header: "drivers",
              accessor: "drivers",
            },{
              Header: "riders",
              accessor: "riders",
            },
            {
              Header: "parent",
              accessor: "parent",
            },
          ]}
          /*
            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
          */
          className="-striped -highlight primary-pagination"
        />
      </div>
    </>
  );
}

export default Userrolehierarchy

