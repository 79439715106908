import firebase from 'firebase/app'
import 'firebase/auth'
import "firebase/database";
import "firebase/storage"
import 'firebase/firestore';
var firebaseConfig = {
    apiKey: "AIzaSyCru6VJYpx4nz5Q4a1JSYf1SfdFfNrezD4",
    authDomain: "africar-9309f.firebaseapp.com",
    databaseURL: "https://africar-9309f-default-rtdb.firebaseio.com",
    projectId: "africar-9309f",
    storageBucket: "africar-9309f.appspot.com",
    messagingSenderId: "468129636728",
    appId: "1:468129636728:web:6061422b057af19ac3e88d",
    measurementId: "G-MPGNL1BHYJ"
};
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const auth = app.auth();
export const database = firebase.database();
export const fire_storage = firebase.storage();
export const dbfirestore=firebase.firestore();
export default app