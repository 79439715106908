
const initailState = {
    arr:[],
    nextKey:''
}

const Unlinkedvehicledro=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_UNLINKED_DRO_VEHICLE':
            return{ 
                ...state,
                arr: state.arr.concat(action.payload),
                nextKey:action.keynext
            }
        default:
            return state;
    }
}

export default Unlinkedvehicledro