
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  Spinner,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
} from "reactstrap";
import { addDriversData } from "../datachane/actions";
import { useSelector, useDispatch } from "react-redux";
// core components
import ReactTable from "../../components/ReactTable/ReactTable";

function AllDrivers() {
  const dd = useSelector((state) => state.All_DriversStore);
  const [sipnnershow, setspinnershow] = useState(false);
  const [nextkey, setnextkey] = useState('');

  const dispatch = useDispatch();
  const [nexttoggle, setnexttoggle] = useState(true);
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [alertdata, setalertdata] = useState(null);
  const [modal, setModal] = useState(false);
  //data date
  const [isDisabled, setisDisabled] = useState(false);
  //modal toggl
  const toggle = () => {
    setModal(!modal);
  };
  useEffect(() => {
    if (dd.arr.length === 0) {
      calldataall_drivers('');
    }
  }, [0]);
  async function calldriverRepeat(e) {
    if (e >= 2) {
      var d = e;
      var da = null
      if (dd.nextKey !== "") {
        da = dd.nextKey;
      } else {
        da = null;
      }
      while (d !== 0) {
        d--;
        if (da !== null && nexttoggle) {
          da = await calldataall_drivers(da);
        } else {
          break;
        }
      }
    }
  }

  const handleSubmit1 = (event) => {
    event.preventDefault();
    setisDisabled(true);
    fileJson(dd.arr, "Driver", true);
  };
  const fileJson = async (JSONData, ReportTitle, ShowLabel) => {
    var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;

    var CSV = "";

    const items = arrData;
    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    CSV = [
      header.join(","), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ].join("\r\n");
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    //Generate a file name
    var fileName = "Report_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setisDisabled(false);
  };

  async function calldataall_drivers(nexttokenparam) {
    setspinnershow(true);

    
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return await fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/all_drivers?start=" +
      nexttokenparam,
      requestOptions
    )
      .then((response) => response.json())
      .then(result => {
        if (result.status === true) {
          console.log(result);
          setnexttoggle(true);
           dispatch(addDriversData(result.data, result.start));
          setspinnershow(false);
          return result.status
        } else {
          setspinnershow(false);
          setnexttoggle(false);
          setmessagttouser("Unable to get data....ERROR : " + result.message);
          console.log("error", result.message);
          alert("No Data To Load");
          return null;
        }
      })
      .catch((error) => {
        setnexttoggle(false);
        setspinnershow(false);
        setmessagttouser("Unable to get data....ERROR");
        setTimeout(() => {
          setalertdata("");
        }, 3000);
        console.log("error", error);
      });
  }

  if (dd.arr.length === 0) {
    return (
      <>
        <div className="content">
          <div className="w-100 ">
            <h5 style={{ display: "inline-block" }}>Drivers Details</h5>
            <p>{messagttouser}</p>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="content w-100">
        <div className="w-100" style={{ display: "inline-block" }}>
          <Row>
            <Col md={9}>
              <h5>Drivers Details</h5>
              <h5>{alertdata}</h5>
              {sipnnershow && (
                <div style={{ display: "inline-block" }}>
                  Loading data... <Spinner size="sm" color="primary" />
                </div>
              )}
            </Col>
            <Col md={3}>
              <Button
                className="w-100"
                onClick={() => {
                  toggle();
                }}
              >
                Download Ride Data
              </Button>
            </Col>
          </Row>
        </div>
        <Card>
          {" "}
          <ReactTable
            calldata={calldataall_drivers}
            countforcall={calldriverRepeat}
            data={dd.arr}
            isnext={nexttoggle}
            hidecolumnsdata={[
              "insuranceno",
              "insurancevalidity",
              "licenseno",
              "licensevalidity",
              "badgeno",
              "badgevalidity",
              "lasdricard",
              "lasdrivalidity", "max_due", "commission", "joindate", "driver_status"
            ]}
            columns={[
              {
                Header: "first name",
                accessor: "fname",
              },
              {
                Header: "lname",
                accessor: "lname",
              },
              {
                Header: "email_id",
                accessor: "emailid",
                maxWidth: 100,
                minWidth: 90,
                width: 100,
              },
              {
                Header: "contact no",
                accessor: "contactno",
              },

              {
                Header: "Assigned Vendor",
                accessor: "vendor",
              },
              {
                Header: "City",
                accessor: "city",
              },
              {
                Header: "join date",
                accessor: "joindate",
              },
              {
                Header: "dob",
                accessor: "dob",
              },
              {
                Header: "status",
                accessor: "driver_status",
              },

              {
                Header: "license no",
                accessor: "licenseno",
              },
              {
                Header: "license validity",
                accessor: "licensevalidity",
              },
              {
                Header: "Commission (%)",
                accessor: "commission",
              },
              {
                Header: "Max Due",
                accessor: "max_due",
              },
              {
                Header: "lasdri card",
                accessor: "lasdricard",
              },
              {
                Header: "lasdri card validity",
                accessor: "lasdrivalidity",
              },
            ]}
            /*
            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
          */
            className="-striped -highlight primary-pagination"
          />
        </Card>
        <Modal size="lg" className="w-100" isOpen={modal} toggle={toggle}>
          <ModalHeader>Ride Details </ModalHeader>
          <ModalBody>
            <div>
              <div className="">
                <Form onSubmit={handleSubmit1} className="w-100">
                  <p>Driver Data will be Download in CSV File...</p>
                  <p>
                    {isDisabled && (
                      <div style={{ display: "inline-block" }}>
                        Processing data... <Spinner size="sm" color="primary" />
                      </div>
                    )}
                  </p>
                  <Row>
                    <Col md={6}>
                      <Button
                        className="w-75"
                        disabled={isDisabled}
                        id="submit"
                        type="submit"
                      >
                        Download CSV File
                      </Button>
                    </Col>
                    <Col md={6}>
                      <Button
                        className="cancelButton w-75"
                        onClick={() => {
                          setModal(!modal);
                        }}
                      >
                        Close
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

export default AllDrivers;
