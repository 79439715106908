const initailState = {
    arr:[]
}
const All_vendors=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_VENDORS':
            return{ 
                ...state,
                arr: state.arr.concat(action.payload)
            }
        default:
            return state;
    }
}

export default All_vendors