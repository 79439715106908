
import React, { useEffect, useState } from "react";

// reactstrap components
import { Button, Card, Spinner } from "reactstrap";
import { addall_ridersData } from "../datachane/actions";
import { useSelector, useDispatch } from "react-redux";

// core components
import ReactTable from "components/ReactTable/ReactTable2";

function AllRiders() {
  // const dd = useSelector((state) => state.All_Riders);
  const dispatch = useDispatch();
  const [nextkey, setnextkey] = useState( "");
  const [messagttouser, setmessagttouser] = useState("Loding Data...");
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false);
var dd=[{
    
full_name:'joe manganello',
gender:'male',
email_id:'manganello123@gmail.com',
phone_number:'+937493222223',
joined_on:'22/05/2021 10:30 AM',
dob:'10/03/1990',
reason:'Pending payment'
}]

  // useEffect(() => {
  //   if (dd.arr.length === 0) {
  //     calldataall_riders("");
  //   }
  // }, []);
  async function calldataall_ridersRepeat(e) {
    // var d = e;
    // var da = "";
    // if (dd.nextKey !== "") {
    //   da = dd.nextKey;
    // } else {
    //   da = nextkey;
    // }
    // while (d !== 0) {
    //   d--;
    //   da = await calldataall_riders(da);
    // }
  }
 
  // if (dd.arr.length === 0) {
  //   return (
  //     <>
  //       <div className="content">
  //         <div className="w-100">
  //           <h5 style={{ display: "inline-block" }}>RIDERS INFO</h5>
  //           <p>{messagttouser}</p>
  //         </div>
  //       </div>
  //     </>
  //   );
  // }
  return (
    <>
      <div className="content ">
        <div className="w-100" style={{ display: "inline-block" }}>
          <h5 style={{ display: "inline-block" }}>Blacklisted Riders </h5>
          {"  "}
          {sipnnershow && (
            <div style={{ display: "inline-block" }}>
              Loading data... <Spinner size="sm" color="primary" />
            </div>
          )}
        </div>{" "}
        <ReactTable
          calldata={()=>{}}
          data={[0]}
          countforcall={calldataall_ridersRepeat}
          isnext={nexttoggle}
          columns={[
            {
              Header: "Rider name",
              accessor: "full_name",
            },
            {
              Header: "gender",
              accessor: "gender",
            },
            {
              Header: "email Id",
              accessor: "email_id",
            },
            {
              Header: "phone number",
              accessor: "phone_number",
            },
            {
              Header: "joined on",
              accessor: "joined_on",
            },
            {
              Header: "reason",
              accessor: "reason",
            },
            
          ]}
          /*
            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
          */
          className="-striped -highlight primary-pagination"
        />
      </div>
    </>
  );
}

export default AllRiders;
