
const initailState = {
    arr:[],
    nextKey:''
}
const AccountsRevenueDrivers=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_REVENUE_DRIVER_DATA':
            return{ 
                arr: state.arr.concat(action.payload),
                nextKey:action.nextKey
            }
        default:
            return state;
    }
}

export default AccountsRevenueDrivers