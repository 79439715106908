
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Spinner,
} from "reactstrap";
import { addvehicalsData } from "../datachane/actions";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
// core components
import ReactTable from "../../components/ReactTable/ReactTable";
function AllRiders() {
  const [messagttouser, setmessagttouser] = useState("No data found");
  const [errormessage, seterrormessage] = useState(null);
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false);
  // const dd = useSelector((state) => state.All_Vehiclesstore);
  const dispatch = useDispatch();
  const [nextkey, setnextkey] = useState("");
  // useEffect(() => {
  //   if (dd.arr.length === 0) {
  //     calldataall_vehicles("");
  //   }
  // }, [0]);
 



  if (true) {
    return (
      <>
        <div className="content">
          <div className="w-100">
            <h5 style={{ display: "inline-block" }}>Vehicles Details</h5>
            <p>{messagttouser}</p>
          </div>
        </div>
      </>
    );
  }
  var dd=[{
    registration_number:'Ka 41 ER 4039',
    contactno:'+234 9817384444',
    brand:'Bajaj',
    model:'Quit'
,    joindate:'03/01/2020',
    city:'lagos',
    vendor:'sagar',
    insurance_validity:'20/9/20121',
    autogenius_inspection_report_validity:'12/01/2021',
    document_status:'pending',
    vehicle_status:'pending',
    fc_validity:'22/4/2021',
    docex:'Document Expired'
  }]
  return (
    <>
      <div className="content ">
        <div className="w-100" style={{ display: "inline-block" }}>
          <h5 style={{ display: "inline-block" }}>Document Expired Vehicles  </h5>
          {errormessage}
          {"  "}
          {sipnnershow && (
            <div style={{ display: "inline-block" }}>
              Loading data... <Spinner size="sm" color="primary" />
            </div>
          )}
        </div>
        <ReactTable
          calldata={()=>{}}
          countforcall={()=>{}}
          isnext={nexttoggle}
          hidecolumnsdata={[
            "fc_validity",
            "document_status",
            "autogenius_inspection_report_validity",
            "insurance_validity",
            "action","vendor","vehicle_status",
          ]}
          data={dd}
          columns={[
            {
              Header: "vehicle Registration number",
              accessor: "registration_number",
            },
            {
              Header: "mobile number",
              accessor: "contactno",
            },
            
            {
              Header: "vehicle brand",
              accessor: "brand",
            },
            {
              Header: "vehicle model",
              accessor: "model",
            },
            {
              Header: "vehicle joined date",
              accessor: "joindate",
            },
            {
              Header: "vehicle city",
              accessor: "city",
            },
            {
              Header: "vehicle vendor name",
              accessor: "vendor",
            },
            {
              Header: "vehicle insurance validity",
              accessor: "insurance_validity",
            },
            {
              Header: "vehicle_Auto inspection_report validity",
              accessor: "autogenius_inspection_report_validity",
            },
            {
              Header: "Reason",
              accessor: "docex",
            },
            
            {
              Header: "document_status",
              accessor: "document_status",
            },
            {
              Header: "vehicle status",
              accessor: "vehicle_status",
            },

            {
              Header: "vehicle fc_validity",
              accessor: "fc_validity",
            },
            
          ]}
          /*
            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
          */
          className="-striped -highlight primary-pagination"
        />
      </div>
    </>
  );
}

export default AllRiders;
