/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,Spinner
} from "reactstrap";
import { Bar } from "react-chartjs-2";

import { adddriver_statsData } from "../datachane/actions";
import { useSelector, useDispatch } from "react-redux";
// core components
import ReactTable from "../../components/ReactTable/ReactTable";
import { AuthProvider } from "components/Authcontext";

function DriversAnalytics() {
  const dd = useSelector((state) => state.Driveranalytics);
  dd.arr.map((data) => {
    data.action = (
      <div className="">
        {/* use this button to remove the data row */}
        <Button
          onClick={() => {
            toggle(data);
          }}
          color="info"
          size="md"
          className="btn-icon btn-link like"
        >
          <i className="fa fa-eye" aria-hidden="true"></i>
        </Button>{" "}
      </div>
    );
  });
  const dispatch = useDispatch();
  const [nextkey, setnextKey] = useState("");
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [alertdata, setalertdata] = useState(null);
  //chart
  const [isDisabled, setisDisabled] = useState(true);
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false);

  const [cahrtdata, setcahrtdata] = useState();
  const [chartlabel, setchartlabel] = useState([]);
  const [modal, setModal] = useState(false);
  const [modaldata, setmodaldata] = useState("");
  const toggle = (e) => {
    setmodaldata(e);
    setModal(!modal);
  };
  useEffect(() => {
    if (dd.arr.length === 0) {
      calldataall_driversAnalytics();
    }
  }, []);
  async function calldriverRepeat(e) {
    if (e >= 2) {
      var d = e;
      var da = "";
      if (dd.nextKey !== null) {
        da = dd.nextKey;
        while (d !== 0) {
       
          var star=  await calldataall_driversAnalytics(da);
          console.log(star);
          if (star) {
            d--;
          } else {
            d = 0;
           break;
          }
  
          
        }
      }
     
    }
  }
  function calldataall_driversAnalytics(nexttokenparam) {
    setspinnershow(true);
    if (nexttokenparam === undefined) {
      nexttokenparam = nextkey;
    }
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/driver_stats?start=" +
      nexttokenparam,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setnextKey(result.last);
          if(result.total!==undefined){
          const dda = Object.entries(result);

          var dvalue = [];
          for (let i = 2; i < dda.length - 1; i++) {
            dvalue.push(dda[i][1]);
          }
          // setchartlabel(dlabel);
          setcahrtdata(dvalue);
        }
          var data = {
            total: result.total,
            accepting_failed: result.accepting_failed,
            accepted_successfully: result.accepted_successfully,
            ignored: result.ignored,
            rejected: result.rejected,
            cancelled: result.cancelled,
            last: result.last,
          };
  
          result.driver_details.map((item) => {
            var dataget = [];
            
  
            item.login.map((data) => {
              var l = data.online.length;
              for (let index = 0; index < l; index++) {
                dataget.push({
                  dateon: new Date(data.date * 1000).toISOString().split("T")[0],
                  onlinedatae: new Date(data.online[index] * 1000).toLocaleString(
                    "en-US",
                    {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    }
                  ),
                  offlinedate:
                    data.offline[index] !== undefined
                      ? new Date(data.offline[index] * 1000).toLocaleString(
                          "en-US",
                          {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          }
                        )
                      : "-",
                });
              }
            });
            item.login = dataget;
          });
          dispatch(adddriver_statsData(result.driver_details,result.last, data));
          setspinnershow(false);

        } else {
          setnexttoggle(false);
          setspinnershow(false);

          alert("Error - "+result.message);
          document.getElementById("submit").removeAttribute("disabled");
        }
        })
      .catch((error) => {
        setmessagttouser("Unable to get data....ERROR");
        setalertdata("No More Data To Fetch...");
        setTimeout(() => {
          setalertdata("");
        }, 3000);
        console.log("error", error);
      });
  }
  const chartExample10 = {
    data: {
      labels: [
        "Received",
        "Accepted",
        "Confirmed",
        "Canceled",
        "Rejected",
        "No Action",
      ],
      datasets: [
        {
          label: "Data",
          borderColor: "#fcc468",
          fill: true,
          backgroundColor: "#fcc468",
          hoverBorderColor: "#fcc468",
          borderWidth: 6,
          barPercentage: 1,

          data: cahrtdata,
        },
      ],
    },
    options: {
      tooltips: {
        tooltipFillColor: "rgba(0,0,0,0.5)",
        tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        tooltipFontSize: 14,
        tooltipFontStyle: "normal",
        tooltipFontColor: "#fff",
        tooltipTitleFontFamily:
          "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        tooltipTitleFontSize: 14,
        tooltipTitleFontStyle: "bold",
        tooltipTitleFontColor: "#fff",
        tooltipYPadding: 6,
        tooltipXPadding: 6,
        tooltipCaretSize: 8,
        tooltipCornerRadius: 6,
        tooltipXOffset: 10,
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              fontColor: "#9f9f9f",
              fontStyle: "bold",
              beginAtZero: true,
              maxTicksLimit: 5,
              padding: 20,
            },
            gridLines: {
              zeroLineColor: "transparent",
              display: true,
              drawBorder: false,
              color: "#9f9f9f",
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              zeroLineColor: "white",
              display: false,
              drawBorder: false,
              color: "transparent",
            },
            ticks: {
              padding: 20,
              fontColor: "#9f9f9f",
              fontStyle: "bold",
            },
          },
        ],
      },
    },
  };
  if (dd.arr.length === 0) {
    return (
      <>
        <div className="content">
          <div className="w-100 ">
            <h5 style={{ display: "inline-block" }}>Drivers Analytics</h5>
            <p>{messagttouser}</p>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="content w-100">
        <div className="w-100" style={{ display: "inline-block" }}>
          <h5>Drivers Analytics</h5>  {sipnnershow ? (
                      <div style={{ display: "inline-block" }}>
                        Loading data... <Spinner size="sm" color="primary" />
                      </div>
                    ) : null}
          <h5>{alertdata}</h5>
        </div>
        <Card className="m-2 p-2">
          <Row>
            <Col lg="6" md="8" sm="6">
              <Row>
                <Col md="4" xs="12">
                  <Card
                    className="card-stats h-75"
                    style={{ backgroundColor: "#A9A9A9" }}
                  >
                    <CardBody>
                      <CardTitle> BOOKINGS RECEIVED </CardTitle>
                    </CardBody>
                    <CardFooter>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "20px",
                        }}
                      >
                        {dd.totalcount.total}{" "}
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="4" xs="12">
                  <Card
                    className="card-stats h-75"
                    style={{ backgroundColor: "#EFEFEF" }}
                  >
                    <CardBody>
                      <CardTitle> BOOKINGS ACCEPTING FAILED</CardTitle>
                    </CardBody>
                    <CardFooter>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "20px",
                        }}
                      >
                        {dd.totalcount.accepting_failed}{" "}
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="4" xs="12">
                  <Card
                    className="card-stats h-75"
                    style={{ backgroundColor: "#A9A9A9" }}
                  >
                    <CardBody>
                      <CardTitle>BOOKINGS CONFIRMED </CardTitle>
                    </CardBody>
                    <CardFooter>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "20px",
                        }}
                      >
                        {dd.totalcount.accepted_successfully}{" "}
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="4" xs="12">
                  <Card
                    className="card-stats h-75"
                    style={{ backgroundColor: "#EFEFEF" }}
                  >
                    <CardBody>
                      <CardTitle>BOOKINGS CANCELLED </CardTitle>
                    </CardBody>
                    <CardFooter>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "20px",
                        }}
                      >
                        {dd.totalcount.cancelled}{" "}
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="4" xs="12">
                  <Card
                    className="card-stats h-75"
                    style={{ backgroundColor: "#A9A9A9" }}
                  >
                    <CardBody>
                      <CardTitle>BOOKINGS REJECTED </CardTitle>
                    </CardBody>
                    <CardFooter>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "20px",
                        }}
                      >
                        {dd.totalcount.rejected}{" "}
                      </div>
                    </CardFooter>
                  </Card>
                </Col>{" "}
                <Col md="4" xs="12">
                  <Card
                    className="card-stats h-75"
                    style={{ backgroundColor: "#EFEFEF" }}
                  >
                    <CardBody>
                      <CardTitle>BOOKINGS NO ACTION </CardTitle>
                    </CardBody>
                    <CardFooter>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "20px",
                        }}
                      >
                        {dd.totalcount.ignored}{" "}
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg="6" md="8" sm="6">
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle>Statistics</CardTitle>
                </CardHeader>
                <CardBody>
                  <Bar
                    data={chartExample10.data}
                    options={chartExample10.options}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Card>
        <Card className="p-2">
          {/*
                  Bookings Cancelled
ARN No.	Driver Name	Driver Contact No.	Vehicle No	Bookings Received	Bookings Accepted	Bookings Rejected	Bookings No Action	Bookings Confirmed	Bookings Cancelled Driver	Bookings Cancelled Customer	Bookings Completed	Total Revenue
                  */}
          <ReactTable
            calldata={calldataall_driversAnalytics}
            countforcall={calldriverRepeat}

            morewidth={200}
            data={dd.arr}
            isnext={nexttoggle}
            hidecolumnsdata={[
              "cancelled",
              "cancelleed",
              "candcelled",
              "canceslleed",
            ]}
            columns={[
              {
                Header: "Driver name",
                accessor: "name",
              },
              {
                Header: "Driver contact No",
                accessor: "contact_number",
              },
              {
                Header: "Vehicle No",
                accessor: "registration_number",
              },
              {
                Header: "Bookings Accepted",
                accessor: "accepting_failed",
              },
              {
                Header: "Booking Confirmed",
                accessor: "accepted_successfully",
              },
              {
                Header: "Bookings No Action",
                accessor: "ignored",
              },
              {
                Header: "Bookings Rejected ",
                accessor: "rejected",
              },
              {
                Header: "Bookings Cancelled ",
                accessor: "cancelled",
              },
              {
                Header: "Active Hour's ",
                accessor: "cancelleed",
              },
              {
                Header: "Offline Hour's",
                accessor: "candcelled",
              },
              {
                Header: "Total Revenue ",
                accessor: "canceslleed",
              },
              {
                Header: "Actions ",
                accessor: "action",
              },
            ]}
            className="-striped -highlight primary-pagination"
          />
          <Modal size="lg" className="w-100" isOpen={modal} toggle={toggle}>
            <ModalHeader>Driver Details</ModalHeader>
            <ModalBody className="pr-1 ">
              <div>
                <Row>
                  <Col md={12} xl={6} style={{ borderLeft: "2px solid black" }}>
                    <Row>
                      <Col md={6}>
                        <h6> Name :</h6>
                      </Col>
                      <Col md={6}>
                        <p>{modaldata.name}</p>
                      </Col>
                      <Col md={6}>
                        <h6> Driver contact No :</h6>
                      </Col>
                      <Col md={6}>
                        <p>{modaldata.contact_number}</p>
                      </Col>
                      <Col md={6}>
                        <h6> Vehicle No :</h6>
                      </Col>
                      <Col md={6}>
                        <p>{modaldata.registration_number}</p>
                      </Col>
                      <Col md={6}>
                        <h6> Bookings Accepted :</h6>
                      </Col>
                      <Col md={6}>
                        <p>{modaldata.accepting_failed}</p>
                      </Col>
                      <Col md={6}>
                        <h6> Booking Confirmed :</h6>
                      </Col>
                      <Col md={6}>
                        <p>{modaldata.accepted_successfully}</p>
                      </Col>
                      <Col md={6}>
                        <h6> Bookings No Action :</h6>
                      </Col>
                      <Col md={6}>
                        <p>{modaldata.ignored}</p>
                      </Col>
                      <Col md={6}>
                        <h6> Bookings Rejected :</h6>
                      </Col>
                      <Col md={6}>
                        <p>{modaldata.rejected}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} xl={6} style={{ borderLeft: "2px solid black" }}>
                    <Row className="w-100">
                      <Col md={6}>
                        <h6> Bookings Cancelled :</h6>
                      </Col>
                      <Col md={6}>
                        <p>{modaldata.cancelled}</p>
                      </Col>
                      <Col
                        md={12}
                        style={{ overflowY: "auto", height: "200px" }}
                      >
                        {modaldata.login !== undefined ? (
                          <Tablelogin_logout datalogin={modaldata.login} />
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
              className='mr-3'
                color="secondary"
                onClick={() => {
                  setmodaldata("");
                  setModal(!modal);
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </Card>
      </div>
    </>
  );
}

function Tablelogin_logout({ datalogin }) {
  return (
    <Table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Login Time</th>
          <th>Logout Time</th>
        </tr>
      </thead>
      <tbody>
        {datalogin.length !== 0
          ? datalogin.map((data, key) => (
              <tr key={key}>
                <th scope="row"> {data.dateon}</th>
                <td>{data.onlinedatae}</td>
                <td>{data.offlinedate}</td>
              </tr>
            ))
          : null}
      </tbody>
    </Table>
  );
}

export default DriversAnalytics;
