import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Spinner,
  InputGroupText,
} from "reactstrap";
import { searchdata, adddunlinkedvehicleData } from "../datachane/actions";
import { useSelector, useDispatch } from "react-redux";
// core components
import App from "./ReactTableglobalsearch";
function VehicleToDriver() {
  const isloading = useSelector((state) => state.Isload);
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [errormessage, seterrormessage] = useState(null);
  const [errormessage2, seterrormessage2] = useState(null);

  const [nexttoggle, setnexttoggle] = useState(true);
  const dd = useSelector((state) => state.Searchdata);
  const UnlinkedVehicle = useSelector((state) => state.UnlinkedVehicle);
  const [sipnnershow, setsipnnershow] = useState(false);
  const [VehicalResponse, setVehicalResponse] = useState(null);

  //UnlinkedVehicle
  const dispatch = useDispatch();
  const [selectedVehical, setselectedVehical] = useState(null);

  const [vehiclesetdata1, setvehiclesetdata1] = useState(null);
  const [search_value, setsearch_value] = useState();
  const [shodsearch, setshodsearch] = useState(false);
  const [vehicleRN, setvehicleRN] = useState("");

  const [selecterdriver, setselecterdriver] = useState(null);
  function handleSubmit(event) {
    event.preventDefault();
    if (vehiclesetdata1 != null && selecterdriver != null) {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        driver: selecterdriver,
        vehicle: vehiclesetdata1,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log(raw);

      fetch(
        "https://us-central1-africar-9309f.cloudfunctions.net/link_driver_vehicle",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === false) {
            alert(result.message);
          } else {
            setselectedVehical(null);
            alert("Driver Has BeenLinked To The Vehicle");
            setselecterdriver(null);
            setvehiclesetdata1("");
            setVehicalResponse(null);
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      alert("Please select the values properly");
    }
  }
  async function handleSearchvehicle(event) {
    event.preventDefault();
    setsipnnershow(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/search_asset?asset=vehicle&search=" +
        vehicleRN +
        "&type=vehicle_driver",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === false) {
          seterrormessage2(result.message);
          setTimeout(() => {
            seterrormessage2(null);
          }, 2000);
          setsipnnershow(false);
        } else {
          setsipnnershow(false);
          setVehicalResponse(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }

  if (UnlinkedVehicle.arr.length === 0 && isloading) {
    return (
      <>
        <div className="content">
          <div className="w-100 ">
            <h5 style={{ display: "inline-block" }}>loading data...</h5>{" "}
            {sipnnershow && (
              <div style={{ display: "inline-block" }}>
                Loading data... <Spinner size="sm" color="primary" />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
  async function handleSearchSubmit(event) {
    event.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = "";
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/search_users?search=" +
        search_value +
        "&user=driver&type=linking",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === false) {
          seterrormessage(result.message);
          setshodsearch(false);
          setTimeout(() => {
            seterrormessage(null);
          }, 2000);
        } else {
          if (dd.arr.length !== 0) {
            var dd_id = [];
            dd_id = dd.arr.map((d) => d.id);
            if (dd_id.includes(result.data.id)) {
              alert("Data already present");
            } else {
              dispatch(searchdata(result.data));
            }
          } else {
            dispatch(searchdata(result.data));
          }
        }
      })
      .catch((error) => console.log("error", error));
  }

  return (
    <>
      <div className="content ">
        <div className="w-100 mt-3">
          <h5 style={{ display: "inline-block" }}>Vehicle to driver</h5>{" "}
        </div>

        <Card className="h-75 m-1 p-2">
          <Row>
            <Col md={6}>
              <Card className="mb-2 p-1 h-100">
                <div className="content ">
                  <div className="w-100" style={{ display: "inline-block" }}>
                    <h5 style={{ display: "inline-block" }}>Vehicle Search </h5>
                    <p>"Please Enter a Valid Vehicle Registration Number" </p>
                    <Form onSubmit={handleSearchvehicle} className="w-100">
                      <InputGroup className="input-group-focus">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-search" aria-hidden="true" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          value={vehicleRN}
                          name="vSearch"
                          placeholder="Registration Number"
                          onChange={(e) => setvehicleRN(e.target.value)}
                          type="text"
                        />
                      </InputGroup>
                      <FormGroup className='text-center'>
                        <Button id="submits" type="submit">
                          Search Vehicle
                        </Button>
                        <div>
                          {errormessage2 !== null ? (
                            <p style={{ color: "red" }}>{errormessage2}</p>
                          ) : null}
                        </div>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </Card>
            </Col>

            <Col md={6}>
                <Card className="h-100 p-1">
                  <div className="content ">
                    <div className="w-100" style={{ display: "inline-block" }}>
                      <h5 style={{ display: "inline-block" }}>
                        Driver Search{" "}
                      </h5>
                      <p>"Please Enter a Valid Contact Number Or Email ID" </p>
                      <Form onSubmit={handleSearchSubmit} className="w-100">
                        <InputGroup className="input-group-focus">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-search" aria-hidden="true" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            value={search_value}
                            name="vSearch"
                            placeholder="Email Id / Contact Number"
                            onChange={(e) => setsearch_value(e.target.value)}
                            type="text"
                          />
                        </InputGroup>
                        <FormGroup className='text-center'>
                          <Button id="submit" type="submit">
                            Search Drivers
                          </Button>
                          <div>
                            {errormessage !== null ? (
                              <p style={{ color: "red" }}>{errormessage}</p>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </Card>
            </Col>
          </Row>
        </Card>
        <Card className="p-2">
          <Row>
            <Col md={5}>
              {" "}
              {VehicalResponse !== null ? (
                <Card
                  className=" p-2 h-100"
                  style={{ backgroundColor: "#EFEFEF", textAlign: "justify" }}
                  id={VehicalResponse.id}
                  onClick={() => {
                    setvehiclesetdata1(VehicalResponse.id);
                    document.getElementById(
                      VehicalResponse.id
                    ).style.backgroundColor = "#EFEF";
                  }}
                >
                  <Row style={{ textTransform: "capitalize" }}>
                    <Col sm={12}>
                      <p className="marginzero">
                        Registration Number:{" "}
                        <span>{VehicalResponse.registration_number}</span>
                      </p>
                    </Col>

                    <Col sm={12}>
                      <p className="marginzero">
                        Model : <span>{VehicalResponse.model}</span>
                      </p>
                    </Col>
                    <Col sm={12}>
                      <p className="marginzero">
                        Brand :<span>{VehicalResponse.brand}</span>
                      </p>
                    </Col>
                    <Col sm={12}>
                      <p className="marginzero">
                        city : <span>{VehicalResponse.city}</span>
                      </p>
                    </Col>
                    <Col sm={12}>
                      <p className="marginzero">
                        Phone Number : <span>{VehicalResponse.number}</span>
                      </p>
                    </Col>
                  </Row>{" "}
                </Card>
              ) : null}
            </Col>{" "}
            <Col md={6}>
              <Row>
                {dd.arr.length !== 0
                  ? dd.arr.map((data) => {
                      return (
                        <Col md={12}>
                          <Card
                            className="p-2 text-center"
                            id={data.id}
                            onClick={() => {
                              setselecterdriver(data.id);
                              document.getElementById(
                                data.id
                              ).style.backgroundColor = "#EFEF";
                            }}
                          >
                            <Row>
                              <Col xl={12} >
                                <img
                                  height={"150px"}
                                  width={"200px"}
                                  src={data.license_back_pic||null}
                                ></img>
                              </Col>
                              <Col sm={12}>
                                <span>
                                  <h5></h5>Driver Name :{" "}
                                  {data.fname + " " + data.lname}
                                </span>
                              </Col>
                              <Col sm={12}>
                                <span>
                                  <h5></h5>Contact No : {data.contactno}
                                </span>
                              </Col>
                              <Col sm={12}>
                                <span>
                                  <h5></h5>Gender : {data.gender}
                                </span>
                              </Col>
                              <Col sm={12}>
                                <span>
                                  <h5></h5>Email ID: {data.emailid}
                                </span>
                              </Col>
                              <Col sm={12}>
                                <span>
                                  <h5></h5>Vendor : {data.vendor}
                                </span>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      );
                    })
                  : null}
              </Row>
            </Col>
            <Col md={12}>
              <Form onSubmit={handleSubmit} className="w-100">
                <Button id="submit" type="submit">
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        </Card>
        <Card></Card>
      </div>
    </>
  );
}

export default VehicleToDriver;
