import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  Spinner,
  Form,
  FormGroup,
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Table,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
// core components
import ReactTable from "../../components/ReactTable/ReactTable2";
import {  addReferalData} from "../datachane/actions";
import Papa from "papaparse";

function SimCard() {
  const dd = useSelector((state) => state.SalesReferral);

  const dispatch = useDispatch();
  const [sipnnershow, setspinnershow] = useState(false);

  const [countsearch, setcountsearch] = useState(0);
  const [nextkey, setnextkey] = useState("");
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [nexttoggle, setnexttoggle] = useState(true);
  const [modal, setModal] = useState(false);
  const [isDisabled, setisDisabled] = useState();
  const [companyName, setcompanyName] = useState();
  const [Simnumber, setSimnumber] = useState();
  const [showMultiple, setshowMultiple] = useState(true);
  const [referralName, setreferralName] = useState('')
  const [referralNumber, setreferralNumber] = useState()
  const [referralCode, setreferralCode] = useState()
  const [referralCodeToken, setreferralCodeToken] = useState()
  const [fileselectedjson, setfileselectedjson] = useState({ data: [] });
  useEffect(() => {
    if (dd.arr.length === 0) {
      callReferrealdata("");
    }
  }, [0]);
  async function callreferralRepeat(e) {
    if (e >= 20) {
      var da = "";
      if (dd.nextKey !== "") {
        da = dd.nextKey;
      } else {
        da = nextkey;
      }
      if (nextkey !== null) {
        await callReferrealdata(da);
      }
    }
  }
  const CSVToJsonData = (e) => {
    const files = e.target.files;
    if (files) {
      Papa.parse(files[0], {
        header: true,
        complete: function (results) {
          setfileselectedjson({ data: results.data });
        },
      });
    }
  };
  function callReferrealdata(nexttokenparam) {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/sales_referral?start=" +
        nexttokenparam ,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.data.length !== 0) {
          result.data.map((doc) => {
          doc.added_on=
          new Date(doc.added_on * 1000).toLocaleString(
            "en-US",
            {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }
          )
          });
          if (result.start !== null) {

            setnextkey(result.start);
            setmessagttouser("");
            dispatch(addReferalData(result.data,result.start));
            return result.start;
          }
        } else {
          setnexttoggle(false)
          alert('No More Data To Load');
          setmessagttouser('No More Data To Load');

          setTimeout(() => {
            setmessagttouser('');

          }, 2500);
          return result.start===null?false:undefined;
        }
      })
      .catch((error) => console.log("error", error));
  }
  function referralCodeGenerator(nexttokenparam) {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/sales_referral?type=code" ,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {      
          if (result.status === true) {
            setreferralCodeToken(result.referral_code)
setreferralCode(JSON.parse(atob(result.referral_code.split('.')[1])).code) 
toggle();

          }
       
      })
      .catch((error) => console.log("error", error));
  }
 

  function handleSubmit1(e) {
    e.preventDefault();
    setspinnershow(true);

    document.getElementById("submit").setAttribute("disabled", "disabled");

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        code: referralCodeToken,
        name: referralName,
        phone_number:referralNumber,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/sales_referral",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          alert("Referral Created Successfully");
          document.getElementById("submit").removeAttribute("disabled");
          setreferralCode("");
          setreferralName("");
          setreferralNumber("");
          setspinnershow(false);
        } else {
          alert("Error", result.message);
          setspinnershow(false);
          setmessagttouser(result.message);
          document.getElementById("submit").removeAttribute("disabled");
        }
      })
      .catch((error) => console.log("error", error));
  }
  const toggle = () => {
    setModal(!modal);
  };

  return (
    <>
      <div className="content">
       
        <Row>
          <Col> <div className="w-100">
          <h5 style={{ display: "inline-block" }}> Referral Info</h5>
          <p>{messagttouser}</p>
        </div></Col>
          <Col>
            <Button
              className="w-100"
              onClick={() => {
                referralCodeGenerator()
              }}
            >
              Add Referral`s
            </Button>
          </Col>
        </Row>
        {dd.arr.length !== 0 ? (
          <ReactTable
            calldata={callReferrealdata}
            countforcall={callreferralRepeat}
            data={dd.arr}
            isnext={nexttoggle}
           
            columns={[
              {
                Header: "name",
                accessor: "name",
              },
              
              
              {
                Header: "phone_number",
                accessor: "phone_number",
              },
              {
                Header: "added_on",
                accessor: "added_on",
              },
              {
                Header: "referral",
                accessor: "referral",
              },
              {
                Header: "referred count",
                accessor: "referred_count",
              },
              
            ]}
            className="-striped -highlight primary-pagination"
          />
        ) : null}

        <Modal size="lg" className="w-100" isOpen={modal} toggle={toggle}>
          <ModalHeader>Add Referral </ModalHeader>
          <ModalBody>
            <Row>
             
              <Col md={12}>
                  <div>
                    {sipnnershow ? (
                      <div style={{ display: "inline-block" }}>
                        Processing data... <Spinner size="sm" color="primary" />
                      </div>
                    ) : null}
                    <Form onSubmit={handleSubmit1} className="w-100">
                      <Row>
                      <Col md={12} className="mt-2">
                          <FormGroup>
                            <p className="Labelstyle">Reference Code</p>
                            <Input
                              required
                              disabled
                              type="text"
                              value={referralCode||''}
                              onChange={(e) => {
                                setreferralCode(e.target.value);
                              }}
                            />
                          </FormGroup>{" "}
                        </Col>
                        <Col md={6} className="mt-2">
                          <FormGroup>
                            <p className="Labelstyle">Name</p>
                            <Input
                              required
                              maxLength="14"
                              placeholder="Enter Name"
                              type="text"
                              value={referralName}
                              onChange={(e) => {
                                setreferralName(e.target.value);
                              }}
                            />
                          </FormGroup>{" "}
                        </Col>
                        <Col md={6} className="mt-2">
                          <FormGroup>
                            <p className="Labelstyle">Phone number</p>
                            <Input
                              required
                              maxLength="14"
                              placeholder="Contact Number... +234 XXX XXX XXXX"
                              type="text"
                              value={referralNumber}
                              onChange={(e) => {
                                setreferralNumber(e.target.value);
                              }}
                            />
                          </FormGroup>{" "}
                        </Col>
                        <Col md={6}>
                          <Button
                            className="w-75"
                            disabled={isDisabled}
                            id="submit"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Col>
                        <Col md={6}>
                          <Button
                            className="cancelButton w-75"
                            onClick={() => {
                              setModal(!modal);
                            }}
                          >
                            Close
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
            
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

export default SimCard;
