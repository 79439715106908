const initailState = {
  arr: [],
  nextKey: "",
};
const All_Vehiclesstore = (state = initailState, action) => {
  switch (action.type) {
    case "ADD_VEHICLES":
      if (action.payload !== null) {
        return {
          arr: state.arr.concat(action.payload),
          nextKey: action.nextKey,
        };
      } else {
        return {
          arr: state.arr,
          nextKey: null,
        };
      }
    default:
      return state;
  }
};

export default All_Vehiclesstore;
