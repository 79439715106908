
const userrolecreation = (state = [], action) => {
    switch (action.type) {
        case 'ADD_USER_PERMISSIONS': {
            var arr = state
            var i = arr.length;
            var data = Object.keys(action.payload);

            while (i--) {
                if (arr[i]
                    && arr[i].hasOwnProperty(data[0])
                ) {
                 return   [...state, arr[i]=data[1]]
            break;
                }
                else{
                    if(arr[i]==null)
                    arr.splice(i, 1);                }
            }

            return [...state, action.payload];
        }

        //return (state.permissions.hasOwnProperty(action.payload)?state:action.payload)
        case 'DELETE_USER_PERMISSIONS': {
            var arr = state
            var i = arr.length;
            while (i--) {
                if (arr[i]
                    && arr[i].hasOwnProperty(action.payload)
                ) {

                    arr.splice(i, 1);

                }
            }
            return arr;
        }
        case 'CLEAN_STATE': 
        return state = [];

        default:
            return state;
    }
}

export default userrolecreation