
const initailState = {
    arr:[],
    nextKey:''
}
const Drounlinkedvehicleds=(state=initailState,action)=>{
    switch(action.type){
        case 'ON_DRO_VEHICLES':
            return {arr:action.payload,nextKey:action.keynext}
        default:
            return state;
    }
}

export default Drounlinkedvehicleds