/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";

// reactstrap components
import { Button, Card, FormGroup, Input, Row, Col, Form } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
// core components
import { database } from "../../components/firebase";
import { useHistory } from "react-router-dom";

function AddCountry() {
  const dd = useSelector((state) => state.All_DriversStore);

  let history = useHistory();

  const dispatch = useDispatch();
  const [nextkey, setnextkey] = useState("");
  const [nexttoggle, setnexttoggle] = useState(true);
  const [messagttouser, setmessagttouser] = useState("Loding Data...");
  const [alertdata, setalertdata] = useState(null);
  const [countryname, setcountryname] = useState();
  const [playstore, setplaystore] = useState();
  const [appstore, setappstore] = useState();
  const [country, setcountry] = useState([]);
  useEffect(() => {
    var ratecard = database.ref("localization/country_names");
    ratecard.on("value", (snapshot) => {
      const datarate = snapshot.val();
      setcountry(datarate);
    });
  }, []);
  const handleSubmit1 = (e) => {
    e.preventDefault();
    
    var datass = JSON.parse(countryname);
    var data = {
      appstore_link: appstore,
      playstore_link: playstore,
    };
    var lengtharr = 0;

    var setdata = database.ref("localization/countries");
    setdata.once("value").then((snapshot) => {
      var username = snapshot.val();
      if(username===null){
        database.ref("localization/countries").set([datass ])
      }else{
      lengtharr = username.length;
      setdata.update({ [lengtharr]: datass });
      }
    }).catch((e) => alert(e));;

    var dbSelect = database.ref("localization/" + datass.name);
    dbSelect
      .update(data)
      .then(() => {
        alert('country added successfully');
        setcountryname('');
        setplaystore('');
        setappstore('');
      })
      .catch((e) => alert(e));
  };
  if (country.length === 0) {
    return (
      <>
        <div className="content">
          <div className="w-100 ">
            <h5>Add Country</h5>
            <h5>{alertdata}</h5>
            <p>{messagttouser}</p>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="content w-100">
        <div className="w-100" style={{ display: "inline-block" }}>
          <h5>Add Country</h5>
          <h5>{alertdata}</h5>
        </div>
        <Card className="m-0 p-4">
          <Form onSubmit={handleSubmit1} className="w-100">
            <Row>
              <Col md={4} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">Country Name</p>
                  <Input
                    required
                    value={countryname}
                    onChange={(e) => {
                      setcountryname(e.target.value);
                    }}
                    placeholder="select category"
                    type="select"
                  >
                    <option value="single">select country</option>
                    {country.map((data, key) => {
                      return (
                        <option key={key} value={JSON.stringify(data)}>
                          {data.name}
                        </option>
                      );
                    })}{" "}
                  </Input>
                </FormGroup>{" "}
              </Col>
              <Col md={12} className="mt-2" />

              <Col md={1} className="mt-2">
                <i style={{fontSize:'3em'}} className="fa fa-google-plus-square mt-3" />
              </Col>
              <Col md={9} className="mt-1">
                <FormGroup>
                  <p className="Labelstyle">Playstore Link</p>

                  <Input
                    required
                    placeholder="Enter URL"
                    type="text"
                    value={playstore}
                    onChange={(e) => {
                      setplaystore(e.target.value);
                    }}
                  ></Input>
                </FormGroup>{" "}
              </Col>
              <Col md={12} className="mt-2" />

              <Col md={1} className="mt-2">
                <i className="fa fa-apple mt-3" style={{fontSize:'3em'}} />
              </Col>
              <Col md={9} className="mt-1">
                <FormGroup>
                  <p className="Labelstyle">AppStore Link</p>

                  <Input
                    required
                    placeholder="Enter URL"
                    type="text"
                    value={appstore}
                    onChange={(e) => {
                      setappstore(e.target.value);
                    }}
                  ></Input>
                </FormGroup>{" "}
              </Col>
              <Col md={12} />
              <Col md={6}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle"> </p>
                  <Button id="submit" type="submit">
                    Submit
                  </Button>
                </FormGroup>{" "}
              </Col>
              <Col md={6}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle"> </p>
                  <Button
                    className="cancelButton"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                </FormGroup>{" "}
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </>
  );
}

export default AddCountry;
