
const initailState = {
    arr:[],
    nextKey:'',
    totalcount:''
}
const Driveranalytics=(state=initailState,action)=>{
    switch(action.type){
        // 
        case 'ADD_DRIVER_ANALYTICS_DATA_FIRST':
            return{ 
                ...state,
                arr: state.arr.concat(action.payload),
                nextKey:action.keynext,
                totalcount:action.totalcount
            }
        case 'ADD_DRIVER_ANALYTICS_DATA':
            return{ 
                ...state,
                arr: state.arr.concat(action.payload),
                nextKey:action.keynext,
            }
        default:
            return state;
    }
}

export default Driveranalytics