import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  CardFooter,
} from "reactstrap";
function Adduser() {
  const [headdata, setheaddata] = useState();
  const [parentdataselected, setparentdataselected] = useState('')
  const [errormessage, seterrormessage] = useState(null)
  //userdetails
  const [emailid, setemailid] = useState('')
  const [password, setpassword] = useState('')
  const [username, setusername] = useState('')
  const [buttonstatus, setbuttonstatus] = useState(false)
///response data
const [Responsedata, setResponsedata] = useState()
const [Secondparametershow, setSecondparametershow] = useState(false)

//second set of data
const [secondselectvalue, setsecondselectvalue] = useState()

//third setdata
const [show3, setshow3] = useState(false)
const [selectvalue3, setselectvalue3] = useState()
const [Responsedata3, setResponsedata3] = useState()

//fourth setdata
const [show4, setshow4] = useState()
const [selectvalue4, setselectvalue4] = useState()
const [Responsedata4, setResponsedata4] = useState()

//five setdata
const [show5, setshow5] = useState()
const [selectvalue5, setselectvalue5] = useState()
const [Responsedata5, setResponsedata5] = useState()
//rawdata
const [datafetch2, setdatafetch2] = useState()
const [datafetch3, setdatafetch3] = useState()
const [datafetch4, setdatafetch4] = useState()




useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/add_users",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if(result.status===true){
        setheaddata(result);}
        else{
          seterrormessage(result.message)
        }
      })
      .catch((error) => console.log("error", error));
  }, [0]);

const apicall=(data,type)=>{
  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Bearer " + localStorage.getItem("token")
  );
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    role_name: data,
    type: type,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    "https://us-central1-africar-9309f.cloudfunctions.net/add_users",
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      if(result.status===false)
      {

        alert(result.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }else{
      setResponsedata(result)
      setSecondparametershow(true);
      }
    })
    .catch((error) => console.log("error", error));
}
const apicall2=(data,val,type,seatefunction)=>{
  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Bearer " + localStorage.getItem("token")
  );
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    role_name: parentdataselected,
    type: type,
    [data]:val
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
 fetch(
    "https://us-central1-africar-9309f.cloudfunctions.net/add_users",
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      if(result.status===false)
      {

        alert(result.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }else{
      seatefunction(result)
      setshow3(true);
      }
    })
    .catch((error) => console.log("error", error)); 
}
const apicall3=(data,val,type,seatefunction4)=>{
  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Bearer " + localStorage.getItem("token")
  );
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    role_name: parentdataselected,
    type: type,
    [data]:val
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
 fetch(
    "https://us-central1-africar-9309f.cloudfunctions.net/add_users",
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      if(result.status===false)
      {

        alert(result.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }else{
      seatefunction4(result)
      setshow4(true)}
    })
    .catch((error) =>{ 
      alert(error.message)
      console.error();
      console.log("error", error)}); 
}

const apicall4=(data,val,type,seatefunction5)=>{
  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Bearer " + localStorage.getItem("token")
  );
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    role_name: parentdataselected,
    type: type,
    [data]:val
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
 fetch(
    "https://us-central1-africar-9309f.cloudfunctions.net/add_users",
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      if(result.status===false)
      {

        alert(result.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }else{
      seatefunction5(result)
      setshow5(true)}
    })
    .catch((error) =>{ 
      alert(error.message)
      console.error();
      console.log("error", error)}); 
}

  const handleFirstselect=(e)=>{
    if(e.target.value!=''){
      apicall(e.target.value,'one')
    setparentdataselected(e.target.value);
    setshow3(false)
    setshow4(false)
document.getElementById("parentdataselected").setAttribute("disabled", "disabled");

  }else{
    setparentdataselected('');
    setSecondparametershow(false)
    setshow3(false)
    setshow4(false)
  }

}
const handleSecondselect=(e)=>{
  if(e.target.value!=''){
    setdatafetch2({[Responsedata.data_role]:e.target.value})
    apicall2(Responsedata.data_role,e.target.value,'one',setResponsedata3)
    setsecondselectvalue(e.target.value)
}else{
  setsecondselectvalue('');
  setdatafetch2({})

  setshow3(false)

  setshow4(false)
  setshow5(false)


}
}
const handlethirdselect=(e)=>{
  if(e.target.value!=''){
    setdatafetch3({[Responsedata3.data_role]:e.target.value})
    apicall3(Responsedata3.data_role,e.target.value,'one',setResponsedata4)
    setselectvalue3(e.target.value)


}else{
  setselectvalue3('')
  setdatafetch3({})

}

}
const handlefourthselect=(e)=>{
  if(e.target.value!=''){
    setdatafetch4({[Responsedata4.data_role]:e.target.value})
    apicall4(Responsedata4.data_role,e.target.value,'one',setResponsedata5)
    setselectvalue4(e.target.value)

}else{
  setselectvalue4('')
  setdatafetch4({})

}

}
//form submition
const handelsubmisition=()=>{

  if(emailid!=''&&password!=''&&username!=''&&parentdataselected!=''){
  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Bearer " + localStorage.getItem("token")
  );
  myHeaders.append("Content-Type", "application/json");



var dataselect={
  ...datafetch2,
  ...datafetch3,
  ...datafetch4,
}
var dataBody={
  role_name: parentdataselected,
  data:dataselect,
 type:'two'
}
var userdetails={
  email:emailid,
  password:password,
  name:username
}
dataBody.user_details=userdetails
  var raw = JSON.stringify(dataBody);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

 fetch(
    "https://us-central1-africar-9309f.cloudfunctions.net/add_users",
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      if(result.status===false)
      {
        alert(result.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }else{
        alert("User Created Successfully");   
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    })
    .catch((error) => console.log("error", error)); 
}else{
  alert("please provide valid data")
}
}
if(errormessage!==null){
  return (
    <>
      <div className="content">
        <div className="w-100 ">
          <h5 style={{ display: 'inline-block' }}>Drivers Details</h5>
<p>{errormessage}</p>     
        </div> 
        </div>
        </>)
}
  return (
    <div className="content">
      <Card className="m-auto p-3 ">
        <h4>Create User</h4>
        <Row>
          <Col md="12">
            <span>{errormessage!==null?errormessage:null}</span>
            <Form className="w-100">
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <label >Select User Role</label>

                    <Input placeholder="select " id="parentdataselected"
                    value={parentdataselected} 
                    onChange={(e) => {
                      handleFirstselect(e);
                    }}
                    type="select">
                      <option value="">Select</option>
                      {headdata != null
                        ? headdata.response.map((dd, key) => {
                            return (
                              <option key={key} value={dd}>
                                {dd}
                              </option>
                            );
                          })
                        : null}
                    </Input>
                  </FormGroup>
                </Col>
                {Secondparametershow&&Responsedata&&Responsedata.data_role!==undefined?( <Col md={6}><FormGroup className={`has-label `}>
                <p> {Responsedata.data_role}</p>
                <Input
                  placeholder="" type="select"
                  value={secondselectvalue} required
                  onChange={(e) => {
                    handleSecondselect(e);
                  }}
                ><option value=''>Select </option>
                  {Responsedata.data[Responsedata.data_role]?Responsedata.data[Responsedata.data_role].map((data2, i) => {
                    return <option value={data2.id} key={i}>{data2.name}</option>
  
                  }):null}
                </Input>
              </FormGroup >

                 </Col>):null

                }
            
            {show3&&Responsedata3&&Responsedata3.data_role!==undefined?( <Col md={6}><FormGroup className={`has-label `}>
                <p> {Responsedata3.data_role}</p>
                <Input
                  placeholder="" type="select"
                  value={selectvalue3} required
                  onChange={(e) => {
                    handlethirdselect(e)
                   
                  }}
                ><option value=''>Select </option>
                  {Responsedata3.data[Responsedata3.data_role]?Responsedata3.data[Responsedata3.data_role].map((data2, i) => {
                    return <option value={data2.id} key={i}>{data2.name}</option>
  
                  }):null}
                </Input>
              </FormGroup >

                 </Col>):null

                }
               {show4&&Responsedata4&&Responsedata4.data_role!==undefined?( <Col md={6}><FormGroup className={`has-label `}>
                <p> {Responsedata4.data_role}</p>
                <Input
                  placeholder="" type="select"
                  value={selectvalue4} required
                  onChange={(e) => {
                    handlefourthselect(e)

                  }}
                ><option value=''>Select </option>
                  {Responsedata4.data[Responsedata4.data_role]?Responsedata4.data[Responsedata4.data_role].map((data2, i) => {
                    return <option value={data2.id} key={i}>{data2.name}</option>
  
                  }):null}
                </Input>
              </FormGroup >

                 </Col>):null

                }     
                 {show5&&Responsedata5&&Responsedata5.data_role!==undefined?( <Col md={6}><FormGroup className={`has-label `}>
                <p> {Responsedata5.data_role}</p>
                <Input
                  placeholder="" type="select"
                  value={selectvalue5} required
                  onChange={(e) => {
                    apicall4(Responsedata5.data_role,e.target.value,'one',setResponsedata5)
                    setselectvalue5(e.target.value)
                  }}
                ><option value=''>Select </option>
                  {Responsedata5.data[Responsedata5.data_role]?Responsedata5.data[Responsedata5.data_role].map((data2, i) => {
                    return <option value={data2.id} key={i}>{data2.name}</option>
  
                  }):null}
                </Input>
              </FormGroup >

                 </Col>):null

                }              
              </Row>
              <Card>
              <Row>
              <Col md={6}>
                  <FormGroup className={`has-label `}>
                    <label>Email Address *</label>
                    <Input required
                     value={emailid} 
                     onChange={(e) => {
                       setemailid(e.target.value);
                     }}
                    name="email" type="email" />
                  </FormGroup>
                </Col>{" "}
                <Col md={6}>
                  <FormGroup className={`has-label `}>
                    <label>Password *</label>
                    <Input required
                     value={password} 
                     onChange={(e) => {
                       setpassword(e.target.value);
                     }}
                      id="Password"
                      name="password"
                      type="password"
                      autoComplete="off"
                    />
                  </FormGroup>
                </Col>{" "}
                <Col md={6}>
                  <FormGroup className={`has-label `}>
                    <label>Name</label>
                    <Input required
                      name="name"
                      value={username} 
                      onChange={(e) => {
                        setusername(e.target.value);
                      }}
                      type="text"
                      
                    />
                  </FormGroup>
                </Col>
              </Row>
              </Card>
            </Form>
          </Col>
        </Row>
        <CardFooter className="text-right">
          <Button disabled={buttonstatus} onClick={()=>handelsubmisition()} color="primary">Add User</Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default Adduser;