import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  Spinner,
  Form,
  FormGroup,
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Table,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
// core components
import ReactTable from "../../components/ReactTable/ReactTable";
import { AddSimdata } from "../datachane/actions";
import Papa from "papaparse";

function SimCard() {
  const dd = useSelector((state) => state.Simdata);

  const dispatch = useDispatch();
  const [sipnnershow, setspinnershow] = useState(false);

  const [countsearch, setcountsearch] = useState(0);
  const [nextkey, setnextkey] = useState("");
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [nexttoggle, setnexttoggle] = useState(true);
  const [modal, setModal] = useState(false);
  const [isDisabled, setisDisabled] = useState();
  const [companyName, setcompanyName] = useState();
  const [Simnumber, setSimnumber] = useState();
  const [showMultiple, setshowMultiple] = useState(true);
  const [fileselectedjson, setfileselectedjson] = useState({ data: [] });
  useEffect(() => {
    if (dd.arr.length === 0) {
      CallSimData("");
    }
  }, [0]);
  async function callsimRepeat(e) {
    if (e >= 20) {
      var da = "";
      if (dd.nextKey !== "") {
        da = dd.nextKey;
      } else {
        da = nextkey;
      }
      if (nextkey !== null) {
        await CallSimData(da);
      }
    }
  }
  const CSVToJsonData = (e) => {
    const files = e.target.files;
    if (files) {
      Papa.parse(files[0], {
        header: true,
        complete: function (results) {
          setfileselectedjson({ data: results.data });
        },
      });
    }
  };
  function CallSimData(nexttokenparam) {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/asset?start=" +
        nexttokenparam +
        "&type=sim&limit=",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true&&result.data.length!==0) {
          result.data.map((doc) => {
            doc.linked_to_vehicle = doc.linked_to_vehicle ? "Yes" : "No";
            doc.linked_to_mobile = doc.linked_to_mobile ? "Yes" : "No";

            doc.added_on = new Date(doc.added_on * 1000).toLocaleString(
              "en-US",
              {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }
            );
            doc.linked_on = new Date(doc.linked_on * 1000).toLocaleString(
              "en-US",
              {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }
            );
          });
          if (result.start === null) {
            // alert("No More Data To Load.");
            setmessagttouser("No Data To Load");
          } else {
            setnextkey(result.start);
            setmessagttouser("");
            dispatch(AddSimdata(result.data));
            return result.start;
          }
        } else {
          setnexttoggle(false)
          alert('no more data to load');
          setmessagttouser(result.message);
          return result.start;
        }
      })
      .catch((error) => console.log("error", error));
  }
  function DownloadSampleCSV() {
    const header = ["number", "company"];
    var CSV = [header.join(",")].join("\r\n");
    //Generate a file name
    var fileName = "Sample";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += "SIM Upload".replace(/ /g, "_");
    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);
    var link = document.createElement("a");
    link.href = uri;
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  function handleSubmitMultiple(e) {
    e.preventDefault();
    setspinnershow(true);

    document.getElementById("multiple").setAttribute("disabled", "disabled");
    if (fileselectedjson.data.length !== 0) {
      var heads = Object.keys(fileselectedjson.data[0]);
      if (
        heads.includes("number") &&
        heads.includes("company") &&
        heads.length === 2
      ) {
        fileselectedjson.map((doc)=>{doc.number=doc.number.replace(' ','')?doc.number.replace(' ',''):doc.number})
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("token")
        );
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(fileselectedjson);
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          "https://us-central1-africar-9309f.cloudfunctions.net/asset?type=sim",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status === true) {
              alert("Sim added Successfully");
              document.getElementById("multiple").removeAttribute("disabled");
              setfileselectedjson({ data: [] });
            } else {
              alert("Error", result.message);
              document.getElementById("multiple").removeAttribute("disabled");
              setspinnershow(false);
            }
          })
          .catch((error) => console.log("error", error));
      } else {
        document.getElementById("multiple").removeAttribute("disabled");
        setspinnershow(false);

        alert(
          "The Uploaded File Contains foreign fields.Please Check the file format"
        );
      }
    } else {
      document.getElementById("multiple").removeAttribute("disabled");
      alert("The uploaded file contain Zero Fields");
      setspinnershow(false);
    }
  }
  function handleSubmit1(e) {
    e.preventDefault();
    setspinnershow(true);

    document.getElementById("submit").setAttribute("disabled", "disabled");

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");
setSimnumber(Simnumber.replace(' ',''))
    var raw = JSON.stringify({
      type: "sim",
      company: companyName,
      number: Simnumber,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/asset",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          alert("Sim added Successfully");
          document.getElementById("submit").removeAttribute("disabled");
          setcompanyName("");
          setSimnumber("");
          setspinnershow(false);
        } else {
          alert("Error", result.message);
          setspinnershow(false);
          setmessagttouser(result.message);
          document.getElementById("submit").removeAttribute("disabled");
        }
      })
      .catch((error) => console.log("error", error));
  }
  const toggle = () => {
    setModal(!modal);
  };

  return (
    <>
      <div className="content">
        <div className="w-100">
          <h5 style={{ display: "inline-block" }}> SIM Card Info</h5>
          <p>{messagttouser}</p>
        </div>
        <Row>
          <Col></Col>
          <Col>
            <Button
              className="w-100"
              onClick={() => {
                toggle();
              }}
            >
              Add SIM
            </Button>
          </Col>
        </Row>
        {dd.arr.length !== 0 ? (
          <ReactTable
            calldata={CallSimData}
            countforcall={callsimRepeat}
            data={dd.arr}
            isnext={nexttoggle}
            hidecolumnsdata={[
              "linked_to_mobile",
              "linked_on",
              "IMEI",
              "build_number",
              "brand_name",
            ]}
            columns={[
              {
                Header: "SIM Provider",
                accessor: "company",
              },
              {
                Header: "Phone number",
                accessor: "number",
              },
              {
                Header: "added on",
                accessor: "added_on",
              },
              {
                Header: "linked to vehicle",
                accessor: "linked_to_vehicle",
              },
              {
                Header: "linked to mobile",
                accessor: "linked_to_mobile",
              },
              {
                Header: "linked On",
                accessor: "linked_on",
              },

              {
                Header: "IMEI",
                accessor: "IMEI",
              },
              {
                Header: "build number",
                accessor: "build_number",
              },
              {
                Header: "brand Name",
                accessor: "brand_name",
              },
            ]}
            className="-striped -highlight primary-pagination"
          />
        ) : null}

        <Modal size="lg" className="w-100" isOpen={modal} toggle={toggle}>
          <ModalHeader>Add SIM </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={12}>
                <FormGroup check className="pull-left">
                  <Label check>
                    Upload CSV file.
                    <Input
                      name={"ss"}
                      type="checkbox"
                      value={showMultiple}
                      onChange={(e) => {
                        setshowMultiple(!showMultiple);
                      }}
                    />
                    <span className="form-check-sign" />
                  </Label>
                </FormGroup>
              </Col>
              <Col md={12}>
                {showMultiple === true ? (
                  <div>
                    {sipnnershow ? (
                      <div style={{ display: "inline-block" }}>
                        Processing data... <Spinner size="sm" color="primary" />
                      </div>
                    ) : null}
                    <Form onSubmit={handleSubmit1} className="w-100">
                      <Row>
                        <Col md={6} className="mt-2">
                          <FormGroup>
                            <p className="Labelstyle">SIM Provider</p>
                            <Input
                              required
                              value={companyName}
                              onChange={(e) => {
                                setcompanyName(e.target.value);
                              }}
                              placeholder="select category"
                              type="select"
                            >
                              <option value="">Select provider</option>

                              <option value="MTN">MTN</option>
                              <option value="Airtel">Airtel</option>
                              <option value="9mobile">9mobile</option>
                            </Input>
                          </FormGroup>{" "}
                        </Col>
                        <Col md={6} className="mt-2">
                          <FormGroup>
                            <p className="Labelstyle">Phone number</p>
                            <Input
                              required
                              maxLength="14"
                              placeholder="E.g - +234 XXX XXX XXXX"
                              type="text"
                              onChange={(e) => {
                                setSimnumber(e.target.value);
                              }}
                            />
                          </FormGroup>{" "}
                        </Col>
                        <Col md={6}>
                          <Button
                            className="w-75"
                            disabled={isDisabled}
                            id="submit"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Col>
                        <Col md={6}>
                          <Button
                            className="cancelButton w-75"
                            onClick={() => {
                              setModal(!modal);
                            }}
                          >
                            Close
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                ) : (
                  <div>
                    {sipnnershow ? (
                      <div style={{ display: "inline-block" }}>
                        Processing data... <Spinner size="sm" color="primary" />
                      </div>
                    ) : null}
                    <Form onSubmit={handleSubmitMultiple} className="w-100">
                      <Row>
                        <Col md={12}>
                          <p>
                            Please follow the format given while uploading the
                            .csv
                          </p>
                          <p>
                            Download sample CSV{" "}
                            <Button
                              style={{ width: "100px" }}
                              onClick={() => {
                                DownloadSampleCSV();
                              }}
                            >
                              <i
                                className="fa fa-download"
                                aria-hidden="true"
                              ></i>
                            </Button>
                          </p>

                          <Table bordered dark>
                            <thead>
                              <tr>
                                <th style={{ textTransform: "lowercase" }}>
                                  number
                                </th>
                                <th style={{ textTransform: "lowercase" }}>
                                  company
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>+243 XXX XXX XXXX</td>
                                <td>Airtel</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>

                        <Col md={12}>
                          <hr />

                          <input
                            style={{ borderLeft: "6px solid red" }}
                            required
                            type="file"
                            accept=".csv"
                            onChange={(e) => {
                              CSVToJsonData(e);
                            }}
                          />
                          <hr />
                        </Col>
                        <Col md={6}>
                          <Button
                            className="w-75"
                            disabled={isDisabled}
                            id="multiple"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Col>
                        <Col md={6}>
                          <Button
                            className="cancelButton w-75"
                            onClick={() => {
                              setModal(!modal);
                            }}
                          >
                            Close
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                )}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

export default SimCard;
