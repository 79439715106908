const initailState = {}

const userpermisitions=(state=initailState,action)=>{
    switch(action.type){
        case 'USER_PERMISSIONS':
            return action.payload
        default:
            return state;
    }
}

export default userpermisitions