import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  FormGroup,
  Input,
  Row,
  Col,
  Form,
  Tooltip,
  Label,
} from "reactstrap";
import { useSelector } from "react-redux";
// core components
import { useHistory } from "react-router-dom";

function AddCityRateCard() {
  const isloading = useSelector((state) => state.Isload);
  let cities = useSelector((state) => state.Ratecarddata);
  let history = useHistory();
  const [isDisabled, setisDisabled] = useState(false);

  const [alertdata, setalertdata] = useState(null);
  const [couponname, setcouponname] = useState();
  const [city, setcity] = useState();
  const [amountoff, setamountoff] = useState('');
  const [percentoff, setpercentoff] = useState('');
  const [coupondescription, setcoupondescription] = useState();
  const [discountduration, setdiscountduration] = useState();
  const [DiscountDurationInMounth, setDiscountDurationInMounth] =
    useState();
    const [Duration_in_days, setDuration_in_days] = useState()

  const [maxredemptionperuser, setmaxredemptionperuser] = useState();
  const [redeem_by, setredeem_by] = useState();
  const [maxRedemption, setmaxRedemption] = useState();
 
  const [upto, setupto] = useState();
  //tooltip
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const [tooltipOpen3, setTooltipOpen3] = useState(false);
  //
  const [typeoff, settypeoff] = useState("");
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const toggle1 = () => setTooltipOpen1(!tooltipOpen1);
  //week
  var initialState = false;
  const [sunday, setsunday] = useState(initialState);
  const [monday, setmonday] = useState(initialState);
  const [tuesday, settuesday] = useState(initialState);
  const [wednesday, setwednesday] = useState(initialState);
  const [thursday, setthursday] = useState(initialState);
  const [friday, setfriday] = useState(initialState);
  const [saturday, setsaturday] = useState(initialState);

  //handleform submit
  function handleSubmit(event) {
    event.preventDefault();
    setisDisabled(true);

    if(amountoff!==null||amountoff!==''&&redeem_by!==null||redeem_by!==''){
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );    myHeaders.append("Content-Type", "application/json");

    var raw = {
      object: "coupon",
      id: couponname,
      city: city,
     
      duration: discountduration,
      max_redemption: Number(maxRedemption),
      redeem_by: new Date(redeem_by).getTime() / 1000,
      description: coupondescription,
      max_redemption_per_user:Number(maxredemptionperuser),
      valid: true,
    };
    if(typeoff === "amount"
     ){
      raw.amount_off=Number(amountoff)
    }
    if(typeoff === "percentage" ){
      raw.percent_off= Number(percentoff)
      raw.upto= Number(upto)
    }
    if(discountduration === "repeating" ){
      raw.duration_in_months=Number(DiscountDurationInMounth)
    }
    if(discountduration === "repeating_daily"){
      raw.duration_in_days=Number(Duration_in_days)
    }
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body:JSON.stringify(raw),
      redirect: "follow",
    };
    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/coupon",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if(result.status===true){
          alert('Cupon Added');
          setisDisabled(false);

        }else{
          alert('ERROR',result.message);
        }
        })
      .catch((error) => console.log("error", error));
  }else{
    alert("please Fill The Values ")
  }
}
function callweek(params) {

  
}
  if (isloading) {
    return (
      <>
        <div className="content">
          <div className="w-100 ">
            <h5 style={{ display: "inline-block" }}>loading data...</h5>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="content w-100">
        <div className="w-100" style={{ display: "inline-block" }}>
          <h5>Add Coupon</h5>
          <h5>{alertdata}</h5>
        </div>
        <Card className="m-2 p-2">
          {" "}
          <Form onSubmit={handleSubmit} className="w-100">
         
              <Row>
                <Col md={3} className="mt-2"><FormGroup>


                  <p className="-Formlable">Coupon Name/ID</p>
                  <Input
                  required
                    placeholder="Enter Coupon Name/ID"
                    id="cnam"
                    value={couponname}
                    type="text"
                    onChange={(e) => {
                      setcouponname(e.target.value);
                    }}
                  />                </FormGroup>

                </Col>
                <Col md={3} className="mt-2"><FormGroup>

                  <p className="-Formlable">Select City</p>

                  <Input
                  required
                    type="select"
                    value={city}
                    onChange={(e) => {
                      setcity(e.target.value);
                    }}
                  >
                    <option value="">Select City </option>
                    {cities.asasa.map((data, i) => {
                      return (
                        <option value={data} key={i}>
                          {data}
                        </option>
                      );
                    })}
                  </Input>
                  </FormGroup>
                </Col>
                <Col md={3} className="mt-2"><FormGroup>

                  <p className="-Formlable">Select Type OFF</p>
                  <Input
                  required
                    value={typeoff}
                    type="select"
                    onChange={(e) => {
                      settypeoff(e.target.value);
                    }}
                    placeholder="select Type"
                  ><option value="">Select Type</option>
                    <option value="amount">Amount Off</option>
                    <option value="percentage">Percent Off</option>
                  </Input>
                  </FormGroup>
                </Col>
                {typeoff === "amount" ? (
                  <Col md={3} className="mt-2"><FormGroup>

                    <p className="-Formlable">Amount Off</p>

                    <Input
                    required
                      onChange={(e) => {
                        setamountoff(e.target.value);
                      }}
                      placeholder="Enter Amount Off"
                      type="number"
                    /> </FormGroup>
                  </Col>
                ) : null}
              
                {typeoff === "percentage" ? (
                  <Col md={3} className="mt-2"><FormGroup>

                    <p className="-Formlable">Percent Off</p>

                    <Input
                    required
                    
                      value={percentoff}
                      onChange={(e) => {
                        setpercentoff(e.target.value);
                      }}
                      placeholder="Enter percent off"
                      type="number"
                    /> </FormGroup>
                  </Col>
                ) : null}
                {typeoff === "percentage" ? (
                  <Col md={3} className="mt-2"><FormGroup>

                    <p className="-Formlable">UPTO</p>

                    <Input
                      value={upto}
                      onChange={(e) => {
                        setupto(e.target.value);
                      }}
                      placeholder="Enter UPTO amount"
                      type="number"
                    /> </FormGroup>
                  </Col>
                ) : null}
                <Col md={3} className="mt-2"><FormGroup>

                  <p className="-Formlable">Discount Duration</p>
                  <Tooltip
                    placement="right"
                    isOpen={tooltipOpen3}
                    target="discountduration"
                    toggle={() => setTooltipOpen3(!tooltipOpen3)}
                  >
Discount Duration                  </Tooltip>

                  <Input
                  id="discountduration"
                  required
                    value={discountduration}
                    type="select"
                    onChange={(e) => {
                      setdiscountduration(e.target.value);
                    }}
                    placeholder="Enter Discount Duration"
                  ><option value="">Select Type </option>
                    <option value="once" title="user can use only once">Once</option>
                    <option value="forever" title="can be used forever">Forever</option>
                    <option value="repeating" title="repeats periodical in mounts">Repeating Monthly</option>
                    <option value="repeating_daily" title="repeats daily ">Repeating Daily</option>

                  </Input> </FormGroup>
                </Col>
                {discountduration === "repeating" ? (
                  <Col md={3} className="mt-2"><FormGroup>

                    <p className="-Formlable">Discount Duration(in Mounths)</p>

                    <Input
                    required
                      value={DiscountDurationInMounth}
                      onChange={(e) => {
                        setDiscountDurationInMounth(e.target.value);
                      }}
                      placeholder="Enter Discount Duration"
                      type="number"
                    /> </FormGroup>
                  </Col>
                ) : null}
{discountduration === "repeating_daily" ? (
                  <Col md={3} className="mt-2"><FormGroup>

                    <p className="-Formlable">Discount Duration Days</p>
                    <Input
                    required
                      value={Duration_in_days}
                      onChange={(e) => {
                        setDuration_in_days(e.target.value);
                      }}
                      placeholder="Enter number of times to repeated"
                      type="number"
                    /> </FormGroup>
                  </Col>
                ) : null}
                <Col md={3} className="mt-2"><FormGroup>

                  <p className="-Formlable">Max Redemption Per User</p>

                  <Input
                  required
                    value={maxredemptionperuser}
                    onChange={(e) => {
                      setmaxredemptionperuser(e.target.value);
                    }}
                    placeholder="Enter Max Redemption Per User"
                    type="number"
                  /> </FormGroup>
                </Col>

                <Col md={3} className="mt-2"><FormGroup>

                  <p className="-Formlable" id="maxRedemption">
                    Max Redemption
                  </p>
                  <Tooltip
                    placement="right"
                    isOpen={tooltipOpen2}
                    target="maxRedemption"
                    toggle={() => setTooltipOpen2(!tooltipOpen2)}
                  >
                    Maximum number of times this coupon can be redeemed, in
                    Total
                  </Tooltip>
                  <Input
                  required
                    value={maxRedemption}
                    onChange={(e) => {
                      setmaxRedemption(e.target.value);
                    }}
                    placeholder="Enter Max Redemption"
                    type="number"
                  /> </FormGroup>
                </Col>
                <Col md={12} className="mt-2"><FormGroup>

                  <p className="-Formlable">Coupon Descrition</p>

                  <Input
                  required
                    value={coupondescription}
                    onChange={(e) => {
                      setcoupondescription(e.target.value);
                    }}
                    placeholder="Enter Coupon Descrition"
                    type="textarea"
                  /> </FormGroup>
                </Col>
                <Col md={3} className="mt-2"><FormGroup>

                  <p className="-Formlable" id="redeem_by">
                  Valid Till               </p>
                  <Tooltip
                    placement="right"
                    isOpen={tooltipOpen}
                    target="redeem_by"
                    toggle={toggle}
                  >
                    Date after which the coupon can no longer be redeemed{" "}
                  </Tooltip>
                  <Input
                  required
                    value={redeem_by}
                    onChange={(e) => {
                      setredeem_by(e.target.value);
                    }}
                    placeholder="Enter date"
                    type="date"
                  /> </FormGroup>
                </Col>
                {/* <Col md={12}>
                  <Row>
                    <Col md={12}>
                      {" "}
                      <h5>Applies when days</h5>
                    </Col>
                    <Col sm={12}>
                      <FormGroup check className="pull-left">
                        <Label check>
                          <Input
                            type="checkbox"
                            value={sunday}
                            onChange={(e) => {
                              setsunday(e.target.checked);
                            }}
                          />
                          <span className="form-check-sign" />
                          Sunday
                        </Label>
                      </FormGroup>
              
                      {sunday ? (
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="1">From</Label>
                              <Input
                                type="time"
                                name="times1"
                                id="1"
                                placeholder="Time"
                                onChange={(e) => {
                                  console.log(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label for="2">To</Label>
                              <Input
                                type="time"
                                name="times2"
                                id="2"
                                placeholder="Time"
                                onChange={(e) => {
                                  console.log(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col> <Button className="pt-2"  onClick={()=>{}}>Add</Button></Col>
                          <Col> - </Col>
                        </Row>
                      ) : null}
                    </Col>
                    <Col sm={12}>
                      <FormGroup check className="pull-left">
                        <Label check>
                          <Input
                            type="checkbox"
                            value={sunday}
                            onChange={(e) => {
                              setsunday(e.target.checked);
                            }}
                          />
                          <span className="form-check-sign" />
                          monday
                        </Label>
                      </FormGroup>
              
                      {sunday ? (
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="1">From</Label>
                              <Input
                                type="time"
                                name="times1"
                                id="1"
                                placeholder="Time"
                                onChange={(e) => {
                                  console.log(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label for="2">To</Label>
                              <Input
                                type="time"
                                name="times2"
                                id="2"
                                placeholder="Time"
                                onChange={(e) => {
                                  console.log(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : null}
                    </Col>
                 
                  </Row>
                </Col>
              */}
             </Row> 
           
          <Row>
            <Col md={6}>
              <Button  className="w-75" disabled={isDisabled}  id='submit' type="submit">
                Submit
              </Button>
            </Col>
            <Col md={6}>
              <Button
                className="cancelButton w-75"
                onClick={() => history.goBack()}
              >
                Back
              </Button>
            </Col>
          </Row>
          </Form>

        </Card>
      </div>
    </>
  );
}

export default AddCityRateCard;
