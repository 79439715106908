/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";

// reactstrap components
import { Row, Col ,Input} from "reactstrap";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isselected:false
    };
  }

  fileuplode=(e, setdata, id) =>{
    e.preventDefault();
    const file = e.target.files[0];
    ///driver_info/
    let reader = new FileReader();
    reader.onloadend = () => {
      document.getElementById(id).style.backgroundImage =
        "url(" + reader.result + ")";
        this.setState({ [setdata]: file ,isselected:true});
      };
    reader.readAsDataURL(file);
  }
  render() {
    return (
      <>
        <h5 className="info-text">Choose The Appropriate Image</h5>
        <Row className="justify-content-center">
          <Col ></Col>
          <Col  className="justify-content-center">
          <div className="ghor " id="imagenote">
                      {" "}
                    </div>
                    <Input required 
                      className="w-50"
                      onChange={(e) => {
                        this.fileuplode(e, 'profileimg', "imagenote");
                      }}
                      type="file"
                    />       
           
          </Col>
          <Col></Col>
        </Row>
      </>
    );
  }
}

export default Wizard;
