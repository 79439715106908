import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Spinner,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardTitle,
  CardText,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from "reactstrap";
import { addCanceledRidesData } from "../datachane/actions";
import { useSelector, useDispatch } from "react-redux";
// core components
import ReactTable from "../../components/ReactTable/Completedridetable";
function AllRiders(props) {
  const dd = useSelector((state) => state.CancelledRides);
  const cities = useSelector((state) => state.Ratecarddata.asasa);

  const dispatch = useDispatch();
  const [nextkey, setnextkey] = useState(dd.nextKey || "");
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false);
  const [modal, setModal] = useState(false);
  //data date
  const [fromdate, setfromdate] = useState(new Date());
  const [todate, settodate] = useState(new Date());
  const [isDisabled, setisDisabled] = useState(false);
  const [selectCity, setselectCity] = useState();
  const [searchparam, setsearchparam] = useState("");
  //dataexchange
  const [arrayData, setarrayData] = useState(dd.arr);
  const [isDisabledsearch, setisDisabledsearch] = useState(false);
  //modal toggl
  const toggle = () => {
    setModal(!modal);
  };
  const handleSubmit1 = (event) => {
    event.preventDefault();
    setisDisabled(true);
    var todateseconds = new Date(todate).getTime() / 1000;
    var fromdateseconds = new Date(fromdate).getTime() / 1000;
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `https://us-central1-africar-9309f.cloudfunctions.net/cancelled_rides?from=${fromdateseconds}&to=${todateseconds}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          fileJson(result.data, "Completedrides", true);
          setisDisabled(false);
        } else {
          alert(result.message);
        }
      })
      .catch((error) => {
        setmessagttouser("Unable to get data....ERROR");
        console.log("error", error);
      });
  };
  const fileJson = async (JSONData, ReportTitle, ShowLabel) => {
    var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
    var headings = [
      "ARN",
      "booked_on",
      "accepted_on",
      "rider_name",
      "driver_name",
      "registration_number",
      "pickup_str",
      "drop_str",
      "type",
      "required_seats",
      "verified_on",
      "completed_on",
      "distance_str",
      "payment_method",
      "rate_card.base_fare",
      "rate_card",
      "distance_str",
      "coupon",
      "rate_card.tax",
      "ride_cost",
      "africar_net_gain",
      "driver_commission",
      "africar_gain",
      "ratings",
      "coupon_used",
    ];
    var CSV = "";
    var datasest = [];
    datasest = arrData.map((data) => {
      data.rider_name = data.rider_lname + " " + data.rider_fname;
      const header = Object.keys(data);
      var rt = data.rate_card;
      var coupon = data.coupon_used;
      var cstate = data.coupon;
      delete data.rate_card;
      var ss = data.drop_str ? data.drop_str : ["Undefined"];
      var dd = data.type;
      data.ride_type = dd;
      data.drop_str = ss[0];
      ss = data.pickup_str ? data.pickup_str : ["Undefined"];
      data.pickup_str = ss[0];
      header.map((dddd) => {
        if (!headings.includes(dddd)) {
          delete data[dddd];
        }
      });
      var con,
        aon,
        bon,
        von = "";
      // con = new Date(data.completed_on * 1000).toLocaleString("en-US", {
      //   day: "numeric",
      //   month: "numeric",
      //   year: "numeric",
      //   hour: "numeric",
      //   minute: "numeric",
      //   hour12: true,
      // });
      if (data.accepted_on) {
        aon = new Date(data.accepted_on).toLocaleString("en-US", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      }
      if (data.booked_on) {
        bon = new Date(data.booked_on * 1000).toLocaleString("en-US", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      }
      if (data.verified_on) {
        von = new Date(data.verified_on * 1000).toLocaleString("en-US", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      }
      if (cstate) {
        data.coupon_name = coupon.id;
        data.coupon_discount = coupon.discount;
      } else {
        data.coupon_name = "";
        data.coupon_discount = "";
      }
      return {
        ARN: data.ARN,
        "Booking Time": bon,
        "Accepted Time": aon,
        "Rider name": data.rider_name,
        "Driver name": data.driver_name,
        "Registration number": data.registration_number,
        "Pickup location": data.pickup_str,
        "Drop location": data.drop_str,
        "Ride Type": data.type,
        "Required seats": data.required_seats,
        "Actual Pickup Time": von,
        "Actual Drop Time": con,
        "Distance Travelled": data.distance_str,
        "Payment Method": data.payment_method,
        "Base Fare": rt.base_fare,
        "Distance Fare": rt.distance_fare,
        "Time Fare": rt.time_fare,
        VAT: rt.tax,
        "Ride Cost": data.ride_cost,
        "Africar Net Gain": data.africar_net_gain,
        "Driver Commission": data.driver_commission,
        Coupon: data.coupon,
        "Coupon Name": data.coupon_name,
        "Coupon Discount": data.coupon_discount,
        "Rider Ratings": data.ratings,
        "Driver Ratings": "0",
      };
    });

    const items = datasest;
    const replacer = (key, value) => (value === null ? " " : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    CSV = [
      header.join(","), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ].join("\r\n");
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    //Generate a file name
    var fileName = "Report_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setisDisabled(false);
  };
  //runes after opens page
  useEffect(() => {
    if (dd.arr.length === 0) {
      calldataall_riders("");
    } else {
      setarrayData(dd.arr);
    }
  }, [dd.arr]);
  async function calldataall_ridersRepeat(e) {
    var d = e;
    var da = "";
    if (dd.nextKey !== "") {
      da = dd.nextKey;
    } else {
      da = nextkey;
    }
    while (d !== 0) {
      d--;
      da = await calldataall_riders(da);
    }
  }
  ///function call search
  async function handleSubmitSearch(event) {
    event.preventDefault();
    setisDisabledsearch(true);
    setspinnershow(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `https://us-central1-africar-9309f.cloudfunctions.net/cancelled_rides?ARN=` +
        searchparam,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status === true) {
          setarrayData(result.data);
          setspinnershow(false);
        } else {
          setspinnershow(false);

          alert(result.message);
        }
      })
      .catch((error) => {
        setmessagttouser("Unable to get data....ERROR");
        console.log("error", error);
      });
  }
  //function call api
  async function calldataall_riders(nexttokenparam) {
    setnexttoggle(false);
    setspinnershow(true);
    if (nexttokenparam === undefined) {
      nexttokenparam = dd.nextKey || nextkey;
    }
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    var resultfetch = {};

    await fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/cancelled_rides?start=" +
        nexttokenparam,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        resultfetch = result;
      })
      .catch((error) => {
        setnexttoggle(false);
        setmessagttouser("Unable to get data....ERROR");
        console.log("error", error);
      });
    //
    await setnextkey(resultfetch.start);

    resultfetch.data.map((pro) => {
      if (pro.coupon_used !== undefined) {
        pro.coupon_name = pro.coupon_used.id;
      }
      pro.rider_fname = pro.rider_fname + " " + pro.rider_lname;
      let time = new Date().toLocaleString("en-US", {
        day: "numeric",

        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      if (pro.coupon === true) {
        pro.coupon = "Yes";
      } else {
        pro.coupon = "No";
      }

      if (pro.accepted_on) {
        pro.accepted_on = new Date(pro.accepted_on).toLocaleString("en-US", {
          day: "numeric",

          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      }
      if (pro.booked_on) {
        pro.booked_on = new Date(pro.booked_on).toLocaleString("en-US", {
          day: "numeric",

          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      }
      if (pro.verified_on) {
        pro.verified_on = new Date(pro.verified_on).toLocaleString("en-US", {
          day: "numeric",

          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      }
    });
    //
    dispatch(addCanceledRidesData(resultfetch.data, resultfetch.start,resultfetch.count));
    setnexttoggle(true);
    setspinnershow(false);
    return resultfetch.start;
  }
  if (dd.arr.length === 0) {
    return (
      <>
        <div className="content">
          <div className="w-100">
            <h5 style={{ display: "inline-block" }}>Cancelled Rides </h5>
            <p>{messagttouser}</p>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="content ">
        <div className="w-100" style={{ display: "inline-block" }}>
          <h5 style={{ display: "inline-block" }}>Cancelled Rides </h5>
          {"    "}
          {sipnnershow && (
            <div style={{ display: "inline-block" }}>
              Loading data... <Spinner size="sm" color="primary" />
            </div>
          )}
          <hr className="m-0" />
          <Row className="m-0">
            <Col md={5}>
              <Form onSubmit={handleSubmitSearch}>
                <FormGroup style={{ display: "inline-flex" }}>
                  <Input
                    required
                    placeholder="Enter ARN Id"
                    type="text"
                    value={searchparam}
                    onChange={(e) => {
                      setsearchparam(e.target.value);
                    }}
                  />
                </FormGroup>{" "}
                {!isDisabledsearch ? (
                  <Button disabled={isDisabledsearch} id="search" type="submit">
                    search
                  </Button>
                ) : null}
                {arrayData.length === 1 ? (
                  <Button
                    onClick={() => {
                      setsearchparam("");

                      setisDisabledsearch(false);
                      setarrayData(dd.arr);
                    }}
                  >
                    Clear
                  </Button>
                ) : null}
              </Form>
            </Col>
            <Col md={3} className=" pt-3 ">
              {cities.length !== 0 ? (
                <Input
                  required
                  value={selectCity}
                  onChange={(e) => {
                    setselectCity(e.target.value);
                  }}
                  placeholder="Enter vehicle city"
                  type="select"
                >
                  <option value="">All city </option>
                  {cities.map((data, i) => {
                    return (
                      <option value={data} key={i}>
                        {data}
                      </option>
                    );
                  })}
                </Input>
              ) : null}
            </Col>
            <Col md={4} className="d-flex justify-content-end">
              <Button
                onClick={() => {
                  toggle();
                }}
              >
                Download Ride Data
              </Button>
            </Col>
          </Row>
          <hr className="m-0" />
        </div>
        <br />
        <ReactTable
          calldata={calldataall_riders}
          countforcall={calldataall_ridersRepeat}
          data={arrayData}
          isnext={nexttoggle}
          ageOutside={selectCity}
          counts={dd.TotalCount}

          hidecolumnsdata={[
            "coupon",
            "verified_on",
            "completed_on",
            "type",
            "required_seats",
            "coupon",
            "rate_card.tax",
            "ride_cost",
            "africar_net_gain",
            "driver_commission",
            "africar_gain",
            "vendoxr",
            "ratings",
            "couxpon",
            "payment_method",
            "rate_card.base_fare",
            "rate_card.distance_fare",
            "rate_card.time_fare",
            "distance_str",
            "city",
            "coupon_name",
          ]}
          columns={[
            {
              Header: "ARN number   ",
              accessor: "ARN",
            },
            {
              Header: "Booking time",
              accessor: "booked_on",
            },
            {
              Header: "Accepted Time",
              accessor: "accepted_on",
            },
            {
              Header: "rider name",
              accessor: "rider_fname",
            },
            {
              Header: "Driver name",
              accessor: "driver_name",
            },
            {
              Header: "registration number",
              accessor: "registration_number",
            },
            {
              Header: "pickup location",
              accessor: "pickup_str",
            },
            {
              Header: "drop location",
              accessor: "drop_str",
            },
            {
              Header: "Type of Booking",
              accessor: "type",
            },
            {
              Header: "Seats-1/2",
              accessor: "required_seats",
            },

            {
              Header: "Total Distance ",
              accessor: "distance_str",
            },
            {
              Header: "payment method",
              accessor: "payment_method",
            },
            {
              Header: "base fare",
              accessor: "rate_card.base_fare",
            },
            {
              Header: "Distance Fare",
              accessor: "rate_card.distance_fare",
            },
            {
              Header: "Time Fare",
              accessor: "rate_card.time_fare",
            },

            {
              Header: "VAT",
              accessor: "rate_card.tax",
            },
            {
              Header: "Total Fare",
              accessor: "ride_cost",
            },
            {
              Header: "Commissions",
              accessor: "africar_net_gain",
            },
            //
            {
              Header: "Driver earnings",
              accessor: "driver_commission",
            },
            {
              Header: "coupon charges",
              accessor: "coupon",
            },
            {
              Header: "Coupon Name",
              accessor: "coupon_name",
            },
            {
              Header: "Rider Ratings",
              accessor: "vendoxr",
            },

            {
              Header: "Driver Ratings",
              accessor: "ratings",
            },
            {
              Header: "City",
              accessor: "city",
            },
          ]}
          /*
            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
          */
          className="-striped -highlight primary-pagination"
        />
        <Modal size="lg" className="w-100" isOpen={modal} toggle={toggle}>
          <ModalHeader>Ride Details </ModalHeader>
          <ModalBody>
            <div>
              <div className="">
                <Form onSubmit={handleSubmit1} className="w-100">
                  <p>
                    Please Select the Ride time line. {"   "}Ride Data will be
                    Download in CSV File...
                  </p>

                  {isDisabled && (
                    <div style={{ display: "inline-block" }}>
                      Processing data... <Spinner size="sm" color="primary" />
                    </div>
                  )}

                  <Row>
                    <Col md={6} className="mt-2">
                      <FormGroup>
                        <p className="-Formlable" id="fromdate">
                          From Date{" "}
                        </p>
                        <Input
                          required
                          value={fromdate}
                          onChange={(e) => {
                            setfromdate(e.target.value);
                          }}
                          type="date"
                        />{" "}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-2">
                      <FormGroup>
                        <p className="-Formlable" id="todate">
                          To Date{" "}
                        </p>
                        <Input
                          required
                          value={todate}
                          onChange={(e) => {
                            settodate(e.target.value);
                          }}
                          type="date"
                        />{" "}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <Button
                        className="w-75"
                        disabled={isDisabled}
                        id="submit"
                        type="submit"
                      >
                        Download CSV File
                      </Button>
                    </Col>
                    <Col md={6}>
                      <Button
                        className="cancelButton w-75"
                        onClick={() => {
                          setModal(!modal);
                        }}
                      >
                        Close
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

export default AllRiders;
