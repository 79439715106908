/*

*/
export const setloding = () => {
  return {
    type: "SETLOADING",
  };
};
/*.....permissions room....*/

export const setpermissions = (DATA) => {
  return {
    type: "permissions",
    payload: DATA,
  };
};
export const Logoutreset = () => {
  return {
    type: "USER_LOGGED_OUT",
  };
};

/*.....war room....*/

export const addwarroomData = (data) => {
  return {
    type: "ADD_WAR_ROOM_DATA",
    payload: data,
  };
};
export const addwarroomCity = (data) => {
  return {
    type: "ADD_WAR_ROOM_CITY",
    payload: data,
  };
};
/*.....All Riders....*/

export const addall_ridersData = (data, key, count) => {
  return {
    type: "ADD_RIDERS",
    payload: data,
    keynext: key,
    counts: count ? count : "0",
  };
};

export const addDatalocation = (data, lenth) => {
  return {
    type: "ADD_LOC",
    payload: data,
    length: lenth,
  };
};

/*all vendores*/
export const addVendoresData = (data) => {
  return {
    type: "ADD_VENDORS",
    payload: data,
  };
};

/*all drivers*/
export const addDriversData = (data, key) => {
  return {
    type: "ADD_DRIVERS",
    payload: data,
    nextKey: key,
  };
};
/*all vehicals*/
export const addvehicalsData = (data, key) => {
  return {
    type: "ADD_VEHICLES",
    payload: data,
    nextKey: key,
  };
};
/*all ratecard*/
export const addratecardData = (data, ddr) => {
  return {
    type: "ADD_RATE_CARD",
    payload: data,
    cities: ddr,
  };
};
/*all completed*/
export const addCompletedRidesData = (data, key, count) => {
  return {
    type: "ADD_COMPLETED_DATA",
    payload: data,
    keynext: key,
    counts: count ? count : "0",
  };
};
/*all completed*/
export const addCanceledRidesData = (data, key, count) => {
  return {
    type: "ADD_CANCELLED_DATA",
    payload: data,
    keynext: key,
    counts: count ? count : "0",
  };
};
/*all completed*/
export const addPendingRidesData = (data, key, count) => {
  return {
    type: "ADD_PENDING_DATA",
    payload: data,
    keynext: key,
    counts: count ? count : "0",
  };
};
/*all promo code*/
export const addPromocode = (data, key) => {
  return {
    type: "ADD_PROMOCODEDATA",
    payload: data,
    keynext: key,
  };
};
/* promo edit data*/
export const addEditPromocode = (data) => {
  return {
    type: "ADD_edit_PROMOCODE_Data",
    payload: data,
  };
}; /*remove edit data*/
export const removeEditPromocode = () => {
  return {
    type: "CLEAN_STATE_PROMOCODE",
  };
};
/*add user permition, used in create user permitions*/
export const adduserpermition = (data) => {
  return {
    type: "ADD_USER_PERMISSIONS",
    payload: data,
  };
};
export const removeuserpermition = (data) => {
  return {
    type: "DELETE_USER_PERMISSIONS",
    payload: data,
  };
};
export const Clearuserpermition = () => {
  return {
    type: "CLEAN_STATE",
  };
};
///permitiomn
export const addpermition = (data) => {
  return {
    type: "ADD_user_DATA",
    payload: data,
  };
};
//apiuserp///permitiomn
export const addapipermition = (data) => {
  return {
    type: "USER_PERMISSIONS",
    payload: data,
  };
};
//ongoingrides///permitiomn
export const addOngoingRidesData = (data) => {
  return {
    type: "ON_GOING_RIDES",
    payload: data,
  };
};
export const SETadminlodingtrue = () => {
  return {
    type: "SETadminloding",
  };
};
/*all driver analytics data*/
export const adddriver_statsData = (data, key, count) => {
  if (count.total !== undefined) {
    return {
      type: "ADD_DRIVER_ANALYTICS_DATA_FIRST",
      payload: data,
      keynext: key,
      totalcount: count,
    };
  } else {
    return {
      type: "ADD_DRIVER_ANALYTICS_DATA",
      payload: data,
      keynext: key,
    };
  }
};
/*all unlinked vehicles*/
export const adddunlinkedvehicleData = (data, key) => {
  return {
    type: "ADD_UNLINKED_VEHICLE",
    payload: data,
    keynext: key,
  };
};
/*all unlinked dro vehicles*/
export const adddunlinkedvehicledroData = (data, key) => {
  return {
    type: "ADD_UNLINKED_DRO_VEHICLE",
    payload: data,
    keynext: key,
  };
};
/*all CASH Collection*/
export const addCASHCollectionData = (data, key) => {
  return {
    type: "ADD_CASH_COLLECTION_DATA",
    payload: data,
    keynext: key,
  };
};
export const removeCASHCollectionData = (data) => {
  return {
    type: "REMOVE_CASH_COLLECTION_DATA",
    payload: data,
  };
};
///test
// export const addurl =  (data)=> {
//     return {
//         type: 'ADD_urlDATA',
//         payload: data,
//     }
// }
export const searchdata = (data) => {
  return {
    type: "ADD_SEARCH",
    payload: data,
  };
};

//Drounlinkedvehicleds
export const Drounlinkeddata = (data) => {
  return {
    type: "ON_DRO_VEHICLES",
    payload: data,
  };
};
//sim data
export const AddSimdata = (data) => {
  return {
    type: "ADD_SIM_DATA",
    payload: data,
  };
};
//mobile data
export const AddMobileddata = (data) => {
  return {
    type: "ADD_Mobile_DATA",
    payload: data,
  };
};
/*all Referral*/
export const addReferalData = (data, key) => {
  return {
    type: "ADD_REFERRAL",
    payload: data,
    nextKey: key,
  };
};
/// Accounts -----  Collection --------
export const addCollectionDrivers = (data, key) => {
  return {
    type: "ADD_COLLECTION_DRIVER_DATA",
    payload: data,
    nextKey: key,
  };
};

export const addCollectionRides = (data, key) => {
  return {
    type: "ADD_COLLECTION_RIDES_DATA",
    payload: data,
    nextKey: key,
  };
};
export const addCollectionRidesSearch = (data,key) => {
  return {
    type: "ADD_COLLECTION_RIDES_SEARCH_DATA",
    payload: data,
    nextKey: key,
  };
};
export const addCollectionVehicle = (data, key) => {
  return {
    type: "ADD_COLLECTION_VEHICLE_DATA",
    payload: data,
    nextKey: key,
  };
};
/// Accounts -----  Revenue --------
export const addRevenueDrivers = (data, key) => {
  return {
    type: "ADD_REVENUE_DRIVER_DATA",
    payload: data,
    nextKey: key,
  };
};
export const addRevenueRides = (data, key, count) => {
  return {
    type: "ADD_REVENUE_RIDES_DATA",
    payload: data,
    nextKey: key,
    counts: count ? count : "0",
  };
};
export const addRevenueRidesSearch = (data,key, count) => {
  return {
    type: "ADD_REVENUE_RIDES_SEARCH_DATA",
    payload: data,
    nextKey: key,
    counts: count ? count : "0",
  };
};
export const addRevenueVehicle = (data, key) => {
  return {
    type: "ADD_REVENUE_VEHICLE_DATA",
    payload: data,
    nextKey: key,
  };
};
export const addRevenueSearch = (data,key ) => {
  return {
    type: "ADD_REVENUE_MORE_DATA",
    payload: data,
    nextKey: key,

  };
};
export const addRevenueSearchClean = ( ) => {
  return {
    type: "CLEAN",


  };
};
export const addRevenuedriverdetails = (data,key ) => {
  return {
    type: "ADD_REVENUE_DRIVER_DETAIL_DATA",
    payload: data,
    nextKey: key,

  };
};
export const addRevenuedriverdetailsSearch = (data, ) => {
  return {
    type: "ADD_REVENUE_DRIVER_DETAI_SEARCH_DATA",
    payload: data,

  };
};
export const addRevenuedriverdetailsClean = ( ) => {
  return {
    type: "CLEAN",
   

  };
};
//VEHICLE DETAIL
export const addRevenueVehicleDetails = (data,key ) => {
  return {
    type: "ADD_REVENUE_VEHICLE_DETAIL_DATA",
    payload: data,
    nextKey: key,

  };
};
export const addRevenueVehicleDetailsSearch = (data, ) => {
  return {
    type: "ADD_REVENUE_VEHICLE_DETAIL_SEARCH_DATA",
    payload: data,

  };
};
export const addRevenuedVehicleDetailsClean = ( ) => {
  return {
    type: "CLEAN",
   

  };
};
export const addOutstandingDetails = (data,key ) => {
  return {
    type: "ADD_OUTSTANDING_DATA",
    payload: data,
    nextKey: key,

  };
};