/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create a form with multiple steps
import ReactWizard from "react-bootstrap-wizard";

// reactstrap components
import { Col } from "reactstrap";

// wizard steps
import Step1 from "./screens/Step1";
import Step2 from "./screens/Step2";

var steps = [
  {
    stepName: "About",
    stepIcon: "fa fa-address-card-o",
    component: Step1,
  },
  {
    stepName: "Image",
    stepIcon: "fa fa-picture-o",
    component: Step2,
  },
];

class Wizard extends React.Component {
  async finishButtonClick(allStates) {
    if (allStates.Image.isselected && allStates.About.filled) {
      console.log(allStates);
     // const urldata = await FileUplodeToFireStore(allStates.Image.profileimg);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer "+localStorage.getItem('token'));
      myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          topic:allStates.About.topic,
          title:allStates.About.title,
          body:allStates.About.body,
          image:'urldata',
        });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

      // fetch(
      //   "https://us-central1-africar-9309f.cloudfunctions.net/push_notification",
      //   requestOptions
      // )
      //   .then((response) => response.text())
      //   .then((result) => alert("Notification will be sent to the Riders."))
      //   .catch((error) => console.log("error", error));
    }
  }

  async FileUplodeToFireStore(e) {
    // const file_data = e;
    // const storage_ref = fire_storage.ref();
    // const fileref = storage_ref.child('driver_info/'+file_data.name).put(file_data);
    // return await fileref.snapshot.ref.getDownloadURL().then((downloadURL) => {
    //   return downloadURL;
    // });
  }
  render() {
    return (
      <>
        <div className="content">
          <Col className="mr-auto ml-auto" md="10">
            <ReactWizard
              steps={steps}
              navSteps
              validate
              title="Send Notifications"
              description="This information will sent to both IOS and Android Users."
              headerTextCenter
              finishButtonClasses="btn-wd"
              nextButtonClasses="btn-wd"
              previousButtonClasses="btn-wd"
              finishButtonClick={this.finishButtonClick}
            />
          </Col>
        </div>
      </>
    );
  }
}

export default Wizard;
