import AddPromoCode from "./views/promocode/AddPromoCode";
import updatepromocode from "./views/promocode/updatepromocode";
import AddCityRateCard from "./views/CityRateCard/AddCityRateCard";
import Updateratecard from "./views/CityRateCard/Updateratecard";

import DriverDetails from "./views/Revenue/DriverDetails";
import Vehicledetails from "./views/Revenue/vehicledetails";

const routessub = [
    {
      path: "/AddCityRateCard",
      component: AddCityRateCard,
      layout: "/admin",
      name: "Add City Rate Card",

    },
    {
      path: "/UpdateCityRateCard",
      component: Updateratecard,
      layout: "/admin",
      name: "update rate card",

    },
  
    {
      path: "/AddPromoCode",
      component: AddPromoCode,
      layout: "/admin",
      name: "Add Promo Code",

    },
    {
      path: "/RevenuDriverdetails",
      component: DriverDetails,
      layout: "/admin",
      name: "updatepromocode",

    },
    {
      path: "/RevenuVehicledetails",
      component: Vehicledetails,
      layout: "/admin",
      name: "updatepromocode",

    },
    {
      path: "/UpdatePromoCode",
      component: updatepromocode,
      layout: "/admin",
      name: "updatepromocode",

    },
  
   
]
export default routessub;
