import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  FormGroup,
  Input,
  Row,
  Col,
  Form,
  Progress,
} from "reactstrap";
// import { addDriversData } from "../datachane/actions";
import { useSelector } from "react-redux";
import { fire_storage } from "../../components/firebase";
import { useHistory } from "react-router-dom";

function AddDrivers() {
  const isloading = useSelector((state) => state.Isload);
  let cities = useSelector((state) => state.Ratecarddata);
  var initialState = "";

  let history = useHistory();
  ///display proress
  const [uplodedisplay, setuplodedisplay] = useState(false);
  const [uplode2, setuplode2] = useState({ label: "", value: 0 });
  const [uplode3, setuplode3] = useState({ label: "", value: 0 });
  const [uplode1, setuplode1] = useState({ label: "", value: 0 });

  const [alertdata, setalertdata] = useState(null);
  const [fname, setfname] = useState(initialState);
  const [lname, setlname] = useState(initialState);
  const [commission, setcommission] = useState(0);
  const [dob, setdob] = useState(initialState);
  const [gender, setgender] = useState(initialState);
  const [driverprofilepic, setdriverprofilepic] = useState(initialState);
  const [contactno, setcontactno] = useState(initialState);
  const [emailid, setemailid] = useState(initialState);
  const [city, setcity] = useState(initialState);
  const [joindate, setjoindate] = useState(initialState);
  const [password, setpassword] = useState(initialState);
  const [licensevalidity, setlicensevalidity] = useState(initialState);
  const [license_back_pic, setlicense_back_pic] = useState(initialState);
  const [license_front_pic, setlicense_front_pic] = useState(initialState);
  const [licenseno, setlicenseno] = useState(initialState);
  const [lasdricard, setlasdricard] = useState(initialState);
  const [lasdrivalidity, setlasdrivalidity] = useState(initialState);
  const [police_verification, setpolice_verification] = useState(initialState);
  const [document_status, setdocument_status] = useState(initialState);
  const [police_verificationdoc, setpolice_verificationdoc] =
    useState(initialState);
  const [MaxDueAmount, setMaxDueAmount] = useState(20000);
  async function FileUplodeToFireStore(e, Name, upoldevalue) {
    //     upoldevalue({ label: Name, value: 50 });
    //     const file = e;
    // const storage_ref = fire_storage.ref();
    // var regex = new RegExp('[^.]+$');
    // const metadata = {
    // contentType: file.type
    // };
    //   var extension = file_data.name.match(regex);
    // const names=localStorage.getItem('user_id')+Name+'.'+extension
    // const fileref = storage_ref.child('driver_info/'+names).put(file);
    // return await fileref.snapshot.ref.getDownloadURL().then((downloadURL) => {
    // upoldevalue({label:Name,value:100});
    // return downloadURL;
    // });
  }
  const datesecond = (e) => {
    return new Date(e).getTime() / 1000;
  };
  async function handleSubmit(event) {
    event.preventDefault();
    if (localStorage.getItem("token") !== undefined) {
      document.getElementById("submit").setAttribute("disabled", "disabled");
      setuplodedisplay(true);
      // setuplode1({ label: 'profilepic', value: 25 });
      // const result1 = await FileUplodeToFireStore(driverprofilepic,"profilepic",setuplode1);

      // const result2 = await FileUplodeToFireStore(license_front_pic, "license_front_pic",
      // setuplode2);

      // const result3 = await FileUplodeToFireStore(license_back_pic,"license_back_pic",
      // setuplode3);

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        fname: fname,
        lname: lname,
        dob: datesecond(dob),
        gender: gender,
        driver_profile_pic: driverprofilepic,
        contactno: contactno,
        emailid: emailid,
        password: password,
        max_due:Number(MaxDueAmount),
        commission:Number(commission),
        city: city,
        joindate: datesecond(joindate),
        license_front_pic: license_front_pic,
        license_back_pic: license_back_pic,
        licenseno: licenseno,
        licensevalidity: datesecond(licensevalidity),
        lasdricard: lasdricard,
        lasdrivalidity: datesecond(lasdrivalidity),
        police_verification: police_verification,
        police_verification_pic: police_verificationdoc,
        document_status: document_status,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
console.log(JSON.parse(raw));
      fetch(
        "https://us-central1-africar-9309f.cloudfunctions.net/all_drivers",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.status === true) {
            alert("Driver added Successfully");
            document.getElementById("submit").removeAttribute("disabled");
          } else {
            alert("Error", result.message);
            document.getElementById("submit").removeAttribute("disabled");
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      alert("Token error");
    }
  }

  async function fileuplode(e, setdata, id, upoldevalue) {
    e.preventDefault();
    if (e.target.files[0] != undefined) {
      const storage_ref = fire_storage.ref();
      const file = e.target.files[0];
      const metadata = {
        contentType: file.type,
      };
      var timese = Math.floor(new Date().getTime() / 1000);

      const fileref = await storage_ref
        .child("driver_info/" + timese + file.name)
        .put(file, metadata)
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then((url) => {
          upoldevalue({ label: "file", value: 100 });
          setdata(url);
        })
        .catch(console.error);
      let reader = new FileReader();
      reader.onloadend = () => {
        document.getElementById(id).style.backgroundImage =
          "url(" + reader.result + ")";
      };
      reader.readAsDataURL(file);
    }
  }

  if (isloading) {
    return (
      <>
        <div className="content">
          <div className="w-100 ">
            <h5 style={{ display: "inline-block" }}>loading data...</h5>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="content w-100">
        <div className="w-100" style={{ display: "inline-block" }}>
          <h5>Add New Driver</h5>
          <h5>{alertdata}</h5>
        </div>
        <Card className="m-2 p-2">
          <Form onSubmit={handleSubmit} className="w-100">
            <Row>
              <Col sm={12}>
                <hr className="m-1" />
                <h4 className="m-2">Personal Details</h4>
                <hr className="m-1" />
              </Col>
              <Col md={4} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">First Name</p>
                  <Input required
                     
                    placeholder="Enter first name"
                    type="text"
                    value={fname}
                    onChange={(e) => {
                      setfname(e.target.value);
                    }}
                  />
                </FormGroup>{" "}
              </Col>

              <Col md={4} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">Last Name</p>

                  <Input required
                     
                    placeholder="Enter last name"
                    type="text"
                    value={lname}
                    onChange={(e) => {
                      setlname(e.target.value);
                    }}
                  ></Input>
                </FormGroup>{" "}
              </Col>
              <Col md={4} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">Date of Birth</p>

                  <Input required
                     
                    value={dob}
                    onChange={(e) => {
                      setdob(e.target.value);
                    }}
                    type="date"
                  />
                </FormGroup>{" "}
              </Col>
              <Col md={4} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">Gender</p>

                  <Input required
                     
                    value={gender}
                    onChange={(e) => {
                      setgender(e.target.value);
                    }}
                    type="select"
                  >
                    <option value="">Select Gender</option>

                    <option value="Male">Male</option>
                    <option value="Female"> Female</option>
                    <option value="Do not wish to specify">
                      Do not wish to specify
                    </option>
                  </Input>
                </FormGroup>{" "}
              </Col>
              <Col md={4} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">Contact Number</p>

                  <Input required
                     
                    value={contactno}
                    onChange={(e) => {
                      setcontactno(e.target.value);
                    }}
                    placeholder="Enter Contact number"
                    type="text"
                  />
                </FormGroup>{" "}
              </Col>

              <Col md={3} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">city</p>

                  <Input required
                     
                    value={city}
                    onChange={(e) => {
                      setcity(e.target.value);
                    }}
                    placeholder=""
                    type="select"
                  >
                    <option value="">Select city </option>
                    {cities.asasa.map((data, i) => {
                      return (
                        <option value={data} key={i}>
                          {data}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>{" "}
              </Col>
              <Col sm={12}>
                <hr className="m-1" />
                <h4 className="m-2">Profile Details</h4>
                <hr className="mb-3" />
              </Col>

              <Col md={6}>
                <Row>
                  <Col sm={12}>
                    {" "}
                    <FormGroup>
                      <p className="Labelstyle">Email</p>

                      <Input required
                         
                        value={emailid}
                        onChange={(e) => {
                          setemailid(e.target.value);
                        }}
                        placeholder="Enter email id"
                        type="email"
                      ></Input>
                    </FormGroup>{" "}
                  </Col>

                  <Col sm={12}>
                    <FormGroup>
                      <p className="Labelstyle">Password</p>

                      <Input required
                         
                        value={password}
                        onChange={(e) => {
                          setpassword(e.target.value);
                        }}
                        placeholder="Enter password"
                        type="text"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col sm={12}>
                    <FormGroup>
                      <p className="Labelstyle">Join date</p>

                      <Input required
                         
                        value={joindate}
                        onChange={(e) => {
                          setjoindate(e.target.value);
                        }}
                        type="date"
                      />
                    </FormGroup>{" "}
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <p className="Labelstyle">
                        Maximun Due Amount (Naira &#8358;)
                      </p>

                      <Input required
                         
                        value={MaxDueAmount}
                        onChange={(e) => {
                          setMaxDueAmount(e.target.value);
                        }}
                        type="number"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <p className="Labelstyle">
                      Commission (%)
                      </p>

                      <Input required
                         
                        value={commission}
                        onChange={(e) => {
                          setcommission(e.target.value);
                        }}
                        type="number"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>

              <Col md={6}>
                <center>
                  {" "}
                  <FormGroup className="formst">
                    <p className="Labelstyle ">Driver profile picture</p>
                    <div className="ghor profileimg" id="profile">
                      {" "}
                    </div>
                    <Input required
                       
                      className="w-50"
                      onChange={(e) => {
                        fileuplode(
                          e,
                          setdriverprofilepic,
                          "profile",
                          setuplode1
                        );
                      }}
                      type="file"
                    />
                  </FormGroup>{" "}
                </center>{" "}
              </Col>
              <Col md={6} className="mt-2"></Col>

              <Col sm={12}>
                <hr className="m-1" />
                <h4 className="m-2">Document Details</h4>
                <hr className="m-1" />
              </Col>

              <Col md={6} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">LASDRI CARD (for lagos City)</p>

                  <Input 
                    value={lasdricard}
                    onChange={(e) => {
                      setlasdricard(e.target.value);
                    }}
                    placeholder="Enter LASDRI CARD"
                    type="text"
                  />
                </FormGroup>{" "}
              </Col>
              <Col md={6} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">
                    LASDRI CARD validity (for lagos City)
                  </p>

                  <Input 
                    value={lasdrivalidity}
                    onChange={(e) => {
                      setlasdrivalidity(e.target.value);
                    }}
                    type="date"
                  />
                </FormGroup>{" "}
              </Col>

              <Col md={6} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">Document status</p>

                  <Input required
                     
                    value={document_status}
                    onChange={(e) => {
                      setdocument_status(e.target.value);
                    }}
                    type="select"
                  >
                    {" "}
                    <option value="">Select Status</option>
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                    <option value="Rejected">Rejected</option>
                  </Input>
                </FormGroup>{" "}
              </Col>
              <Col md={6} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">police verification</p>

                  <Input required
                     
                    value={police_verification}
                    onChange={(e) => {
                      setpolice_verification(e.target.value);
                    }}
                    type="select"
                  >
                    <option value="">Select Status</option>

                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                    <option value="Rejected">Rejected</option>
                  </Input>
                </FormGroup>{" "}
              </Col>

              <Col md={6} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">license number</p>

                  <Input required
                     
                    value={licenseno}
                    onChange={(e) => {
                      setlicenseno(e.target.value);
                    }}
                    type="text"
                  />
                </FormGroup>{" "}
              </Col>

              <Col md={6} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">license validity</p>

                  <Input required
                     
                    value={licensevalidity}
                    onChange={(e) => {
                      setlicensevalidity(e.target.value);
                    }}
                    type="date"
                  />
                </FormGroup>{" "}
              </Col>
              <Col md={12} className="mt-2">
                <Row>
                  <Col md={4} className="mt-2">
                    <FormGroup>
                      <center>
                        <p className="Labelstyle">license front side picture</p>
                        <div className="ghor " id="lfsp">
                          {" "}
                        </div>

                        <Input required
                           
                          className="w-50"
                          onChange={(e) => {
                            fileuplode(
                              e,
                              setlicense_front_pic,
                              "lfsp",
                              setuplode2
                            );
                          }}
                          type="file"
                        />
                      </center>
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-2">
                    <FormGroup>
                      {" "}
                      <center>
                        <p className="Labelstyle">license back side picture</p>
                        <div className="ghor " id="lbsp">
                          {" "}
                        </div>
                        <Input required
                           
                          className="w-50"
                          onChange={(e) => {
                            fileuplode(
                              e,
                              setlicense_back_pic,
                              "lbsp",
                              setuplode3
                            );
                          }}
                          type="file"
                        />{" "}
                      </center>
                    </FormGroup>{" "}
                  </Col>
                  <Col md={4} className="mt-2">
                    <FormGroup>
                      <p className="Labelstyle">police verification Document</p>
                      <div className="ghor " id="pvd"></div>
                      <Input required
                         
                        onChange={(e) => {
                          fileuplode(
                            e,
                            setpolice_verificationdoc,
                            "pvd",
                            setuplode2
                          );
                        }}
                        type="file"
                      ></Input>
                    </FormGroup>{" "}
                  </Col>
                </Row>
              </Col>

              <Col md={6}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle"> </p>
                  <Button id="submit" type="submit">
                    Submit
                  </Button>
                </FormGroup>{" "}
              </Col>
              <Col md={6}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle"> </p>
                  <Button
                    className="cancelButton"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                </FormGroup>{" "}
              </Col>
            </Row>
          </Form>
          {uplodedisplay ? (
            <Card>
              <div>
                <div className="text-center">
                  Uploding {uplode1.label}
                  {"...  "}
                  {uplode1.value}%
                </div>
                <Progress value={uplode1.value} />
              </div>
              <div>
                <div className="text-center">
                  Uploding {uplode2.label}
                  {"...  "}
                  {uplode2.value}%
                </div>
                <Progress value={uplode2.value} />
              </div>
              <div>
                <div className="text-center">
                  Uploding {uplode2.label}
                  {"...  "}
                  {uplode2.value}%
                </div>
                <Progress value={uplode3.value} />
              </div>
            </Card>
          ) : null}
        </Card>
      </div>
    </>
  );
}

export default AddDrivers;
