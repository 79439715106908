
// const initailState = {
//     arr:[],
//     nextKey:''
// }
// const Revenuesearch=(state=initailState,action)=>{
//     switch(action.type){
//         case 'ADD_REVENUE_MORE_DATA':
//             return{ 
//                 arr: state.arr.concat(action.payload),
//                 nextKey:action.nextKey
//             }
//             case 'CLEAN':
//                 return initailState;
//         default:
//             return state;
//     }
// }

// export default Revenuesearch

const initailState = {
    arr: [],
  arrsearch: [],
  nextKey: "",
}
const AccountsRevenueVehicleDetail=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_REVENUE_VEHICLE_DETAIL_DATA':
            return{ 
                arr: state.arr.concat(action.payload),
                nextKey:action.nextKey
            }
            case "ADD_REVENUE_VEHICLE_DETAI_SEARCH_DATA":
                return { ...state, arrsearch: action.payload };
            case 'CLEAN':
                return initailState;
        default:
            return state;
    }
}

export default AccountsRevenueVehicleDetail