import React, { useContext, useState, useEffect } from "react";
import { auth } from "./firebase.js";
import { database } from "./firebase.js";
import { useSelector, useDispatch } from "react-redux";
import { dbfirestore } from "../components/firebase";

import {
  addDatalocation,
  addwarroomData,
  setloding,
  addratecardData,
  addOngoingRidesData,setpermissions,Logoutreset,addwarroomCity
} from "../views/datachane/actions";
import routes from "../routes";
const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  var ongoingrededriverid = [];
  const islodingforwarroom = useSelector((state) => state.Isload);

  const dispatch = useDispatch();
  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  async function lisen() {
    var starrider_queue = database.ref("rider_queue");
    starrider_queue.on("value", (snapshot) => {

      if (snapshot.val() !== null) {
        var dda = Object.entries(snapshot.val());
        var con = [];
        con = dda.map((data) => {
          data[1].booked_on = new Date(data[1].booked_on * 1000)
            .toISOString()
            .split("T")[0];
          if (
            data[1].isAccepted == false &&
            data[1].isCancelled === false &&
            data[1].isCompleted === false &&
            data[1].isVerified === false
          )
            data[1].status = "Incoming";
          if (data[1].isAccepted === true && data[1].isVerified === true)
            data[1].status = "reroute";
          if (data[1].isAccepted === true) data[1].status = "Accepted";
          if (data[1].isCancelled === true) data[1].status = "Cancelled";
          if (data[1].isPending === true) data[1].status = "Pending";
          data[1].id = data[0];

          if (data[1].driver_id !== undefined) {
            ongoingrededriverid[data[1].driver_id] = {
              driverid: data[1].driver_id,
              name: data[1].driver_name,
              status: data[1].status,
              type: data[1].type,
              seat_req: data[1].required_seats,
            };
          }
          return data[1];
        });
        dispatch(addOngoingRidesData(con));
      }
    });

/////test



/////
    var ratecard = database.ref("price_calculation");
    ratecard.on("value", (snapshot) => {
      const datarate = snapshot.val();
      const dda = Object.entries(datarate);
      const dda1 = Object.entries(datarate);
      const toggle = () => {};
      var datamod = [];
      let cities = [];
      dda1.map((pro) => {
        cities.push(pro[0]);
      });
      dda.map((dd, key) => {
        dd[1].share.city_name = cities[key];
        dd[1].share.type = "share";
        var distancemul1 = Object.entries(dd[1].share.distance_multiplier);
        distancemul1.map((data) => {
          dd[1].share[data[0]] = data[1];
        });
        datamod.push(dd[1].share);
        dd[1].single.city_name = cities[key];
        dd[1].single.type = "single";
        var distancemul2 = Object.entries(dd[1].single.distance_multiplier);
        distancemul2.map((data) => {
          dd[1].single[data[0]] = data[1];
        });
        datamod.push(dd[1].single);
      });
      dispatch(addratecardData(datamod, cities));
      ratecard.off();
    });
   var starCountRef = database.ref("drivers/available_drivers");
    starCountRef.on("value", (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        const dd = Object.keys(data).length;
        const dda = Object.entries(data);
        const oncalldrivers = Object.entries(ongoingrededriverid);

        let das = dda.map((pop) => {
          var ids = pop[0].split("@");
          ids = ids[0];
          if (oncalldrivers.length !== 0) {
            if (oncalldrivers[0].includes(ids)) {
              const indexd = oncalldrivers[0].indexOf(ids);
              if (indexd !== -1) {
               var colorCar = "yellow";

                if (ongoingrededriverid[ids].type === "single") {
                              colorCar = "Green";
                            } else {
                              switch (ongoingrededriverid[ids].seat_req) {
                                case 1:
                                  colorCar = "orange";
              
                                  break;
                                case 2:
                                  colorCar = "blue";
              
                                  break;
                                case 3:
                                  colorCar = "purple";
              
                                  break;
                                default:
                                  colorCar = "orange";
              
                                  break;
                              }
                            }
                            console.log(
                             
                                 
                              );

                return  {
                  id: ids,
                  l: {
                    lat: pop[1].l[0],
                    lng: pop[1].l[1],
                  },
                  name: oncalldrivers[indexd][1].name,
                  status: oncalldrivers[indexd][1].status,
                  colorCode:colorCar,
                  type: oncalldrivers[indexd][1].type,
                };
              }
            }
          }
            return {
              id: ids,
              l: {
                lat: pop[1].l[0],
                lng: pop[1].l[1],
              },
              name: null,
              status: 'Waiting',
              colorCode:"yellow" ,
              type: null,
            };
          
          
        });
        dispatch(addDatalocation(das, dd));
      }
    });
    var war_room = database.ref("war_room");
    war_room.on("value", (snapshot) => {
      const datawar = snapshot.val();
      var citiesWar = Object.keys(datawar);
      var dataset={}
      citiesWar.map((dd)=>{
dataset[dd]=datawar[dd].coordinates;
      })
      dispatch(addwarroomData(datawar));
      if (islodingforwarroom) {
        dispatch(addwarroomCity(dataset));

      }

    });
  }
  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }
  function Logout() {
    localStorage.clear();
    dispatch(Logoutreset());
    window.location.reload();

    auth.signOut();

  }
  async function routesf(tok) {
    var ddd;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + tok);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    await fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/permissions",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        ddd = result;
        var arr = routes;
        var i = arr.length;
        while (i--) {
          if (
            arr[i] &&
            arr[i].collapse == true &&
            ddd.permissions.hasOwnProperty([arr[i].tag])
          ) {
            var k = arr[i].views.length;
            var dataarray = arr[i].views;
            while (k--) {
              if (!ddd.permissions[arr[i].tag].includes(dataarray[k].tagsub)) {
                arr[i].views.splice(k, 1);
              }
            }
          } else {
            if (!ddd.permissions.hasOwnProperty([arr[i].tag])) arr.splice(i, 1);
          }
        }
        dispatch(setpermissions(arr));
        dispatch(setloding());


        return routes;
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

   useEffect(  ()  => {
    const unscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser(user);
        localStorage.setItem("user", JSON.stringify(user));
        auth.currentUser
          .getIdToken(true)
          .then ( async function (idToken) {
            localStorage.setItem("user_id", user.uid);
            localStorage.setItem("token", idToken);
           await routesf(idToken);
          })
          .catch(function (error) {
            alert(error.message);
            console.log(error);
            // Handle error
          });
        lisen();
      }
    });

    return unscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    Logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading && children}
    </AuthContext.Provider>
  );
}