import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { searchdata, adddunlinkedvehicledroData } from "../datachane/actions";
import { useSelector, useDispatch } from "react-redux";
// core components
import App from "./ReactTablecheckbox";
function DROtovehicle() {
  const isloading = useSelector((state) => state.Isload);
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [errormessage, seterrormessage] = useState(null);
  const [nexttoggle, setnexttoggle] = useState(true);
  const dd = useSelector((state) => state.Searchdata);
  const UnlinkedVehicle = useSelector((state) => state.Unlinkedvehicledro);
  const UnlinkedVehicleselected = useSelector(
    (state) => state.Drounlinkedvehicleds
  );

  //UnlinkedVehicle
  const dispatch = useDispatch();
  const [vehiclesetdata1, setvehiclesetdata1] = useState(null);
  const [search_value, setsearch_value] = useState("");
  const [srarchResponse, setsrarchResponse] = useState();
  const [selecterdro, setselecterdro] = useState(null);
  
  function handleSubmit(event) {
    event.preventDefault();

    if (UnlinkedVehicleselected.length != 0 && selecterdro != null) {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        dro: selecterdro,
        vehicles: UnlinkedVehicleselected,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log(raw);

        fetch("https://us-central1-africar-9309f.cloudfunctions.net/link_driver_vehicle?type=dro", requestOptions)
            .then((response) => response.json())
            .then((result) => {
              if(result.status===false){
               alert(result.message);

              }else{
                alert("Driver Has Been Linked To The Vehicle");

              }
        })
        .catch((error) => console.log("error", error));
    } else {
      alert("Please select the values properly");
    }
  }
  useEffect(() => {
    if (UnlinkedVehicle.arr.length === 0) {
      callunlinked();
    }
  }, []);
  function callunlinked() {
    var nextkey = UnlinkedVehicle.nextKey || "";
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/all_vehicles?type=unlinked_dro&start=" +
        nextkey,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === false) {
          console.log(result);
          alert("Error : " + result.message);
        } else {
          dispatch(adddunlinkedvehicledroData(result.data, result.start));
        }
      })
      .catch((error) => console.log("error :", error));
  }

  
  async function handleSearchSubmit(event) {
    event.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = "";
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://us-central1-africar-9309f.cloudfunctions.net/search_users?search=" +
        search_value +
        "&user=mappable&type=linking",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === false) {
          seterrormessage(result.message);
          setsrarchResponse(null);
          setTimeout(() => {
            seterrormessage(null);
          }, 2000);
        } else {
          dispatch(searchdata(result.data));
        }
      })
      .catch((error) => console.log("error", error));
  }

  const vehiclecol = [
    {
      Header: "registration number",
      accessor: "registration_number",
    },

    {
      Header: "model",
      accessor: "model",
      canFilter: false,
    },
    {
      Header: "brand",
      accessor: "brand",
      canFilter: false,
    },
    {
      Header: "city",
      accessor: "city",
    },
  ];
  if (UnlinkedVehicle.arr.length === 0 && isloading) {
    return (
      <>
        <div className="content">
          <div className="w-100 ">
            <h5 style={{ display: "inline-block" }}>loading data...</h5>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="content ">
        <div className="w-100 mt-3">
          <h5 style={{ display: "inline-block" }}>Vehicle to DRO</h5>{" "}
        </div>

        <Card className="h-75 m-1 p-2">
          <Row>
            <Col md={6}>
              <div className="content ">
                <div className="w-100" style={{ display: "inline-block" }}>
                  <h5 style={{ display: "inline-block" }}>Vehicle Search </h5>

                  <App data={UnlinkedVehicle.arr} columns={vehiclecol} callfunction={callunlinked}/>
                </div>
              </div>
            </Col>

            <Col md={6}>
              <Card>
                <Card className="m-2 p-1">
                  <div className="content ">
                    <div className="w-100" style={{ display: "inline-block" }}>
                      <h5 style={{ display: "inline-block" }}>DRO Search </h5>
                      <p>"Please Enter a Valid Contact Number Or Email ID" </p>
                      <Form onSubmit={handleSearchSubmit} className="w-100">
                        <InputGroup className="input-group-focus">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-search" aria-hidden="true" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            value={search_value}
                            name="vSearch"
                            placeholder="Email Id / Contact Number"
                            onChange={(e) => setsearch_value(e.target.value)}
                            type="text"
                          />
                        </InputGroup>
                        <FormGroup>
                          <Button id="submit" type="submit">
                            Search Drivers
                          </Button>
                          <div>
                            {errormessage != null ? (
                              <p style={{ color: "red" }}>{errormessage}</p>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </Card>
              </Card>
            </Col>
          </Row>
        </Card>
        <Card className="p-2">
          <h5> </h5>

          <Row>
            <Col sm={6} md={4}>
              {vehiclesetdata1 !== null ? (
                <Card
                  className="card-stats p-2"
                  style={{ backgroundColor: "#EFEFEF", textAlign: "justify" }}
                >
                  <Row style={{ textTransform: "capitalize" }}>
                    <Col sm={12}>
                      <p className="marginzero">
                        Registration Number:{" "}
                        <span>{vehiclesetdata1.registration_number}</span>
                      </p>
                    </Col>

                    <Col sm={12}>
                      <p className="marginzero">
                        city : <span>{vehiclesetdata1.city}</span>
                      </p>
                    </Col>
                    <Col sm={12}>
                      <p className="marginzero">
                        vendor : <span>{vehiclesetdata1.vendor}</span>
                      </p>
                    </Col>
                  </Row>{" "}
                </Card>
              ) : null}
            </Col>
            <Col md={6}>
              <Row>
                {dd.arr.length !== 0
                  ? dd.arr.map((data) => {
                      return (
                        <Col md={6}>
                          <Card
                            className="p-2"
                            id={data.id}
                            onClick={() => {
                              setselecterdro(data.id);
                              document.getElementById(
                                data.id
                              ).style.backgroundColor = "#EFEF";
                            }}
                          >
                            <Row>
                              <Col sm={12}>
                                <span>
                                  <h5></h5>DRO Name :{" "}
                                  {data.name }
                                </span>
                              </Col>
                              <Col sm={12}>
                                <span>
                                  <h5></h5>Email ID: {data.email}
                                </span>
                              </Col>
                              
                            </Row>
                          </Card>
                        </Col>
                      );
                    })
                  : null}
              </Row>
            </Col>

            <Col md={12}>
              <Form onSubmit={handleSubmit} className="w-100">
                <Button id="submit" type="submit">
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        </Card>
        <Card></Card>
      </div>
    </>
  );
}

export default DROtovehicle;

