import React,{useEffect} from "react";
import { useTable, useFilters, useGlobalFilter,usePagination, useRowSelect } from "react-table";
// A great library for fuzzy filtering/sorting items

import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,Card,Button,Row,Col
} from "reactstrap";
import {Drounlinkeddata} from "../datachane/actions";
import {useSelector,useDispatch} from 'react-redux';
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;

  return (
    <div>
      <p> Search:</p>

      <InputGroup className="input-group-focus ">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="fa fa-search" aria-hidden="true" />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          value={globalFilter || ""}
          onChange={(e) => {
            setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
          placeholder={`${count} records...`}
        />
      </InputGroup>
    </div>
  );
}

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef
  
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])
  
      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )



// Our table component
 export default function Table13({ columns, data,callfunction }) {
  const filterTypes = React.useMemo(
    () => ({
     
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
    toggleAllRowsSelected

  } = useTable(
    {
      columns,
      data,
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    usePagination,
    useRowSelect,
    hooks => {
        hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
           
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()}  />
              </div>
            ),
          },
          ...columns,
        ])
      }
  );
  const dispatch = useDispatch();

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // <button
  //       onClick={() => toggleAllRowsSelected()} //trigger select all rows
  //     >zxxz</button>
  const firstPageRows = rows.slice(0, 10);
  useEffect(() => {
    dispatch(Drounlinkeddata(selectedFlatRows.map(
      d => d.original.id||[]
    )))
    },[selectedRowIds])
  return (
    <div className="container">
     
      <Table>
        <thead >
          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: "left",
              }}
            >
              <Row>
                <Col md={10}>
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              /></Col>
              <Col md={2}>
              <Button onClick={callfunction}>Next</Button>
              </Col></Row>
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  style={{
                    overflowWrap: "break-word",
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                  }}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} style={{overflowX:'auto',height:'200px',}}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
     
     
    </div>
  );
}

// function App({  valuesSet, column, searchval,callfunction }) {
//   const data = valuesSet;
 
//   return         <Card className="content" >
//   <Table13 columns={column} data={data} functioncall={callfunction} /></Card>;
// }

//  App;
