const initailState = {
    arr:[],
    nextKey:''
}
const Promocode=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_PROMOCODEDATA':
            return{ 
                arr: action.payload,
                nextKey:action.keynext
            }
        default:
            return state;
    }
}

export default Promocode