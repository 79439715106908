/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";

// reactstrap components
import { Button, Card, FormGroup, Input, Row, Col, Form } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
// core components
import { database } from "../../components/firebase";
import { useHistory } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";

function AddCity() {

  let history = useHistory();
 
  
  const [countryname, setcountryname] = useState();
  const [cityname, setcityname] = useState('');
  const [country, setcountry] = useState(null);
  const [autocompleteOption, setautocompleteOption] = useState({})
  const [navigationvalue, setnavigationvalue] = useState(false)
  useEffect(() => {
    var ratecard = database.ref("localization/countries");
    ratecard.on("value", (snapshot) => {
      const datarate = snapshot.val();
      setcountry(datarate);
    });
  }, []);
  const optionset=(e)=>{
    var selectData=JSON.parse(e);
    setautocompleteOption({
      types: ["(cities)"],
      componentRestrictions: { country: selectData.code },
    })
  }
  const handleSubmit1 = (e) => {
    e.preventDefault();
    if(cityname!==''){
    var setjsondata=JSON.parse(countryname);
    var setdata = database.ref("localization/"+setjsondata.name);
    setdata.once("value").then((snapshot) => {
      var username = snapshot.val();
    var arrcities=[]
   arrcities= username.cities!==undefined?username.cities:[]
   if(arrcities.includes(cityname)){
     alert('This city already exists')
   }else{
    arrcities.push(cityname);
      setdata.update({ cities: arrcities }).then(()=>{
        alert('city added successfully')
        setcountryname('')
setcityname()
setautocompleteOption({})
        if(navigationvalue){
          history.push({
            pathname: '/admin/AddCityRateCard',
            customNameData:{country:countryname,cityname:cityname} ,
          })
        }
      });
    }
  }).catch((e) => console.log(e));
    }else{
      alert('enter the city value')
    }  
};
  if (country===null) {
    return (
      <>
        <div className="content">
          <div className="w-100 ">
            <h5>Add Country</h5>
           {country===null?<p>No country Data found</p>:<p></p>}
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="content w-100">
        <div className="w-100" style={{ display: "inline-block" }}>
          <h5>Add City</h5>
        </div>
        <Card className="m-0 p-4">
          <Form onSubmit={handleSubmit1} className="w-100">
            <Row>
              <Col md={4} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">Country Name</p>
                  <Input
                    required
                    value={countryname}
                    onChange={(e) => {
                      optionset(e.target.value);
                      setcountryname(e.target.value);
                    }}
                    placeholder="select category"
                    type="select"
                  >
                    <option value="">select country</option>
                    {country.map((data, key) => {
                      return (
                        <option key={key} value={JSON.stringify(data)}>
                          {data.name}
                        </option>
                      );
                    })}{" "}
                  </Input>
                </FormGroup>{" "}
              </Col>
              <Col md={12} className="mt-2" />
              <Col md={8} className="mt-2">
                <FormGroup>
                  <p className="Labelstyle">City Name</p>
                  <Autocomplete
                  required
                  className='form-control'
  apiKey={'AIzaSyD6IFjP0WDoxI6sYhMp4qQZdmjGtGS5_Ws'}
  style={{ width: "90%" }}
  onPlaceSelected={(place) => {
    setcityname(place.address_components[0].long_name);
  }}
  options={autocompleteOption}
/> 
                 
                </FormGroup>{" "}
              </Col>
              <Col md={12} />
              <Col md={3}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle"> </p>
                  <Button id="submit" type="submit">
                    Save
                  </Button>
                </FormGroup>{" "}
              </Col>
              <Col md={6}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle"> </p>
                  <Button id="submit" type="submit" onClick={()=>{ 
                    setnavigationvalue(true);
                  }} >
                    Save & Add City Rate Card
                  </Button>
                </FormGroup>{" "}
              </Col>
              <Col md={3}>
                {" "}
                <FormGroup>
                  <p className="Labelstyle"> </p>
                  <Button
                    className="cancelButton"
                    onClick={() => history.goBack()}
                  >
                    Back
                  </Button>
                </FormGroup>{" "}
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </>
  );
}

export default AddCity;
