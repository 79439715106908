
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Spinner
} from "reactstrap";
import { useSelector, useDispatch } from 'react-redux';

// core components
import ReactTable from "../../components/ReactTable/ReactTable";
function OngoinRides() {
  const dd = useSelector(state => state.Ongoingridesdata)
  const dispatch = useDispatch();
  const [nextkey, setnextkey] = useState('');
  const [messagttouser, setmessagttouser] = useState("Loading  Data...")
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false);

  if (dd.length===0) {
    return (
      <>
        <div className="content">
          <div className="w-100">
            <h5 style={{ display: 'inline-block' }}>Ongoing Rides </h5>
            <p>No Records found </p>
          </div>
        </div>
      </>)
  }
  return (
    <>
      <div className="content ">
        <div className="w-100" style={{ display: 'inline-block' }}>
          <h5 style={{ display: 'inline-block' }}>Ongoing Rides  </h5>
          {'  '}{sipnnershow && <div style={{ display: 'inline-block' }}>
            Loading data... <Spinner size="sm" color="primary" />
          </div>}
        </div>   <ReactTable
          calldata={()=>{}}
          data={dd}
          isnext={true}
          counts={dd.TotalCount}

          hidecolumnsdata={[
            "duration_str",
            "distance",
            "licenseno",
            "licensevalidity",
            "vendor",
            "distance",
            "registration_number",
            "driver_fname",
            "ride_cost",
            "ratings",
            "africar_net_gain",
            "driver_commission",
            "payment_method",
            "actions",
            "rate_card.base_fare",
            "dropmapurl", 
            "drop_str",
            "pickup_str"
          ]}
          columns={[
            {
              Header: "rider name",
              accessor: "rider_fname",
            },
            
            {
                Header: "status",
                accessor: "status",
              }, 
               {
              Header: "Date  and  time",
              accessor: "booked_on",
            }, 
            {
              Header: " rider contact number",
              accessor: "rider_phone_number",
            },{
                Header: "Booking type",
                accessor: "type",
              },
            {
              Header: "Driver name",
              accessor: "driver_name",
            },
            {
              Header: "Driver contact number",
              accessor: "driver_contactno",
            },
            {
                Header: "registration number",
                accessor: "registration_number",
              },
              {
                Header: "drop location",
                accessor: "drop_str",
              },
              {
                Header: "pickup location",
                accessor: "pickup_str",
              },
            
            {
              Header: "Km Traveled",
              accessor: "distance",
            }, 
            {
              Header: "total time travelled",
              accessor: "duration_str",
            },
           
            {
              Header: "payment method",
              accessor: "payment_method",
            },
            {
              Header: "ride cost",
              accessor: "ride_cost",
            },
          ]}
          /*
            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
          */
          className="-striped -highlight primary-pagination"
        />

      </div>
    </>
  );
}

export default OngoinRides

