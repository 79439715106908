
const initailState = {
    arr:[],
    nextKey:''
}
const CancelledRides=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_CANCELLED_DATA':
            return{ 
                ...state,
                arr: state.arr.concat(action.payload),
                nextKey:action.keynext,
                TotalCount:action.counts

            }
        default:
            return state;
    }
}

export default CancelledRides